<div mat-dialog-title fxLayout="row" fxLayoutAlign="end" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container" cdkDragHandle>
  <h2 *ngIf="title" [class.margin-right]="!showQuickCancelButton" fxFlex>{{ title }}</h2>
  <button *ngIf="showQuickCancelButton" tabindex="-1" mat-icon-button aria-label="Close dialog button" (click)="cancelClicked()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content fxLayout="column">
  <mat-progress-bar [ngStyle]="{'visibility': isLoading ? 'visible' : 'hidden'}" mode="buffer"></mat-progress-bar>
  <div fxflex fxLayout="column">
    <ng-content></ng-content>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end" fxLayoutGap="6px">
  <button *ngIf="cancelButtonText" mat-button [disabled]="isLoading" (click)="cancelClicked()">
    {{ cancelButtonText }}
  </button>

  <div *ngIf="confirmActionLoading" class="mdc-button cursor-default">
    <mat-spinner diameter="15" [matTooltip]="confirmActionLoadingTooltip"></mat-spinner>
  </div>

  <button *ngIf="confirmButtonText && !confirmActionLoading" mat-button [color]="confirmButtonColor"
          [disabled]="!isConfirmButtonEnabled || isLoading" (click)="confirmClicked()">
    {{ confirmButtonText }}
  </button>
</mat-dialog-actions>
