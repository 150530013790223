<div id="filter-bar" fxLayout="row" fxLayoutAlign="center end" fxLayoutGap="12px" class="mat-elevation-z1" [class.display-in-overlay]="displayInOverlay" [formGroup]="filterOptionsForm">
  <mat-form-field id="date-picker">
    <input matInput readonly [max]="maxFilterDate" [matDatepicker]="picker" formControlName="date" />
    <mat-label>Show Until</mat-label>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <button fxFlex mat-button [matMenuTriggerFor]="types" id="types-selector" disableRipple [ngClass]="{ 'error': numberOfActiveFilters === 0 }"
          [matTooltip]="numberOfActiveFilters > 4 ? '... ' + (displayListOfSelected.splice(4, numberOfActiveFilters) | convertCamelCaseToTitleCase) : ''">
    <div fxFlex class="types-selector-inner" fxLayout="column" fxLayoutAlign="start center">
      <div class="select-label">
        <div [ngClass]="{ 'label': numberOfActiveFilters > 0, 'trigger-label': numberOfActiveFilters === 0 }" fxFlex>
          Type
        </div>

        <div *ngIf="numberOfActiveFilters > 0" fxFlex class="selected">
          <span>{{ displayListOfSelected.splice(0, 4) | convertCamelCaseToTitleCase }}</span>
          <span *ngIf="numberOfActiveFilters > 4" class="additional-selection-label">
            ({{ '+' + (numberOfActiveFilters - 4) + ' more selected' }})
          </span>
        </div>
      </div>
    </div>
  </button>

  <mat-menu #types="matMenu" formGroupName="types">
    <div id="moment-type-options">
      <mat-checkbox formControlName="call" (click)="$event.stopPropagation()">
        Call
      </mat-checkbox>
      <mat-checkbox formControlName="note" (click)="$event.stopPropagation()">
        Note
      </mat-checkbox>
      <mat-checkbox formControlName="file" (click)="$event.stopPropagation()">
        File
      </mat-checkbox>
      <mat-checkbox *ngIf="(featureFlagService.featureFlags$ | async)?.isGoogleDriveEnabled"
                    formControlName="googleFile" (click)="$event.stopPropagation()">
        Google File
      </mat-checkbox>
      <mat-checkbox formControlName="chat" (click)="$event.stopPropagation()">
        Chat
      </mat-checkbox>
      <mat-checkbox formControlName="event" (click)="$event.stopPropagation()">
        Event
      </mat-checkbox>
      <mat-checkbox formControlName="discharge" (click)="$event.stopPropagation()">
        Discharge
      </mat-checkbox>
      <mat-checkbox formControlName="cgiScore" (click)="$event.stopPropagation()">
        CGI Score
      </mat-checkbox>
      <mat-checkbox formControlName="appointment" (click)="$event.stopPropagation()">
        Appointment
      </mat-checkbox>
    </div>
  </mat-menu>

  <div matTooltip="Type is required to filter by" [matTooltipDisabled]="areFiltersValid">
    <button mat-raised-button color="primary" [disabled]="!areFiltersValid" (click)="filterButtonClicked()">Apply</button>
  </div>
</div>


