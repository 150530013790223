import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { MessageService } from '@shared/services/message.service';
import { MomentService } from '../../moment.service';

@Component({
    selector: 'app-delete-note-moment-dialog',
    templateUrl: './delete-note-moment-dialog.component.html',
    styleUrls: ['./delete-note-moment-dialog.component.scss'],
    standalone: false
})
export class DeleteNoteMomentDialogComponent {

  @ViewChild('dialog') dialog: DialogComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { momentId: string, momentService: MomentService; },
    private dialogRef: MatDialogRef<DeleteNoteMomentDialogComponent>,
    private messageService: MessageService
  ) { }

  public cancelButtonClicked(): void {
    this.dialogRef.close();
  }

  public async deleteButtonClicked(): Promise<void> {
    await this.dialog.showAsyncProgressForFunction(async () => {
      try {
        await this.data.momentService.deleteNoteMoment(this.data.momentId);
      } catch (err) {
        console.error(err);
        this.messageService.showMessage('Error: Note could not be deleted. Please refresh the page and try again.');
      }
      this.dialogRef.close(true);
    });
  }

}
