export const PHQ_GAD_Initial_Question: string = 'Over the last week, how often have you been bothered by any of the following problems?';

export enum GAD7 {
  GAD7_1 = `Feeling nervous, anxious, or on edge.`,
  GAD7_2 = `Not being able to stop or control worrying.`,
  GAD7_3 = `Worrying too much about different things.`,
  GAD7_4 = `Trouble relaxing.`,
  GAD7_5 = `Being so restless that it's hard to sit still.`,
  GAD7_6 = `Becoming easily annoyed or irritable.`,
  GAD7_7 = `Feeling afraid as if something awful might happen.`,
}

export enum GAD7_Panic {
  GAD7_Panic = `Have you had an anxiety attack?`,
}

export const GAD7_Panic_Answers = {
  0: 'No',
  1: 'Yes',
};

export enum PHQ9 {
  PHQ9_1 = `Little interest or pleasure in doing things.`,
  PHQ9_2 = `Feeling down, depressed, or hopeless.`,
  PHQ9_3 = `Trouble falling or staying asleep, or sleeping too much.`,
  PHQ9_4 = `Feeling tired or having little energy.`,
  PHQ9_5 = `Poor appetite or overeating.`,
  PHQ9_6 = `Feeling bad about yourself - or that you are a failure or have let yourself or your family down.`,
  PHQ9_7 = `Trouble concentrating on things, such as reading the newspaper or watching television.`,
  PHQ9_8 = `Moving or speaking so slowly that other people could have noticed. Or the opposite - being so fidgety or restless that you have been moving around a lot more than usual.`,
  PHQ9_9 = `Thoughts that you would be better off dead, or of hurting yourself in some way.`,
}

export const PHQ_GAD_Answers = {
  0: 'Not at all',
  1: 'Several days',
  2: 'More than half the days',
  3: 'Nearly every day',
};
export const Function_Question: string = 'How much have the symptoms above affected your work, relationships or household activities?';

export enum Function_Question_Answers {
  NotDifficultAtAll = 'Not difficult at all',
  SomewhatDifficult = 'Somewhat difficult',
  VeryDifficult = 'Very difficult',
  ExtremelyDifficult = 'Extremely difficult'
}
