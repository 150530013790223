<div fxLayout="column" fxLayoutGap="32px" class="stepper-content questions" [formGroup]="phqFormGroup">
  <p>{{ phqGadInitialQuestion }}</p>
  <div *ngFor="let phqQuestion of phqQuestions; let i = index; trackByProperty: 'phqQuestionsAnswers'" class="phq-question">
    <label>{{i + 1}}. {{ phqQuestion }}</label>
    <mat-radio-group formControlName="{{ phqQuestionsKeys[i] }}">
      <mat-radio-button *ngFor="let answer of phqGadAnswers; let answerIndex = index; trackByProperty: 'phqGadAnswers'" [disabled]="i > 1 && !phq9Active" [value]="answerIndex">{{ answer }}</mat-radio-button>
    </mat-radio-group>
  </div>
  <div>
    Total Score: {{ phqScore }}
  </div>
</div>
<p class="small-print"><small>UHS Rev 4/2020 - Developed by Drs. Robert L. Spitzer, Janet B.W. Williams, Kurt Kroenke and colleagues, with an educational grant from Pfizer Inc.</small></p>
