import { Component, Input } from '@angular/core';

import { Moment } from '../../moment';
import { MomentType } from '../../moment-type';

@Component({
    selector: 'app-display-moment',
    templateUrl: './display-moment.component.html',
    styleUrls: ['./display-moment.component.scss'],
    standalone: false
})
export class DisplayMomentComponent {
  @Input() moment: Moment;
  public momentType = MomentType;
}
