import { Injectable } from '@angular/core';

import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { SimpleNavLink } from './nav-link/nav-link';
import { AuthenticationService } from '../../authentication/authentication.service';
import { MainNavLinkCollection } from './main-nav-links';


@Injectable({
  providedIn: 'root'
})
export class MainNavService {
  public mainNavState$ = new BehaviorSubject(false);
  public mainNavOpen$ = new BehaviorSubject(false);
  public mainNavFixedOpen$ = new BehaviorSubject(false);
  private readonly linkCollections: MainNavLinkCollection[] = [
    {
      title: 'THERAPIST',
      links: [
        {
          displayName: 'Chat Requests',
          matIcon: 'add_comment',
          link: '/therapist/chat-requests',
          scope: 'chat:view-requests'
        },
        {
          displayName: 'Chat',
          matIcon: 'question_answer',
          link: '/therapist/chat',
          scope: 'chat:view-active-chats'
        },
        // go-chat-duplication start
        {
          displayName: 'Chat Requests V2',
          matIcon: 'maps_ugc',
          link: '/therapist/go-chat-requests',
          scope: 'chat:view-requests'
        },
        {
          displayName: 'Chat V2',
          matIcon: undefined,
          svgIcon: 'conversation-bubble',
          link: '/therapist/go-chat',
          scope: 'chat:view-active-chats'
        },
        // go-chat-duplication end
        {
          displayName: 'Assigned Patients',
          matIcon: 'folder_shared',
          link: '/therapist/assigned-patients',
          scope: 'therapist:list-patients'
        },
        {
          displayName: 'Therapist Profile',
          matIcon: 'id_card',
          link: '/therapist/profile',
          // TODO: This needs a "view therapist profile" specific scope to be implemented on TUB
          scope: 'therapist:list-patients'
        }
      ]
    },
    {
      title: 'ADMIN',
      links: [
        {
          displayName: 'Organisations',
          matIcon: 'business',
          link: '/admin/organisations',
          scope: 'organisations:edit'
        },
        {
          displayName: 'Message Alerts',
          matIcon: 'feedback',
          link: '/admin/message-alerts',
          scope: 'message-alerts:get'
        },
        {
          displayName: 'Tools',
          matIcon: 'build',
          link: '',
          // TODO: Add logic to only show "Tools" if any of the child scopes are present.
          //  Currently only chat:download-chat is implemented, so there is just a check against this scope.
          scope: 'chat:download-chat',
          children: [
            {
              displayName: 'View Chat Log',
              matIcon: 'view_timeline',
              link: '/admin/tools/chat-log',
              scope: 'chat:download-chat'
            },
            {
              displayName: 'Survey Configs',
              matIcon: 'assignment',
              link: '/admin/tools/survey',
              scope: 'survey:get'
            }
          ]
        },
        {
          displayName: 'Promote Users',
          matIcon: 'group_add',
          link: '/admin/promote-users',
          scope: 'iams:escalate-to-therapist'
        },
        {
          displayName: 'Claim Chat Lead',
          matIcon: 'face_right',
          link: '/admin/claim-chat-lead',
          scope: 'chat:claim-lead'
        },
        {
          displayName: 'Region',
          matIcon: 'public',
          link: '/admin/region-settings',
          scope: 'region-settings:edit'
        }
      ]
    },
    {
      title: 'REPORTING',
      links: [
        {
          displayName: 'Reports',
          link: '/admin/reporting/reports',
          matIcon: 'lab_profile',
          scope: 'mi-report:basic'
        },
        {
          displayName: 'Report Schedule',
          link: '/admin/reporting/report-schedule',
          matIcon: 'event_repeat',
          scope: 'mi-report:basic'
        },
        {
          displayName: 'Dashboard',
          matIcon: 'dashboard',
          link: '/admin/reporting/dashboard',
          scope: 'domo-reports:view'
        },
        {
          displayName: 'Promote Users to Analyst',
          matIcon: 'group_add',
          link: '/admin/promote-users-analyst',
          scope: 'iams:escalate-to-analyst'
        },
        {
          displayName: 'Downgrade Analysts',
          matIcon: 'group_remove',
          link: '/admin/downgrade-users-analyst',
          scope: 'iams:downgrade-from-analyst'
        }
      ]
    },
    {
      title: 'ACCOUNT',
      links: [
        {
          displayName: 'Settings',
          matIcon: 'manage_accounts',
          link: '/account/settings'
          // No scope; account links should scope agnostic
        } ]
    }
  ];

  constructor(
    private authenticationService: AuthenticationService,
  ) {
    const stored = localStorage.getItem('menu_open');
    this.mainNavOpen$.next(stored === 'true');
    this.mainNavState$.next(stored === 'true');
    this.mainNavFixedOpen$.next(stored === 'true');
  }

  /**
   * Returns only the links that the user has permissions to access
   */
  public async getMainNavLinks(): Promise<MainNavLinkCollection[]> {

    // If there are no scopes, then try to fetch them.
    // setUserScopes() is called on login, however when running locally or on dev, it is possible to refresh the page without triggering a
    // log in request - this code triggers that request.
    const currentScopes = await firstValueFrom(this.authenticationService.getUserScopes$());
    // Checking to make sure we are not on the callback page - we don't want Auth box appearing
    if (currentScopes[0] === null && !window.location.pathname.includes('callback')) {
      await this.authenticationService.setUserScopes();
    }

    const filteredLinks: MainNavLinkCollection[] = [];

    for (const linkCollection of this.linkCollections) {
      // Create an array of promises to determine whether the user has permissions to view link
      const results: Promise<boolean>[] = linkCollection.links.map(link => this.hasPermissionToViewLink(link));

      // Resolve all the promises to create an array of boolean whose indexes correspond with each link in linkCollection.links
      const hasPermission: boolean[] = await Promise.all(results);

      // construct a new MainNavLinkCollection rather than modifying the existing one, as this would change the original config
      const filteredLinkCollection: MainNavLinkCollection = {
        title: linkCollection.title,
        links: linkCollection.links.filter((_, index) => hasPermission[index])
      };

      filteredLinks.push(filteredLinkCollection);
    }

    return filteredLinks;
  }

  /**
   * Returns true if no scope has been set (can be assumed to be a "default" link, visible by all users).
   * Returns true if the link has a scope, and the user has been assigned that scope
   * Returns false if the links has a scope, but the user does not have that scope
   * @param link The link to check
   */
  private async hasPermissionToViewLink(link: SimpleNavLink): Promise<boolean> {
    return link.scope ? await this.authenticationService.isScopePresent(link.scope) : true;
  }
}
