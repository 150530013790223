import { Component, Input, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';

import { animateText } from '../main-nav-animations';
import { NavLink } from './nav-link';
import { MainNavService } from '../main-nav.service';

@Component({
  selector: 'app-nav-link',
  templateUrl: './nav-link.component.html',
  styleUrls: [ './nav-link.component.scss' ],
  animations: [animateText],
  standalone: false
})
export class NavLinkComponent {

  @ViewChild(MatAccordion) accordion: MatAccordion;
  @Input() linkData: NavLink;

  constructor(public mainNavService: MainNavService){}
}
