<div fxLayout="column" fxLayoutGap="24px" class="stepper-content" [formGroup]="cgiFormGroup">
  <ng-container *ngIf="!editMode">
    <mat-accordion *ngIf="previousCGI; else noPreviousCGI">
      <mat-expansion-panel class="previous">
        <mat-expansion-panel-header>
          <mat-panel-title> Previous CGI Severity / CGI Improvement </mat-panel-title>
          <mat-panel-description class="previous-description">
            {{ previousCGI?.author }} &nbsp; | &nbsp; {{ previousCGI?.ts | date: 'EEEE, MMMM d, y, h:mm:ss a' }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <p class="highlight">
            <span>CGI Severity:</span> {{ previousCGI?.data?.cgiSeverity }} -
            {{ cgiSeverityAnswers[previousCGI?.data?.cgiSeverity - 1] }}
          </p>
          <p class="highlight">
            <span>CGI Improvement:</span> {{ previousCGI?.data?.cgiImprovement }} -
            {{ cgiImprovementAnswers[previousCGI?.data?.cgiImprovement - 1] }}
          </p>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <ng-template #noPreviousCGI>
      <div>
        There are no previous CGI scores for {{ patient?.email | displayUserName: patient?.firstName : patient?.lastName }}.
      </div>
    </ng-template>
  </ng-container>

  <div>
    <p>{{ cgiSeverityExplanation }}</p>
    <p class="highlight-question">{{ cgiSeverityQuestion }}</p>
    <p>{{ cgiSeverityQuestionHint }}</p>

    <mat-form-field class="form-group">
      <mat-label for="cgiSeverity">CGI Severity</mat-label>
      <mat-select id="cgiSeverity" #cgiSeverity formControlName="cgiSeverityResult">
        <mat-option *ngFor="let option of cgiSeverityAnswers; let i = index; trackByProperty: 'cgiSeverityQuestionAnswers'" [value]="cgiSeverityKeys[i]">{{ option }}</mat-option>
      </mat-select>
      <mat-error *ngIf="cgiFormGroup.controls['cgiSeverityResult'].hasError('required')">
        CGI Severity score is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>

  <div>
    <p>{{ cgiImprovementExplanation }}</p>
    <p class="highlight-question">{{ cgiImprovementQuestion }}</p>

    <mat-form-field class="form-group">
      <mat-label for="cgiImprovement">CGI Improvement</mat-label>
      <mat-select id="cgiImprovement" #cgiImprovement formControlName="cgiImprovementResult">
        <mat-option *ngFor="let option of cgiImprovementAnswers; let i = index; trackByProperty: 'cgiImprovementQuestionAnswers'" [value]="cgiImprovementKeys[i]">{{ option }}</mat-option>
      </mat-select>
      <mat-error *ngIf="cgiFormGroup.controls['cgiImprovementResult'].hasError('required')">
        CGI Improvement score is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>
</div>
