<app-moment icon="call" [title]="momentData.fileName" colour="#46cbf3" [momentData]="momentData">
  <div *ngIf="momentData.deleted" class="deleted">
    <div fxLayout="column" fxLayouGap="6px">
      <h4>Deleted</h4>
      <p>{{ momentData.deleted.ts | date: 'fullDate' }}, {{ momentData.deleted.ts | date: 'shortTime' }} by {{ momentData.deleted.author }}</p>
      <p>Reason: {{ momentData.deleted.reason }}</p>
    </div>
  </div>

  <div *ngIf="momentData.edited" class="edited-info">
    <h4>Edited</h4>
    <p>{{ momentData.edited.ts | date: 'fullDate' }}, {{ momentData.edited.ts | date: 'shortTime' }} by {{ momentData.edited.author }}</p>
    <p>Reason: {{ momentData.edited.reason }}</p>
  </div>

  <div fxLayout="row" fxLayoutAlign="center">
    <app-async-button color="info" (click)="momentService.downloadMoment(momentData.id)"> Download </app-async-button>
    <ng-container *ngIf="(featureFlags$ | async)?.isAppointmentMomentsEnabled && !momentData.deleted">
      <button *ngIf="momentData.appointmentRef" mat-button color="primary" (click)="momentService.editAppointmentMoment(momentData, appointmentStep.attachments)">
        Edit
      </button>
      <button *ngIf="!momentData.deleted && !momentData.appointmentRef" mat-button color="warn"
              [disabled]="!(momentType.Appointment | isTimelineFilterSelected)"
              (click)="momentService.softDeleteButtonClicked(momentData)">
        Delete
      </button>
      <div *ngIf="!(momentType.Appointment | isTimelineFilterSelected)" fxLayout="column" fxLayoutAlign="center">
        <mat-icon inline class="help-icon" matTooltip="All filters must be selected to enable delete functionality">help</mat-icon>
      </div>
    </ng-container>
  </div>
</app-moment>
