import { Pipe, PipeTransform } from '@angular/core';

import { MomentType } from '../moment/moment-type';
import { TimelineService } from '../timeline.service';

@Pipe({
    name: 'isTimelineFilterSelected',
    standalone: false
})
export class IsTimelineFilterSelectedPipe implements PipeTransform {

  constructor(private timelineService: TimelineService) {}

  transform(type: MomentType): boolean {
    let filteredType: string;

    if (type === MomentType.CgiScore) {
      filteredType = 'cgiScore';
    } else if (type === MomentType.GoogleFile) {
      filteredType = 'googleFile';
    } else {
      filteredType = type;
    }

    return this.timelineService.getTypesFromFilterOptions(this.timelineService.activeFilterOptions).includes(filteredType as MomentType);
  }
}
