import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from './components/avatar/avatar.component';
import { ConvertDateToTimePipe } from './pipes/convert-date-to-time.pipe';
import { DialogComponent } from './components/dialog/dialog.component';
import { MaterialModule } from './modules/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { RouterModule } from '@angular/router';
import { ConvertStringToTitleCasePipe } from './pipes/convert-string-to-title-case.pipe';
import { AsyncButtonComponent } from './components/async-button/async-button.component';
import { PanelSpinnerComponent } from './components/panel-spinner/panel-spinner.component';
import { DisplayUserNamePipe } from './pipes/display-user-name.pipe';
import { TimeAgoExtendedPipe } from './pipes/time-ago-extended.pipe';
import { ConvertUnixTimeToLocaleStringPipe } from './pipes/convert-unix-time-to-locale-string.pipe';
import { ConvertUnixTimeToDateStringPipe } from './pipes/convert-unix-time-to-date-string.pipe';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { IntersectionObserverModule } from '@ng-web-apis/intersection-observer';
import { SanitizePipe } from './pipes/sanitize.pipe';
import { LfsFilePathPipe } from './pipes/lfs-file-path.pipe';
import { ConvertUnixTimeToUtcStringPipe } from './pipes/convert-unix-time-to-utc-string.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { GetDayOfWeekPipe } from './pipes/get-day-of-week.pipe';
import { HashStringPipe } from './pipes/hash-string.pipe';
import { HelpIconComponent } from './components/help-icon/help-icon.component';
import { FileSizePipe } from './pipes/file-size.pipe';
import { DiallingPrefixPipe } from './services/phone/dialling-prefix.pipe';
import { CountryNamePipe } from './services/phone/country-name.pipe';
import { EnumKeysPipe } from './pipes/enum-keys.pipe';
import { InvokeFunctionWithContextPipe } from './pipes/invoke-function-with-context.pipe';
import { InvokeFunctionPipe } from './pipes/invoke-function.pipe';
import { HideDirective } from './directives/hide.directive';
import { TranslateModule } from '@ngx-translate/core';
import { NgPipesModule, TimeAgoPipe } from 'ngx-pipes';
import { ConvertTimeToStringPipe } from './pipes/convert-time-to-string.pipe';
import { ConvertCamelCaseToTitleCasePipe } from './pipes/convert-camel-case-to-title-case.pipe';
import { UserHasScopeDirective } from '../authentication/user-has-scope.directive';
// patient timeline shared start
import { TimelineFiltersComponent } from './components/patient-timeline/timeline-filters/timeline-filters.component';
import { IsLinkedMomentPipe } from './components/patient-timeline/is-linked-moment.pipe';
import { IsLinkedAppointmentMomentPipe } from './components/patient-timeline/is-linked-appointment-moment.pipe';
import { MomentConnectionLinkComponent } from './components/patient-timeline/moment-connection-link/moment-connection-link.component';
import { AppointmentMomentConnectorComponent } from './components/patient-timeline/appointment-moment-connector/appointment-moment-connector.component';
import { CreateMomentDirective } from './components/patient-timeline/moment/create-moment.directive';
import { MomentComponent } from './components/patient-timeline/moment/moment.component';
import { AppointmentMomentComponent } from './components/patient-timeline/moment/appointment-moment/appointment-moment.component';
import { CallMomentComponent } from './components/patient-timeline/moment/call-moment/call-moment.component';
import { FileMomentComponent } from './components/patient-timeline/moment/file-moment/file-moment.component';
import { NoteMomentComponent } from './components/patient-timeline/moment/note-moment/note-moment.component';
import { ChatMomentComponent } from './components/patient-timeline/moment/chat-moment/chat-moment.component';
import { GoogleDriveFileMomentComponent } from './components/patient-timeline/moment/google-drive-file-moment/google-drive-file-moment.component';
import { AuditEventMomentComponent } from './components/patient-timeline/moment/audit-event-moment/audit-event-moment.component';
import { AddMomentButtonComponent } from './components/patient-timeline/add-moment-button/add-moment-button.component';
import { AddCallMomentComponent } from './components/patient-timeline/add-call-moment/add-call-moment.component';
import { AddFileMomentComponent } from './components/patient-timeline/add-file-moment/add-file-moment.component';
import { AddNoteMomentComponent } from './components/patient-timeline/add-note-moment/add-note-moment.component';
import { AddEditGoogleFileMomentDescriptionComponent } from './components/patient-timeline/moment/google-drive-file-moment/add-edit-google-file-moment-description/add-edit-google-file-moment-description.component';
import { GoogleFileMomentDescriptionHistoryComponent } from './components/patient-timeline/moment/google-drive-file-moment/google-file-moment-description-history/google-file-moment-description-history.component';
import { ChatLogDialogComponent } from './components/patient-timeline/chat-log-dialog/chat-log-dialog.component';
import { HasExpandableContentPipe } from './components/patient-timeline/moment/has-expandable-content.pipe';
import { DeleteNoteMomentDialogComponent } from './components/patient-timeline/moment/note-moment/delete-note-moment-dialog/delete-note-moment-dialog.component';
import { ExpandNoteMomentDialogComponent } from './components/patient-timeline/moment/note-moment/expand-note-moment-dialog/expand-note-moment-dialog.component';
import { RiskAssessmentEventMomentComponent } from './components/patient-timeline/moment/risk-assessment-event-moment/risk-assessment-event-moment.component';
import { DischargeMomentComponent } from './components/patient-timeline/moment/discharge-moment/discharge-moment.component';
import { CgiScoreMomentComponent } from './components/patient-timeline/moment/cgi-score/cgi-score-moment.component';
import { AddEditAppointmentMomentComponent } from './components/patient-timeline/add-edit-appointment-moment/add-edit-appointment-moment.component';
import { AppointmentStepComponent } from './components/patient-timeline/add-edit-appointment-moment/stepper-components/appointment-step/appointment-step.component';
import { AttachmentsStepComponent } from './components/patient-timeline/add-edit-appointment-moment/stepper-components/attachments-step/attachments-step.component';
import { CgiStepComponent } from './components/patient-timeline/add-edit-appointment-moment/stepper-components/cgi-step/cgi-step.component';
import { GadStepComponent } from './components/patient-timeline/add-edit-appointment-moment/stepper-components/gad-step/gad-step.component';
import { PhqStepComponent } from './components/patient-timeline/add-edit-appointment-moment/stepper-components/phq-step/phq-step.component';
import { ValidationStepComponent } from './components/patient-timeline/add-edit-appointment-moment/stepper-components/validation-step/validation-step.component';
import { SoftDeleteMomentDialogComponent } from './components/patient-timeline/moment/soft-delete-moment-dialog/soft-delete-moment-dialog.component';
import { CancelDialogComponent } from './components/patient-timeline/add-edit-appointment-moment/cancel-dialog/cancel-dialog.component';
import { DisplayMomentComponent } from './components/patient-timeline/moment/soft-delete-moment-dialog/display-moment/display-moment.component';
import { IsTimelineFilterSelectedPipe } from './components/patient-timeline/timeline-filters/is-timeline-filter-selected.pipe';

import { AppClosePatientChatPanelDirective }from './directives/close-patient-chat-panel.directive';

// patient timeline shared end
// go-chat-duplication start
import { GoAvatarComponent } from '@shared/components/go-avatar/go-avatar.component';
import { NgForTrackByPropertyModule } from './modules/track-by-property.module';
// go-chat-duplication end
import { UserHasScopePipe } from '@app/modules/authentication/user-has-scope.pipe';

@NgModule({
  declarations: [
    AvatarComponent,
    DialogComponent,
    ConvertDateToTimePipe,
    ConvertStringToTitleCasePipe,
    ConvertCamelCaseToTitleCasePipe,
    AsyncButtonComponent,
    PanelSpinnerComponent,
    DisplayUserNamePipe,
    TimeAgoExtendedPipe,
    ConvertUnixTimeToDateStringPipe,
    EnumToArrayPipe,
    SanitizePipe,
    LfsFilePathPipe,
    ConvertUnixTimeToLocaleStringPipe,
    ConvertUnixTimeToDateStringPipe,
    ConvertUnixTimeToUtcStringPipe,
    TruncatePipe,
    GetDayOfWeekPipe,
    HashStringPipe,
    HelpIconComponent,
    FileSizePipe,
    DiallingPrefixPipe,
    CountryNamePipe,
    EnumKeysPipe,
    InvokeFunctionWithContextPipe,
    InvokeFunctionPipe,
    HideDirective,
    ConvertTimeToStringPipe,
    UserHasScopeDirective,
    GoAvatarComponent,
    CreateMomentDirective,
    IsLinkedMomentPipe,
    IsLinkedAppointmentMomentPipe,
    MomentConnectionLinkComponent,
    AppointmentMomentConnectorComponent,
    MomentComponent,
    TimelineFiltersComponent,
    AuditEventMomentComponent,
    AppointmentMomentComponent,
    CallMomentComponent,
    ChatMomentComponent,
    DischargeMomentComponent,
    CgiScoreMomentComponent,
    FileMomentComponent,
    NoteMomentComponent,
    GoogleDriveFileMomentComponent,
    AddMomentButtonComponent,
    AddCallMomentComponent,
    AddFileMomentComponent,
    AddNoteMomentComponent,
    AddEditGoogleFileMomentDescriptionComponent,
    AddEditAppointmentMomentComponent,
    AppointmentStepComponent,
    AttachmentsStepComponent,
    CgiStepComponent,
    GadStepComponent,
    PhqStepComponent,
    ValidationStepComponent,
    SoftDeleteMomentDialogComponent,
    GoogleFileMomentDescriptionHistoryComponent,
    ChatLogDialogComponent,
    GoogleDriveFileMomentComponent,
    HasExpandableContentPipe,
    DeleteNoteMomentDialogComponent,
    ExpandNoteMomentDialogComponent,
    RiskAssessmentEventMomentComponent,
    UserHasScopePipe,
    CancelDialogComponent,
    DisplayMomentComponent,
    IsTimelineFilterSelectedPipe,
    AppClosePatientChatPanelDirective
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FlexLayoutModule,
    IntersectionObserverModule,
    ClipboardModule,
    TranslateModule,
    NgPipesModule,
    NgForTrackByPropertyModule
  ],
  exports: [
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    IntersectionObserverModule,
    AvatarComponent,
    ConvertDateToTimePipe,
    DialogComponent,
    ConvertStringToTitleCasePipe,
    ConvertCamelCaseToTitleCasePipe,
    AsyncButtonComponent,
    PanelSpinnerComponent,
    DisplayUserNamePipe,
    TimeAgoExtendedPipe,
    ConvertUnixTimeToDateStringPipe,
    EnumToArrayPipe,
    SanitizePipe,
    LfsFilePathPipe,
    ConvertUnixTimeToLocaleStringPipe,
    ConvertUnixTimeToDateStringPipe,
    ClipboardModule,
    ConvertUnixTimeToUtcStringPipe,
    TruncatePipe,
    GetDayOfWeekPipe,
    HelpIconComponent,
    FileSizePipe,
    DiallingPrefixPipe,
    CountryNamePipe,
    EnumKeysPipe,
    InvokeFunctionWithContextPipe,
    InvokeFunctionPipe,
    HideDirective,
    ConvertTimeToStringPipe,
    UserHasScopeDirective,
    GoAvatarComponent,
    CreateMomentDirective,
    IsLinkedMomentPipe,
    IsLinkedAppointmentMomentPipe,
    MomentConnectionLinkComponent,
    AppointmentMomentConnectorComponent,
    MomentComponent,
    TimelineFiltersComponent,
    AuditEventMomentComponent,
    AppointmentMomentComponent,
    CallMomentComponent,
    ChatMomentComponent,
    DischargeMomentComponent,
    CgiScoreMomentComponent,
    FileMomentComponent,
    NoteMomentComponent,
    GoogleDriveFileMomentComponent,
    AddMomentButtonComponent,
    AddCallMomentComponent,
    AddFileMomentComponent,
    AddNoteMomentComponent,
    AddEditGoogleFileMomentDescriptionComponent,
    AddEditAppointmentMomentComponent,
    SoftDeleteMomentDialogComponent,
    GoogleFileMomentDescriptionHistoryComponent,
    ChatLogDialogComponent,
    GoogleDriveFileMomentComponent,
    HasExpandableContentPipe,
    DeleteNoteMomentDialogComponent,
    ExpandNoteMomentDialogComponent,
    RiskAssessmentEventMomentComponent,
    UserHasScopePipe,
    CancelDialogComponent,
    DisplayMomentComponent,
    IsTimelineFilterSelectedPipe,
    AppClosePatientChatPanelDirective,
    NgForTrackByPropertyModule
  ],
  providers: [ConvertStringToTitleCasePipe, DisplayUserNamePipe, ConvertUnixTimeToUtcStringPipe, TimeAgoPipe],
})
export class SharedModule {}
