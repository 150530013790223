<div fxFill fxLayout="column" fxLayoutGap="12px" class="stepper-content" [formGroup]="appointmentFormGroup">
  <div *ngIf="editMode" [formGroup]="editReasonFormGroup" fxFlex>
    <mat-form-field fxFlex>
      <mat-label>Reason for editing moment</mat-label>
      <textarea cdkTextareaAutosize matInput formControlName="reason" cdkAutosizeMinRows="2" placeholder="Enter your reason here..."></textarea>
      <mat-error *ngIf="editReasonFormGroup.controls['reason'].hasError('required')">
        Reason for editing moment is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="editReasonFormGroup.controls['reason'].hasError('trimErrorStart')">
        Reason for editing moment cannot contain leading whitespaces
      </mat-error>
      <mat-error *ngIf="editReasonFormGroup.controls['reason'].hasError('trimErrorEnd')">
        Reason for editing moment cannot contain trailing whitespaces
      </mat-error>
      <mat-error *ngIf="editReasonFormGroup.controls['reason'].hasError('multipleWhitespacesError')">
        Reason for editing moment cannot contain multiple consecutive whitespaces
      </mat-error>
      <mat-error *ngIf="!editReasonFormGroup.controls['reason'].hasError('required') && editReasonFormGroup.controls['reason'].hasError('lengthError')">
        Reason for editing moment must contain more than 3 characters
      </mat-error>
    </mat-form-field>
  </div>

  <div fxFlex fxLayout="row" fxLayoutGap="12px">
    <mat-form-field fxFlex fxFlex.gt-md="80">
      <mat-label>Date of appointment</mat-label>
      <input matInput [matDatepicker]="picker" [max]="today" formControlName="appointmentDate" />
      <mat-hint>DD/MM/YYYY</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker disabled="false"></mat-datepicker>
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label>Select time</mat-label>
      <input matInput [matTimepicker]="timePicker" formControlName="appointmentTime">
      <mat-timepicker-toggle matIconSuffix [for]="timePicker" />
      <mat-timepicker #timePicker />
      <mat-hint>Enter time in 24 hour format</mat-hint>
      <mat-error *ngIf="appointmentFormGroup.controls['appointmentTime'].hasError('invalidTimeFuture')">
        Time cannot be in the future
      </mat-error>
      <mat-error *ngIf="appointmentFormGroup.controls['appointmentTime'].hasError('matTimepickerParse')">
        Time value is invalid, expected 24 hour format
      </mat-error>
    </mat-form-field>
  </div>

  <mat-form-field>
    <mat-label>Outcome of appointment</mat-label>
    <mat-select formControlName="appointmentAttendanceStatus">
      <mat-option *ngFor="let status of attendanceStates; trackByProperty: 'attendanceStates'" [value]="status">
        {{ status }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="appointmentFormGroup.controls['appointmentAttendanceStatus'].hasError('required')">
      Outcome of appointment is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="appointmentFormGroup.get('appointmentAttendanceStatus').value === 'Other'">
    <mat-label>Reason for other</mat-label>
    <input matInput formControlName="appointmentAttendanceStatusOther">
    <mat-error *ngIf="appointmentFormGroup.controls['appointmentAttendanceStatusOther'].hasError('required')">
      Reason for selecting "Other" is <strong>required</strong>
    </mat-error>
    <mat-error *ngIf="appointmentFormGroup.controls['appointmentAttendanceStatusOther'].hasError('trimErrorStart')">
      Reason for selecting "Other" cannot contain leading whitespaces
    </mat-error>
    <mat-error *ngIf="appointmentFormGroup.controls['appointmentAttendanceStatusOther'].hasError('trimErrorEnd')">
      Reason for selecting "Other" cannot contain trailing whitespaces
    </mat-error>
    <mat-error *ngIf="appointmentFormGroup.controls['appointmentAttendanceStatusOther'].hasError('multipleWhitespacesError')">
      Reason for selecting "Other" cannot contain multiple consecutive whitespaces
    </mat-error>
    <mat-error *ngIf="!appointmentFormGroup.controls['appointmentAttendanceStatusOther'].hasError('required') && appointmentFormGroup.controls['appointmentAttendanceStatusOther'].hasError('lengthError')">
      Reason for selecting "Other" must contain more than 3 characters
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Session number</mat-label>
    <input matInput placeholder="Number of therapy sessions so far" formControlName="appointmentSessionNumber" [min]="1" type="number">
    <mat-error *ngIf="appointmentFormGroup.controls['appointmentSessionNumber'].hasError('required')">
      Number of Therapy sessions user has recieved is <strong>required</strong>
    </mat-error>
    <mat-error *ngIf="appointmentFormGroup.controls['appointmentSessionNumber'].value && +appointmentFormGroup.controls['appointmentSessionNumber'].value < 1">
      Number of Therapy sessions user has recieved cannot be less than 1
    </mat-error>
  </mat-form-field>

  <ng-container *ngIf="!editMode">
    <mat-accordion *ngIf="previousNote else noPreviousNote">
      <mat-expansion-panel class="previous">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Previous note
          </mat-panel-title>
          <mat-panel-description class="previous-description">
            {{ previousNote?.author }} &nbsp; | &nbsp; {{ previousNote?.ts | date: 'EEEE, MMMM d, y, h:mm:ss a' }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="previous-content"><p [innerText]="previousNote?.data?.note"></p></div>
      </mat-expansion-panel>
    </mat-accordion>
    <ng-template #noPreviousNote><div>There are no previous notes for {{ patient?.email | displayUserName: patient?.firstName : patient?.lastName }}.</div></ng-template>
  </ng-container>

  <div [formGroup]="noteFormGroup" fxFlex>
    <mat-form-field fxFlex>
      <mat-label>Notes</mat-label>
      <textarea cdkTextareaAutosize #text matInput formControlName="note" cdkAutosizeMinRows="20" placeholder="Enter your notes here..."></textarea>
      <mat-error *ngIf="noteFormGroup.controls['note'].hasError('required')">
        Notes are <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="noteFormGroup.controls['note'].hasError('trimErrorStart')">
        Notes cannot contain leading whitespaces
      </mat-error>
      <mat-error *ngIf="noteFormGroup.controls['note'].hasError('trimErrorEnd')">
        Notes cannot contain trailing whitespaces
      </mat-error>
      <mat-error *ngIf="noteFormGroup.controls['note'].hasError('multipleWhitespacesError')">
        Notes cannot contain multiple consecutive whitespaces
      </mat-error>
      <mat-error *ngIf="!noteFormGroup.controls['note'].hasError('required') && noteFormGroup.controls['note'].hasError('lengthError')">
        Notes must contain more than 3 characters
      </mat-error>
    </mat-form-field>
  </div>

  <div class="function-question">
    <p><label>{{ functionQuestion }}</label></p>
    <mat-radio-group formControlName="functionResult">
      <mat-radio-button *ngFor="let answer of functionAnswers; trackByProperty: 'functionQuestionAnswers'" [value]="answer">{{ answer }}</mat-radio-button>
    </mat-radio-group>
  </div>
</div>
