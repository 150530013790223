import { Component, Input } from '@angular/core';
import { FormGroup, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';

import { takeUntil } from 'rxjs';

import { OnDestroyObservable } from '@app/classes/on-destroy-observable';
import { FeatureFlagService } from '@shared/services/feature-flag.service';
import { AppointmentMomentService } from '../../appointment-moment.service';
import { CheckableMoment } from './attachments.model';
import { TubPatient } from '@backend-client/models/tub-patient';
import { MomentService } from '../../../moment/moment.service';
import { GoogleDriveFileMoment } from '../../../moment/google-drive-file-moment/google-drive-file-moment';

@Component({
    selector: 'app-attachments-step',
    templateUrl: './attachments-step.component.html',
    styleUrls: ['./attachments-step.component.scss'],
    standalone: false
})
export class AttachmentsStepComponent extends OnDestroyObservable  {
  @Input() attachmentsFormGroup: FormGroup;
  @Input() patient: TubPatient;

  public featureFlags$ = this.featureFlagService.featureFlags$;
  // Values all stored under appointment moment service

  constructor(
    private formBuilder: UntypedFormBuilder,
    private featureFlagService: FeatureFlagService,
    public momentService: MomentService,
    public appointmentMomentService: AppointmentMomentService
  ) {
    super();
  }

  public async addEditDescriptionForGoogleFileMoment(attachment: GoogleDriveFileMoment) {
    const dialogRef = await this.momentService.addEditDescriptionForGoogleFileMoment(attachment, attachment?.description);
    dialogRef.afterClosed().pipe(takeUntil(this.ngOnDestroy$)).subscribe(reload => {
      if (reload) {
        this.appointmentMomentService.reloadAttachments();
      }
    });
  }

  public toggleAttachmentSelection(attachment: CheckableMoment): void {
    const formArray = (this.attachmentsFormGroup.get('moments') as UntypedFormArray);
    const isChecked = this.appointmentMomentService.attachmentsCheckedList$.value.some(item => item.id === attachment.id);

    if (isChecked) {
      this.uncheckAttachment(attachment, formArray);
    } else {
      this.checkAttachment(attachment, formArray);
    }

    this.markFormGroupAsTouchedAndDirty();
  }

  public detachDeletedAttachment(attachment: CheckableMoment): void {
    const formArray = (this.attachmentsFormGroup.get('moments') as UntypedFormArray);
    this.uncheckAttachment(attachment, formArray);
    this.markFormGroupAsTouchedAndDirty();
  }

  private uncheckAttachment(attachment: CheckableMoment, formArray: UntypedFormArray): void {
    this.appointmentMomentService.attachmentsCheckedList$.next(this.appointmentMomentService.attachmentsCheckedList$.value.filter(a => a.id !== attachment.id));

    attachment.checked = false;
    const removeIndex = formArray.controls.findIndex(control => control.value === attachment.id);
    formArray.removeAt(removeIndex);
  }

  private checkAttachment(attachment: CheckableMoment, formArray: UntypedFormArray): void {
    this.appointmentMomentService.attachmentsCheckedList$.value.push(attachment);
    attachment.checked = true;
    formArray.push(this.formBuilder.control(attachment.id));
  }

  private markFormGroupAsTouchedAndDirty(): void {
    this.attachmentsFormGroup.markAsTouched();
    this.attachmentsFormGroup.markAsDirty();
  }
}
