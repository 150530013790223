
import { ReplaySubject } from 'rxjs';
import { ObserverRepository } from './observer-repository';

/* Protobuf Imports */
import {
    Response,
    CommandObserveProperty, CommandUpdateProperty, Any, Message
} from '@thrivesoft/gocore-web';

/**
* GoCore can send us various message types on these property observer channels
*/
export class GoCoreObserverRepository extends ObserverRepository {

    static readonly opSystem = 'SYSTEM_OBSERVER';
    static readonly opConfig = 'LOCALCONFIGKEYS';
    static readonly opCache = 'LOCALCACHE';
    static readonly opAPIKeys = 'APIKEYS';
    static readonly opAuth = 'AUTHORISATION';
    static readonly opUserProfile = 'USERPROFILESTATUS';
    static readonly opChatInfoProperty = 'CHATINFO';
    static readonly opChatTypingProperty = 'CHATTYPING';
    static readonly opChatStatusProperty = 'CHATSTATUS';
    static readonly opChatMessagesProperty = 'CHATMESSAGES';
    static readonly opChatLastReadProperty = 'CHATLASTREAD';
    static readonly opChatChannelsProperty = 'CHATCHANNELS';
    static readonly opChatErrorProperty = 'CHATERROR';

    private _callbacks: Map<string, ReplaySubject<Response>>;

    constructor(
        private sendCommand: <T extends Message>(command: T, callback?: (r: Response) => void) => void
    ) {
        super();

        this._callbacks = new Map();

        this.observeProperty(GoCoreObserverRepository.opSystem, this.onSystemMessage);

        const commandPropertyObserver = new CommandObserveProperty({
            id: 'Client',
            key: ''
        }) as Message;

        this.sendCommand(commandPropertyObserver, (response: Response) => this.handleMessage(response));
    }

    observeProperty(propertyId: string, callback?: (r: Response) => void) {

        if (!this._callbacks.has(propertyId)) {
            const streamController = new ReplaySubject<Response>();
            this._callbacks.set(propertyId, streamController);
        }

        return this._callbacks.get(propertyId).subscribe(callback);
    }

    updateProperty<T extends Message>(propertyId: string, value: T): void {
        const innerCmd = new CommandUpdateProperty(
            {
                key: propertyId,
                message: Any.pack(value)
            }
        );

        this.sendCommand(innerCmd as Message);
    }

    private onSystemMessage(): void {}

    private handleMessage(response: Response): void {
        const propertyId = response.message;

        // If there is no registered listener, create a stream here so the
        // listener will automatically receive the events when it becomes available
        if (!this._callbacks.has(propertyId)) {
            const streamController = new ReplaySubject<Response>();
            this._callbacks.set(propertyId, streamController);
        }

        this._callbacks.get(propertyId)?.next(response);
    }
}
