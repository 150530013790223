<div *ngIf="!savingData && !savingCompleted" class="form-status validation" fxLayout="column" fxLayoutGap="12px">
  <p>Please recheck all data being submitted is for {{ patient?.email | displayUserName: patient?.firstName : patient?.lastName }} before saving.</p>
  <div *ngIf="editMode">
    <p class="header">Edit Reason</p>
    <p>
      <mat-icon [ngClass]="{ error: editReasonFormGroupValid }">
        {{ editReasonFormGroupValid ? 'error' : 'check_circle' }}
      </mat-icon>
      <ng-container *ngIf="editReasonFormGroup.get('reason').hasError('required')">Reason for editing is required</ng-container>
      <ng-container *ngIf="editReasonFormGroup.get('reason').hasError('trimErrorStart')">Reason for editing cannot contain leading whitespaces</ng-container>
      <ng-container *ngIf="editReasonFormGroup.get('reason').hasError('trimErrorEnd')">Reason for editing cannot contain trailing whitespaces</ng-container>
      <ng-container *ngIf="editReasonFormGroup.get('reason').hasError('multipleWhitespacesError')">Reason for editing cannot contain multiple consecutive whitespaces</ng-container>
      <ng-container *ngIf="!editReasonFormGroup.get('reason').hasError('required') && editReasonFormGroup.get('reason').hasError('lengthError')">Reason for editing must contain more than 3 characters</ng-container>
      <ng-container *ngIf="!editReasonFormGroupValid">Ready to save</ng-container>
  </div>

  <div>
    <p class="header">Appointment Information</p>
    <p>
      <mat-icon [ngClass]="{error: appointmentFormGroup.invalid}">
        {{ appointmentFormGroup.invalid ? 'error' : 'check_circle' }}
      </mat-icon>
      {{ appointmentFormGroup.invalid ? 'Form contains errors:' : 'Ready to save' }}
    </p>
    <div>
      <p>
        <mat-icon [ngClass]="{error: (appointmentFormGroup.get('appointmentDate').value > today)}">
          {{ (appointmentFormGroup.get('appointmentDate').value > today) ? 'error' : 'check_circle' }}
        </mat-icon>
        Date <ng-container *ngIf="appointmentFormGroup.get('appointmentDate').value > today">cannot be in the future</ng-container>
      </p>
      <p>
        <mat-icon [ngClass]="{error: timeValid}">
          {{  timeValid ? 'error' : 'check_circle' }}
        </mat-icon>
        Time <ng-container *ngIf="appointmentFormGroup.get('appointmentTime').hasError('required')">is required</ng-container>
        <ng-container *ngIf="appointmentFormGroup.get('appointmentTime').hasError('invalidTimeFuture')">cannot be in the future</ng-container>
        <ng-container *ngIf="appointmentFormGroup.get('appointmentTime').hasError('matTimepickerParse')">value is invalid, please enter a valid 24hr format</ng-container>
      </p>
      <p>
        <mat-icon [ngClass]="{error: appointmentFormGroup.get('appointmentAttendanceStatus').hasError('required')}">
          {{ appointmentFormGroup.get('appointmentAttendanceStatus').hasError('required') ? 'error' : 'check_circle' }}
        </mat-icon>
        Outcome of appointment <ng-container *ngIf="appointmentFormGroup.get('appointmentAttendanceStatus').hasError('required')">is required</ng-container>
      </p>
      <p *ngIf="appointmentAttendanceStatusOtherValid">
        <mat-icon [ngClass]="{error: appointmentAttendanceStatusOtherValid}">
          {{ appointmentAttendanceStatusOtherValid ? 'error' : 'check_circle' }}
        </mat-icon>
        Reason for Other <ng-container *ngIf="appointmentFormGroup.get('appointmentAttendanceStatusOther').hasError('required')">is required</ng-container>
        <ng-container *ngIf="appointmentFormGroup.get('appointmentAttendanceStatusOther').hasError('trimErrorStart')">cannot contain leading whitespaces</ng-container>
        <ng-container *ngIf="appointmentFormGroup.get('appointmentAttendanceStatusOther').hasError('trimErrorEnd')">cannot contain trailing whitespaces</ng-container>
        <ng-container *ngIf="appointmentFormGroup.get('appointmentAttendanceStatusOther').hasError('multipleWhitespacesError')">cannot contain multiple consecutive whitespaces</ng-container>
        <ng-container *ngIf="!appointmentFormGroup.get('appointmentAttendanceStatusOther').hasError('required') && appointmentFormGroup.get('appointmentAttendanceStatusOther').hasError('lengthError')">must contain more than 3
          characters</ng-container>
      </p>
      <p>
        <mat-icon [ngClass]="{error: appointmentSessionNumberValid}">
          {{ appointmentSessionNumberValid ? 'error' : 'check_circle' }}
        </mat-icon>
        Session number <ng-container *ngIf="appointmentFormGroup.get('appointmentSessionNumber').hasError('required')">is required</ng-container>
        <ng-container *ngIf="appointmentFormGroup.get('appointmentSessionNumber').value && +appointmentFormGroup.get('appointmentSessionNumber').value < 1">cannot be less than 1</ng-container>
      </p>
      <p>
        <mat-icon [ngClass]="{error: notesValid}">
          {{ notesValid ? 'error' : 'check_circle' }}
        </mat-icon>
        Notes <ng-container *ngIf="noteFormGroup.get('note').hasError('required')">are required</ng-container>
        <ng-container *ngIf="noteFormGroup.get('note').hasError('trimErrorStart')">cannot contain leading whitespaces</ng-container>
        <ng-container *ngIf="noteFormGroup.get('note').hasError('trimErrorEnd')">cannot contain trailing whitespaces</ng-container>
        <ng-container *ngIf="noteFormGroup.get('note').hasError('multipleWhitespacesError')">cannot contain multiple consecutive whitespaces</ng-container>
        <ng-container *ngIf="!noteFormGroup.get('note').hasError('required') && noteFormGroup.get('note').hasError('lengthError')">must contain more than 3 characters</ng-container>
      </p>
      <p *ngIf="!sessionCancelled">
        <mat-icon [ngClass]="{error: appointmentFormGroup.get('functionResult').hasError('required')}">
          {{ appointmentFormGroup.get('functionResult').hasError('required') ? 'error' : 'check_circle' }}
        </mat-icon>
        How is the patient functioning question <ng-container *ngIf="appointmentFormGroup.get('functionResult').hasError('required')">is required</ng-container>
      </p>
    </div>
  </div>

  <div>
    <p class="header">PHQ9</p>
    <button *ngIf="phqValid" (click)="markGadPhqAsNotRequired.emit()" mat-flat-button color="warn">
      Mark PHQ9 / GAD7 as not required
    </button>
    <p *ngIf="!sessionCancelled && phqFormGroup.invalid && phqFormGroup.errors !== null || !sessionCancelled && phqFormGroup.invalid && phqFormGroup.dirty">
      <mat-icon class="error">error</mat-icon> Form contains errors:
    </p>
    <p *ngIf="!sessionCancelled && phqFormGroup.valid && phqFormGroup.dirty"><mat-icon>check_circle</mat-icon> Ready to save</p>
    <p *ngIf="!sessionCancelled && phqFormGroup.errors === null && phqFormGroup.pristine">
      <mat-icon class="warn">warning</mat-icon> Not required. <small>If this is wrong, please go answer PHQ questions.</small>
    </p>
    <p *ngIf="sessionCancelled && phqFormGroup.touched && phqFormGroup.valid && gadFormGroup.invalid">Please answer GAD questions or mark both as not required.</p>

    <ng-container *ngIf="!sessionCancelled || (sessionCancelled && phqFormGroup.dirty); else notRequired">
      <ng-template *ngIf="sessionCancelled" [ngTemplateOutlet]="notRequired"></ng-template>
      <div *ngIf="phqFormGroup.errors !== null || phqFormGroup.dirty">
        <p *ngFor="let phqQuestion of phqQuestionsKeys; let i = index; trackByProperty: 'phqQuestionsKey'">
          <mat-icon [ngClass]="{ error: phqFormGroup.get(phqQuestion).hasError('required') }">
            {{ phqFormGroup.get(phqQuestion).hasError('required') ? 'error' : 'check_circle' }}
          </mat-icon>
          PHQ9 Question {{ i + 1 }}
          <ng-container *ngIf="phqFormGroup.get(phqQuestion).hasError('required') && phq9Active">answer is required</ng-container>
          <ng-container *ngIf="i > 1 && !phq9Active">not required</ng-container>
        </p>
      </div>
    </ng-container>
  </div>

  <div>
    <p class="header">GAD7</p>
    <button *ngIf="gadValid" (click)="markGadPhqAsNotRequired.emit()" mat-flat-button color="warn">
      Mark PHQ9 / GAD7 as not required
    </button>
    <p *ngIf="!sessionCancelled && gadFormGroup.invalid && gadFormGroup.errors !== null || !sessionCancelled && gadFormGroup.invalid && gadFormGroup.dirty">
      <mat-icon class="error">error</mat-icon> Form contains errors:
    </p>
    <p *ngIf="!sessionCancelled && gadFormGroup.valid && gadFormGroup.dirty"><mat-icon>check_circle</mat-icon> Ready to save</p>
    <p *ngIf="!sessionCancelled && gadFormGroup.errors === null && gadFormGroup.pristine">
      <mat-icon class="warn">warning</mat-icon> Not required. <small>If this is wrong, please go answer GAD questions.</small>
    </p>
    <p *ngIf="sessionCancelled && gadFormGroup.touched && gadFormGroup.valid && phqFormGroup.invalid">Please answer PHQ questions or mark both as not required.</p>

    <ng-container *ngIf="!sessionCancelled || (sessionCancelled && gadFormGroup.dirty); else notRequired">
      <ng-template *ngIf="sessionCancelled" [ngTemplateOutlet]="notRequired"></ng-template>
      <div *ngIf="gadFormGroup.errors !== null || gadFormGroup.dirty">
        <p *ngFor="let gadQuestion of gadQuestionsKeys; let i = index; trackByProperty: 'gadQuestionsKey'">
          <mat-icon [ngClass]="{error: gadFormGroup.get(gadQuestion).hasError('required')}">
            {{ gadFormGroup.get(gadQuestion).hasError('required') ? 'error' : 'check_circle' }}
          </mat-icon>
          GAD7 Question {{ i + 1 }}
          <ng-container *ngIf="gadFormGroup.get(gadQuestion).hasError('required') && gad7Active">answer is required</ng-container>
          <ng-container *ngIf="i > 1 && !gad7Active">not required</ng-container>
        </p>

        <ng-container *ngIf="shouldDisplayGadPanicQuestion">
          <p *ngFor="let gadPanicQuestion of gadPanicQuestionKey; let i = index; trackByProperty: 'gadPanicQuestionKey'">
            <mat-icon [ngClass]="{error: gadPanicFormGroup.get(gadPanicQuestion).hasError('required')}">
              {{ gadPanicFormGroup.get(gadPanicQuestion).hasError('required') ? 'error' : 'check_circle' }}
            </mat-icon>
            GAD7 Panic Question
            <ng-container *ngIf="gadPanicFormGroup.get(gadPanicQuestion).hasError('required') && gad7Active">answer is required</ng-container>
            <ng-container *ngIf="!gad7Active">not required</ng-container>
          </p>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <div>
    <p class="header">CGI-S/ CGI-I</p>
    <p *ngIf="!sessionCancelled">
      <mat-icon [ngClass]="{error: cgiFormGroup.invalid}">
        {{ cgiFormGroup.invalid ? 'error' : 'check_circle' }}
      </mat-icon> {{ cgiFormGroup.invalid ? 'Form contains errors:' : 'Ready to save' }}
    </p>
    <div *ngIf="!sessionCancelled || (sessionCancelled && cgiFormGroup.dirty); else notRequired">
      <ng-template *ngIf="sessionCancelled" [ngTemplateOutlet]="notRequired"></ng-template>
      <p>
        <mat-icon [ngClass]="{error: cgiFormGroup.get('cgiSeverityResult').hasError('required')}">
          {{ cgiFormGroup.get('cgiSeverityResult').hasError('required') ? 'error' : 'check_circle' }}
        </mat-icon>
        CGI Severity <ng-container *ngIf="cgiFormGroup.get('cgiSeverityResult').hasError('required')"> is required</ng-container>
      </p>
      <p>
        <mat-icon [ngClass]="{error: cgiFormGroup.get('cgiImprovementResult').hasError('required')}">
          {{ cgiFormGroup.get('cgiImprovementResult').hasError('required') ? 'error' : 'check_circle' }}
        </mat-icon>
        CGI Improvement <ng-container *ngIf="cgiFormGroup.get('cgiImprovementResult').hasError('required')"> is required</ng-container>
      </p>
    </div>
  </div>

  <div>
    <p class="header">Attachments</p>
    <div *ngIf="!sessionCancelled || (sessionCancelled && attachmentsFormGroup.get('moments').value.length > 0); else notRequired">
      <ng-template *ngIf="sessionCancelled" [ngTemplateOutlet]="notRequired"></ng-template>
      <p>
        <mat-icon [ngClass]="{warn: attachmentsFormGroup.get('moments').value.length === 0}">
          {{ attachmentsFormGroup.get('moments').value.length === 0 ? 'warning' : 'check_circle' }}
        </mat-icon>
        {{ attachmentsFormGroup.get('moments').value.length === 0 ?
          'There are no attachments selected' :
          attachmentsFormGroup.get('moments').value.length + ' attachment' + (attachmentsFormGroup.get('moments').value.length > 1 ? 's are' : '') + ' selected' }}
      </p>
    </div>
  </div>

  <div>
    <button mat-flat-button color="primary" [disabled]="saveDisabled()" (click)="save.emit()">Save</button>
  </div>

  <ng-template #notRequired>
    <p class="not-required"><mat-icon class="warn">warning</mat-icon> Not required due to session not taking place.</p>
  </ng-template>
</div>
