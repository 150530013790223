import { Component } from '@angular/core';

import { FeatureFlagService } from '@shared/services/feature-flag.service';
import { FileMoment } from './file-moment';
import { MomentService } from '../moment.service';
import { AppointmentStep } from '../../add-edit-appointment-moment/appointment-step.enum';
import { MomentType } from '../moment-type';

@Component({
    selector: 'app-file-moment',
    templateUrl: './file-moment.component.html',
    styleUrls: ['./file-moment.component.scss'],
    standalone: false
})
export class FileMomentComponent {

  public momentData: FileMoment;
  public momentType = MomentType;
  public featureFlags$ = this.featureFlagService.featureFlags$;
  public appointmentStep = AppointmentStep;

  constructor(
    public momentService: MomentService,
    private featureFlagService: FeatureFlagService,
  ) { }
}
