<div fxLayout="column" fxLayoutGap="24px" [formGroup]="attachmentsFormGroup">
  <div fxFlex fxLayout="column" class="new-moment">
    <p class="header">Upload Therapy Session Files</p>
    <div fxFlex fxLayout="row" fxLayoutGap="12px">
      <button mat-flat-button color="primary" (click)="appointmentMomentService.uploadAttachment()">Upload File</button>
      <button *ngIf="!(featureFlags$ | async)?.isGoogleDriveEnabled" mat-flat-button color="primary" (click)="appointmentMomentService.uploadCallAttachment()">
        Upload Call Transcript
      </button>
    </div>
  </div>

  <p class="information-text">
    Therapy session files can only be attached to one appointment moment.
    {{ !appointmentMomentService.isEditMode ? 'Deleted files are not displayed.' : 'Deleted files are displayed for detaching only.' }}
  </p>

  <div fxLayout="row" fxLayoutAlign="end" class="reload-container">
    <button mat-flat-button [disabled]="appointmentMomentService.fetchingAttachments$ | async" (click)="appointmentMomentService.reloadAttachments()"><mat-icon>refresh</mat-icon> reload</button>
    <button mat-flat-button [disabled]="appointmentMomentService.fetchingAttachments$ | async" (click)="appointmentMomentService.loadMoreAttachments()"><mat-icon>cached</mat-icon> Load More</button>
  </div>

  <div *ngIf="appointmentMomentService.fetchingAttachments$ | async; else attachment" fxLayout="row" fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
  </div>

  <ng-template #attachment>
    <div fxLayout="column" fxLayoutGap="24px" class="attachment-container">
      <div *ngIf="appointmentMomentService.attachments.length === 0">
        <p>There are no file moments for {{ patient?.email | displayUserName: patient?.firstName : patient?.lastName }}.</p>
      </div>

      <mat-card *ngFor="let attachment of appointmentMomentService.attachments; trackByProperty: 'appointmentAttachments'" class="attachment-moment" [ngClass]="{'deleted': attachment.deleted}">
        <mat-card-header>
          <div fxFlex fxLayout="row">
            <mat-icon *ngIf="attachment.typeName === 'google-file'; else defaultIcon" svgIcon="google-drive" class="file-icon" [ngStyle]="{color: undefined}"></mat-icon>
            <ng-template #defaultIcon>
              <mat-icon class="file-icon" [ngStyle]="{color: attachment.typeName === 'file' ? 'rgb(253, 180, 127)' : 'rgb(70, 203, 243)'}">
                {{ attachment.typeName === 'file' ? 'save' : 'call' }}
              </mat-icon>
            </ng-template>

            <div fxFlex fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="start center">
              <p class="heading" fxFlex>
                <span *ngIf="attachment.deleted" class="deleted"><strong>Deleted! </strong></span>
                {{ attachment.fileName }}
              </p>
              <span>{{ attachment.author }} &nbsp; | &nbsp; {{ attachment.timestamp | date: 'EEEE, MMMM d, y, h:mm:ss a' }}</span>
            </div>
            <mat-checkbox *ngIf="!attachment.deleted; else detachButton" (change)="toggleAttachmentSelection(attachment)" (click)="$event.stopPropagation()" [checked]="attachment.checked"></mat-checkbox>
            <ng-template #detachButton><mat-checkbox (change)="detachDeletedAttachment(attachment)" (click)="$event.stopPropagation()" [disabled]="!attachment.checked" [checked]="attachment.checked"></mat-checkbox></ng-template>
          </div>
        </mat-card-header>
        <mat-card-content *ngIf="attachment?.description">
          {{ attachment?.description }}
        </mat-card-content>
        <mat-card-footer>
          <button *ngIf="attachment.typeName === 'google-file' && !attachment.deleted" mat-button color="primary" (click)="addEditDescriptionForGoogleFileMoment(attachment)">{{ !attachment?.description ? 'Add' : 'Edit' }} Description</button>
          <button *ngIf="attachment.typeName === 'google-file'" mat-button (click)="momentService.previewGoogleDriveMoment(attachment)">Preview</button>
          <button *ngIf="attachment.typeName === 'file' || attachment.typeName === 'call'" mat-button color="accent" (click)="momentService.downloadGoogleDriveMoment(attachment)">Download</button>
        </mat-card-footer>
      </mat-card>
    </div>
  </ng-template>
</div>
