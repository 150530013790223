import { AcceptedChat_Patient, PubNubChannel, PubNubChannelUser, protoInt64 } from '@thrivesoft/gocore-web';

export class ChatChannelModel {
    constructor(
        public name: string,
        public time: bigint,
        public messageCount: number,
        public participants: PubNubChannelUser[],
        public Patient: AcceptedChat_Patient
    ) { }

    static fromProto(proto: PubNubChannel) {
        return new ChatChannelModel(
            proto.Name,
            protoInt64.parse(proto.time),
            proto.MessageCount,
            proto.Participants,
            proto.Patient,
        );
    }

    static list(list: PubNubChannel[]): ChatChannelModel[] {
        return list.map((c) => ChatChannelModel.fromProto(c));
    }

    static convertToChannelList(channels: {[key: string]: PubNubChannel}): ChatChannelModel[] {
        return Object.values(channels).map(ChatChannelModel.fromProto) ?? [];
    }
      }
