<mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" id="header" color="primary">
  <div id="title-container" fxLayout="row" fxLayoutGap.gt-xs="16px">
    <img id="thrive-logo-icon" *ngIf="mediaObserver.isActive('xs') || !(mainNavService.mainNavOpen$ | async)" fxShow style="padding: 0.5em" src="assets/images/thrive-icon-logo.webp" width="43" height="43">
    <img id="thrive-logo-full"  *ngIf="mediaObserver.isActive('gt-sm') && (mainNavService.mainNavOpen$ | async) || mediaObserver.isActive('sm') && (mainNavService.mainNavOpen$ | async)" fxShow src="assets/images/thrive-logo.webp" width="116" height="43">
    <div fxLayout.xs="column" fxLayoutAlign.xs="center" ngStyle.xs="margin-left: 6px">
      <div id="region-flag" fxLayoutAlign="center center" style="padding: 10px">
        <img width="50px" height="25" src="assets/images/flags/{{isAustralia ? 'au' : 'gb'}}.webp">
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="left center">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
              *ngIf="mediaObserver.isActive('lt-md')">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <div fxLayout="row" fxLayoutGap="30px">
        <div *ngIf="mediaObserver.isActive('gt-sm')">{{ title }}</div>
        <ng-container *ngIf="(featureFlagService.featureFlags$ | async)?.isThrive24Enabled">
          <app-office-hours *appUserHasScope="'region-settings:view'" id="chat-coverage"></app-office-hours>
        </ng-container>
      </div>
    </div>
  </div>

  <div id="server-status">
    {{ serverStatusMessage }}
  </div>

  <div fxLayout="row">
    <!--    <button mat-icon-button (click)="feedbackButtonClicked()">-->
    <!--      <mat-icon>feedback</mat-icon>-->
    <!--    </button>-->
    <ng-container *appUserHasScope="'chat:view-active-chats'">
      <app-failed-messages-button></app-failed-messages-button>
      <app-message-notification-button></app-message-notification-button>
      <app-primary-notification-button></app-primary-notification-button>
      <div class="notification-divider"></div>
    </ng-container>

    <!--    go-chat-duplication start-->
    <ng-container>
      <div fxLayout="row" *appUserHasScope="'chat:view-active-chats'" >
        <div class="version-text" fxFlexAlign="center">V2</div>
        <app-go-message-notification-button></app-go-message-notification-button>
        <app-go-primary-notification-button></app-go-primary-notification-button>
        <div class="notification-divider"></div>
      </div>
    </ng-container>
    <!--    go-chat-duplication end-->

    <button mat-icon-button (click)="signOutButtonClicked()">
      <mat-icon>logout</mat-icon>
    </button>
  </div>
</mat-toolbar>
<mat-progress-bar *ngIf="routerResolveStatus.IsLoading" mode="indeterminate" color="warn"></mat-progress-bar>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport="false" autoFocus="false"
                [attr.role]="mediaObserver.isActive('lt-md') ? 'dialog' : 'navigation'"
                [mode]="mediaObserver.isActive('lt-md') ? 'over' : 'side'"
                [opened]="!(mediaObserver.isActive('lt-md'))"
                (click)="mediaObserver.isActive('lt-md') && drawer.toggle()"
                (mouseenter)="updateState()"
                (mouseleave)="clearState()" [opened]="mediaObserver.isActive('lt-md') ? 'false' : 'true'" class="animated-sidenav"
                [class.hovering]="(mainNavService.mainNavState$ | async)" [class.open]="(mainNavService.mainNavOpen$ | async)"
                [@onMainNavChange]="mediaObserver.isActive('gt-xs') ? (mainNavService.mainNavState$ | async) ? 'open' : 'close' : null">
    <mat-nav-list>
      <!-- Home link -->
      <app-nav-link *ngIf="!hideNavForCallback" [linkData]="{displayName: 'Home', link: '/home', matIcon: 'home'}"></app-nav-link>

      <ng-container *ngFor="let linkCollection of linkCollections; trackByProperty: 'navLinkCollections'">
        <div *ngIf="linkCollection.links.length > 0" fxLayout="row" fxLayoutAlign="center" fxLayoutGap="3px" class="menu-divider">
          <mat-divider fxFlex="1 1 6px"></mat-divider>
          <div class="divider-text">{{ linkCollection.title }}</div>
          <mat-divider fxFlex="1 0"></mat-divider>
        </div>
        <ng-container *ngFor="let navLink of linkCollection.links; trackByProperty: 'navLinks'">
          <app-nav-link [linkData]="navLink"></app-nav-link>
        </ng-container>
      </ng-container>
    </mat-nav-list>
    <div *ngIf="mediaObserver.isActive('gt-xs')" class="fix-menu-width">
      <button mat-icon-button [class.spin]="(mainNavService.mainNavOpen$ | async)" (click)="fixSideNavOpen(); $event.preventDefault();">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
  </mat-sidenav>
  <mat-sidenav-content [@onMainContentChange]="(mainNavService.mainNavState$ | async) ? 'open' : 'close'"
    [ngClass]="{'open': mediaObserver.isActive('gt-xs') ? (mainNavService.mainNavState$ | async) : null, 'closed': mediaObserver.isActive('gt-xs') ? !(mainNavService.mainNavState$ | async) : null}">
    <ng-content *ngIf="!routerResolveStatus.IsLoading"></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
