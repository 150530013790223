import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DashboardTubMoment, TubPatient } from '@backend-client/models';

import {
  CGI_Improvement,
  CGI_Improvement_Answers,
  CGI_Improvement_Question,
  CGI_Severity,
  CGI_Severity_Answers,
  CGI_Severity_Hint,
  CGI_Severity_Question
} from './cgi-questions';
import { TimelineService } from '../../../timeline.service';
import { MomentType } from '../../../moment/moment-type';

@Component({
    selector: 'app-cgi-step',
    templateUrl: './cgi-step.component.html',
    styleUrls: ['./cgi-step.component.scss'],
    standalone: false
})
export class CgiStepComponent implements OnInit {
  @Input() patientId: string;
  @Input() patient: TubPatient;
  @Input() editMode: boolean;
  @Input() cgiFormGroup: FormGroup;

  public previousCGI: DashboardTubMoment;
  public cgiSeverityExplanation = CGI_Severity;
  public cgiSeverityQuestion = CGI_Severity_Question;
  public cgiSeverityQuestionHint = CGI_Severity_Hint;
  public cgiSeverityKeys = Object.keys(CGI_Severity_Answers);
  public cgiSeverityAnswers = Object.values(CGI_Severity_Answers);
  public cgiImprovementExplanation = CGI_Improvement;
  public cgiImprovementQuestion = CGI_Improvement_Question;
  public cgiImprovementKeys = Object.keys(CGI_Improvement_Answers);
  public cgiImprovementAnswers = Object.values(CGI_Improvement_Answers);

  constructor(private timelineService: TimelineService) {}

  async ngOnInit() {
    if (!this.editMode) {
      try {
        this.previousCGI = (await this.timelineService.getMoments(this.patientId, [MomentType.CgiScore]))[0];
      } catch (e) {
        console.error(e);
      }
    }
  }
}
