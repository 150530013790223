import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { HttpErrorResponse } from '@angular/common/http';

import {  BehaviorSubject, debounceTime, distinctUntilChanged, filter, take, takeUntil } from 'rxjs';

import { TubAppointmentMoment, TubCgiCreationRequestBody, TubEditAppointmentMomentRequestBody, TubHasActiveChat, TubPatient, TubPatientRiskAssessment } from '@backend-client/models';
import { DialogService } from '@shared/components/dialog/dialog.service';
import { OnDestroyObservable } from '@app/classes/on-destroy-observable';
import { PatientProfileService } from '@app/modules/therapist/assigned-patients/patient-profile/patient-profile.service';
import { TimelineService } from '../timeline.service';
import { AppointmentMoment } from '../moment/appointment-moment/appointment-moment';
import { NoteMoment } from '../moment/note-moment/note-moment';
import { RiskAssessmentEventMoment } from '../moment/risk-assessment-event-moment/risk-assessment-event-moment';
import { CgiScoreMoment } from '../moment/cgi-score/cgi-score-moment';
import { CancelDialogComponent } from './cancel-dialog/cancel-dialog.component';
import { GAD7, GAD7_Panic, PHQ9 } from './stepper-components/phq-step/phq-gad-questionnaire';
import { AttendanceStates } from './stepper-components/appointment-step/attendance-states';
import { CheckableMoment } from './stepper-components/attachments-step/attachments.model';
import { AppointmentMomentService } from './appointment-moment.service';
import { StringEntryControlValidator } from '@shared/utils/string-entry-control-validator';
import { MomentService } from '../moment/moment.service';
import { AppointmentStep } from './appointment-step.enum';

@Component({
  selector: 'app-add-edit-appointment-moment',
  templateUrl: './add-edit-appointment-moment.component.html',
  styleUrls: [ './add-edit-appointment-moment.component.scss' ],
  providers: [ TimelineService, AppointmentMomentService, MomentService ],
  standalone: false
})
export class AddEditAppointmentMomentComponent extends OnDestroyObservable implements OnInit {

  @ViewChild('stepper') stepper: MatStepper;

  // If the GAD score is below this threshold, then display the panic question
  private readonly GAD_SCORE_THRESHOLD = 10;

  private id: string;
  private appointment: AppointmentMoment;
  private previousLocation: string;

  public patientId: string;
  public patient: TubPatient;
  public patientChat: TubHasActiveChat;
  public preloadStep: AppointmentStep;
  public sessionCancelled = false;
  public editReasonFormGroup = this.formBuilder.group({
    reason: ['', [Validators.required, StringEntryControlValidator.isInvalidStringEntryValidator()]],
  });
  public appointmentFormGroup = this.formBuilder.group({
    appointmentDate: [{ value: new Date(), disabled: true }, [Validators.required]],
    appointmentTime: [ new Date(), [Validators.required, this.isInvalidTimeValidator()]],
    appointmentAttendanceStatus: ['', Validators.required],
    appointmentAttendanceStatusOther: '',
    appointmentSessionNumber: ['', [Validators.required, Validators.min(1)]],
    functionResult: ['', Validators.required],
  });
  public noteFormGroup = this.formBuilder.group({
    note: ['', [Validators.required, StringEntryControlValidator.isInvalidStringEntryValidator()]],
  });
  public phqFormGroup = this.formBuilder.group({
    PHQ9_1: ['', Validators.required],
    PHQ9_2: ['', Validators.required],
    PHQ9_3: '',
    PHQ9_4: '',
    PHQ9_5: '',
    PHQ9_6: '',
    PHQ9_7: '',
    PHQ9_8: '',
    PHQ9_9: '',
  });
  public gadFormGroup = this.formBuilder.group({
    GAD7_1: ['', Validators.required],
    GAD7_2: ['', Validators.required],
    GAD7_3: '',
    GAD7_4: '',
    GAD7_5: '',
    GAD7_6: '',
    GAD7_7: '',
  });
  // This is its own form group as it needs isolating for the feature flag
  public gadPanicFormGroup = this.formBuilder.group({
    GAD7_Panic: '',
  });
  public cgiFormGroup = this.formBuilder.group({
    cgiSeverityResult: ['', Validators.required],
    cgiImprovementResult: ['', Validators.required],
  });
  public attachmentsFormGroup = this.formBuilder.group({
    moments: this.formBuilder.array([]),
  });

  // Step 2 & 3 + Validation Step
  public gadQuestionsKeys = Object.keys(GAD7);
  public gadPanicQuestionKey = Object.keys(GAD7_Panic);
  public phqQuestionsKeys = Object.keys(PHQ9);
  public gadScore = 0;
  public phqScore = 0;
  public phq9Active = new BehaviorSubject<boolean>(false);
  public gad7Active = new BehaviorSubject<boolean>(false);
  public shouldDisplayGadPanicQuestion = new BehaviorSubject<boolean>(false);
  public phqGadRequired = false;

  public get phq9ActiveAndValid(): boolean {
    return this.phq9Active.value && !this.phqFormGroup.get('PHQ9_3').value && !this.phqFormGroup.get('PHQ9_3').hasError('required') ||
           this.phq9Active.value && !this.phqFormGroup.get('PHQ9_4').value && !this.phqFormGroup.get('PHQ9_4').hasError('required') ||
           this.phq9Active.value && !this.phqFormGroup.get('PHQ9_5').value && !this.phqFormGroup.get('PHQ9_5').hasError('required') ||
           this.phq9Active.value && !this.phqFormGroup.get('PHQ9_6').value && !this.phqFormGroup.get('PHQ9_6').hasError('required') ||
           this.phq9Active.value && !this.phqFormGroup.get('PHQ9_7').value && !this.phqFormGroup.get('PHQ9_7').hasError('required') ||
           this.phq9Active.value && !this.phqFormGroup.get('PHQ9_8').value && !this.phqFormGroup.get('PHQ9_8').hasError('required') ||
           this.phq9Active.value && !this.phqFormGroup.get('PHQ9_9').value && !this.phqFormGroup.get('PHQ9_9').hasError('required');
  }
  public get gad7ActiveAndValid(): boolean {
    return this.gad7Active.value && !this.gadFormGroup.get('GAD7_3').value && !this.gadFormGroup.get('GAD7_3').hasError('required') ||
            this.gad7Active.value && !this.gadFormGroup.get('GAD7_4').value && !this.gadFormGroup.get('GAD7_4').hasError('required') ||
            this.gad7Active.value && !this.gadFormGroup.get('GAD7_5').value && !this.gadFormGroup.get('GAD7_5').hasError('required') ||
            this.gad7Active.value && !this.gadFormGroup.get('GAD7_6').value && !this.gadFormGroup.get('GAD7_6').hasError('required') ||
            this.gad7Active.value && !this.gadFormGroup.get('GAD7_7').value && !this.gadFormGroup.get('GAD7_7').hasError('required') ||
            this.gad7Active.value && !this.gadPanicFormGroup.get('GAD7_Panic').value;
  }

  // Saving
  public savingData = false;
  public errorSaving = false;
  public savingCompleted = false;
  public errorInformation$ = this.appointmentMomentService.errorInformation$;

  constructor(
    public appointmentMomentService: AppointmentMomentService,
    public momentService: MomentService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private timelineService: TimelineService,
    private patientProfileService: PatientProfileService
  ) {
    super();

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd), takeUntil(this.ngOnDestroy$))
      .subscribe((event: NavigationEnd) => {
        this.previousLocation = event.url.split('?')[0];
      });
  }

  async ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.patientId = params.patientId;
      this.id = params.id;

      if (this.id) {
        this.appointmentMomentService.isEditMode = true;
      }
    });
    this.appointmentMomentService.patientId$.next(this.patientId);

    // Set active step based on moment we are editing
    this.preloadStep = this.activatedRoute.snapshot.queryParams.step;

    try {
      if (this.appointmentMomentService.isEditMode) {
        this.appointment = await this.appointmentMomentService.getLatestMoment(this.id) as AppointmentMoment;
      } else {
        this.patient = await this.patientProfileService.getPatientInformation(this.patientId);
      }
    } catch (e) {
      console.error(e);
    }

    await this.formInit();

    // Do this last as it is not needed till last step
    try {
      this.patientChat = await this.patientProfileService.getPatientChatInformation(this.patientId);
    } catch (e) {
      console.error(e);
    }

    this.stepper.selectionChange.pipe(takeUntil(this.ngOnDestroy$)).subscribe(changeEvent => {
      if (changeEvent.selectedIndex === 4) {
        this.appointmentMomentService.fetchingAttachments$.next(true);
        this.appointmentMomentService.fetchAttachments();
      }
    });
  }

  public cancel(): void {
    const dialogRef = this.dialogService.openDialog(CancelDialogComponent);
    dialogRef.afterClosed().pipe(take(1)).subscribe(response => {
      if (response?.routeAway) {
        const returnUrl = this.previousLocation !== window.location.pathname ? this.previousLocation : `/therapist/assigned-patients/patient-profile/${this.patientId}`;
        this.router.navigate([ returnUrl ]);
      }
    });
  }

  public returnToTimeline(): void {
    this.router.navigate([ `/therapist/assigned-patients/patient-profile/${this.patientId}` ]);
  }

  public returnToChatSession(): void {
    const chatURL = this.patientChat.pubNub ? 'go-chat' : 'chat';
    this.router.navigate([ `/therapist/${chatURL}/${this.patientChat.chatRoomId}` ]);
  }

  public async save(): Promise<void> {
    this.errorSaving = false;
    this.savingData = true;

    const note = { text: this.noteFormGroup.get('note').value !== '' ? this.noteFormGroup.get('note').value : undefined };
    const cgiScore = this.cgiFormGroup.valid && this.cgiFormGroup.touched ? this.getCGIMomentData() : undefined;
    let riskAssessment = undefined;
    if (this.editReasonFormGroup.get('reason').value) {
      if (this.phqFormGroup.valid && this.phqFormGroup.touched || this.gadFormGroup.valid && this.gadFormGroup.touched || this.gadPanicFormGroup.valid && this.gadPanicFormGroup.touched) {
        riskAssessment = this.getRiskAssessmentMomentData();
      }
    } else if (this.phqFormGroup.valid && this.phqFormGroup.touched && this.gadFormGroup.valid && this.gadFormGroup.touched) {
      riskAssessment = this.getRiskAssessmentMomentData();
    }
    const appointment = this.getAppointmentMomentData();

    appointment.noteRef = this.appointment?.noteRef;
    appointment.cgiRef = this.appointment?.cgiRef;
    appointment.riskAssessmentRef = this.appointment?.riskAssessmentRef;

    // CREATE
    if (!this.id && !this.appointment) {
      await this.timelineService.createAppointmentMoment(this.patientId, {appointment, note, cgiScore, riskAssessment})
        .catch((error: HttpErrorResponse) => {
          console.error(error);
          this.errorSaving = true;
          switch(error.status) {
            case 404:
              this.appointmentMomentService.errorInformation$.next('Patient not found in database.');
              break;
            case 403:
              this.appointmentMomentService.errorInformation$.next('You do not have authorisation to access / update this patients record. This may be because you have discharged the patient recently.');
              break;
            case 400:
              this.appointmentMomentService.errorInformation$.next('Attachment belongs to another appointment, please update selection.');
              break;
            default:
              this.appointmentMomentService.errorInformation$.next('An error has occured on submitting the data, if you continue to encounter this error please contact support.');
              break;
          }
        })
        .finally(() => {
          if (!this.errorSaving) {
            this.savingCompleted = true;
          }
          this.savingData = false;
        });
    } else {

      // EDIT
      const dataToSave: TubEditAppointmentMomentRequestBody = {
        id: this.id,
        reason: this.editReasonFormGroup.get('reason').value,
      };

      if (this.appointmentFormGroup.touched && this.appointmentFormGroup.dirty) {
        appointment.appointmentOutcomeOther = appointment.appointmentOutcomeOther ?? null;
        dataToSave.appointment = appointment;
      } else if (this.attachmentsFormGroup.touched && this.attachmentsFormGroup.dirty) {
        dataToSave.appointment = {
          attachmentsRef: this.attachmentsFormGroup.get('moments').value as string[]
        } as TubAppointmentMoment;
      }
      if (this.noteFormGroup.touched && this.noteFormGroup.dirty) {
        dataToSave.note = note;
      }
      if (this.cgiFormGroup.touched && this.cgiFormGroup.dirty) {
        dataToSave.cgiScore = cgiScore;
      }
      if (this.gadFormGroup.touched && this.gadFormGroup.dirty || this.gadPanicFormGroup.touched && this.gadPanicFormGroup.dirty || this.phqFormGroup.touched && this.phqFormGroup.dirty) {
        dataToSave.riskAssessment = riskAssessment;
      }

      await this.timelineService.updateAppointmentMoment(this.patientId, dataToSave)
        .catch((error: HttpErrorResponse) => {
          console.error(error);
          this.errorSaving = true;
          switch(error.status) {
            case 404:
              this.appointmentMomentService.errorInformation$.next('Patient not found in database.');
              break;
            case 403:
              this.appointmentMomentService.errorInformation$.next('You do not have authorisation to access / update this patients record. This may be because you have discharged the patient recently.');
              break;
            case 400:
              this.appointmentMomentService.errorInformation$.next('Attachment belongs to another appointment, please update selection.');
              break;
            default:
              this.appointmentMomentService.errorInformation$.next('An error has occured on submitting the data, if you continue to encounter this error please contact support.');
              break;
          }
        })
        .finally(() => {
          if (!this.errorSaving) {
            this.savingCompleted = true;
          }
          this.savingData = false;
        });
    }
  }

  public markGadPhqAsNotRequired(): void {
    //  Don't emit event - as GAD relies on PHQ - forms mark each another as dirty when value changes
    this.phqFormGroup.reset({}, {emitEvent: false});
    this.phqFormGroup.setErrors(null);
    this.phqScore = 0;
    this.phq9Active.next(false);

    this.gadFormGroup.reset({}, {emitEvent: false});
    this.gadFormGroup.setErrors(null);
    this.gadScore = 0;
    this.gad7Active.next(false);
    this.gadPanicFormGroup.reset({}, {emitEvent: false});
    this.gadPanicFormGroup.setErrors(null);
  }

  private async formInit(): Promise<void> {
    if (this.appointmentMomentService.isEditMode) {
      this.appointmentFormGroup.patchValue({
        appointmentDate: new Date(this.appointment.appointmentDate),
        appointmentTime: new Date(this.appointment.appointmentTime),
        appointmentAttendanceStatus: this.appointment.appointmentOutcome,
        appointmentAttendanceStatusOther: this.appointment?.appointmentOutcomeOther,
        appointmentSessionNumber: `${this.appointment.therapySessionNumber}`,
        functionResult: this.appointment.howPatientIsFunctioning
      });

      if (this.appointment.appointmentOutcome === AttendanceStates.therapistCancelled ||
        this.appointment.appointmentOutcome === AttendanceStates.patientCancelled ||
        this.appointment.appointmentOutcome === AttendanceStates.rescheduled
      ) {
        // Clear all validation as session never took place
        this.sessionCancelled = true;

        this.noteFormGroup.controls['note'].clearValidators();
        this.noteFormGroup.controls['note'].setErrors(null);
        this.noteFormGroup.controls['note'].updateValueAndValidity();
        this.appointmentFormGroup.controls['functionResult'].clearValidators();
        this.appointmentFormGroup.controls['functionResult'].setErrors(null);

        this.phqFormGroup.clearValidators();
        this.gadFormGroup.clearValidators();
        this.cgiFormGroup.clearValidators();
      }

      if (this.appointment?.noteRef) {
        const note = await this.appointmentMomentService.getLatestMoment(this.appointment.noteRef) as NoteMoment;
        this.noteFormGroup.controls['note'].patchValue(note.note);
      }

      if (this.appointment?.riskAssessmentRef) {
        this.phqGadRequired = true;
        const riskAssessment = await this.appointmentMomentService.getLatestMoment(this.appointment.riskAssessmentRef) as RiskAssessmentEventMoment;
        // PHQ
        this.phqScore = riskAssessment.PHQScore;
        this.phq9Active.next(this.phqScore >= 3);
        for (const control of Object.keys(this.phqFormGroup.controls)) {
          this.addValidators(control, this.phqFormGroup, Validators.required);
        }

        for (const questionAnswer of riskAssessment.PHQQuestions) {
          this.phqFormGroup.get(questionAnswer.questionIndex).patchValue(questionAnswer.answerIndex);
          this.phqFormGroup.get(questionAnswer.questionIndex).setErrors(null);
        }

        this.phqFormGroup.updateValueAndValidity();
        this.phqFormGroup.markAsDirty();

        // GAD
        this.gadScore = riskAssessment.GADScore;
        this.gad7Active.next(this.gadScore >= 3);
        this.shouldDisplayGadPanicQuestion.next(this.gadScore >= this.GAD_SCORE_THRESHOLD);
        for (const control of Object.keys(this.gadFormGroup.controls)) {
          this.addValidators(control, this.gadFormGroup, Validators.required);
        }
        // Add GAD7_Panic Validator
        this.addValidators('GAD7_Panic', this.gadPanicFormGroup, Validators.required);

        const panicResults = riskAssessment.GADQuestions.filter(question => question.questionIndex === 'GAD7_Panic')[0];
        const gad7Results = riskAssessment.GADQuestions.filter(question => question.questionIndex !== 'GAD7_Panic');

        for (const questionAnswer of gad7Results) {
          this.gadFormGroup.get(questionAnswer.questionIndex).patchValue(questionAnswer.answerIndex);
          this.gadFormGroup.get(questionAnswer.questionIndex).setErrors(null);
        }

        this.gadFormGroup.updateValueAndValidity();
        this.gadFormGroup.markAsDirty();

        if (panicResults) {
          this.gadPanicFormGroup.get(panicResults.questionIndex).patchValue(panicResults.answerIndex);
          this.gadPanicFormGroup.get(panicResults.questionIndex).setErrors(null);
          this.gadPanicFormGroup.updateValueAndValidity();
          this.gadPanicFormGroup.markAsDirty();
        }
      }

      if (this.appointment?.cgiRef) {
        const cgiScore = await this.appointmentMomentService.getLatestMoment(this.appointment.cgiRef) as CgiScoreMoment;
        this.cgiFormGroup.patchValue({
          cgiImprovementResult: `${cgiScore.cgiImprovement}`,
          cgiSeverityResult: `${cgiScore.cgiSeverity}`
        });
        this.cgiFormGroup.markAsDirty();
        this.cgiFormGroup.updateValueAndValidity();
      }

      if (this.appointment?.attachmentsRef && this.appointment?.attachmentsRef.length > 0) {
        const attachments = [];
        const formArray = this.attachmentsFormGroup.get('moments') as FormArray;

        for (const attachmentId of this.appointment.attachmentsRef) {
          const file = await this.timelineService.getMomentById(this.patientId, attachmentId);
          if (file) {
            (file as CheckableMoment).checked = true;
            file.id = attachmentId;
            attachments.push(file);
            formArray.push(this.formBuilder.control(attachmentId));
          }
        }

        this.appointmentMomentService.attachmentsCheckedList$.next(attachments);
      }

    } else {
      this.editReasonFormGroup.controls['reason'].clearValidators();
      this.editReasonFormGroup.controls['reason'].setErrors(null);

      this.appointmentFormGroup.get('appointmentTime').patchValue(new Date());
    }

    this.addFormValidation();
    this.appointmentMomentService.fetchAttachments();
  }

  private getCGIMomentData(): TubCgiCreationRequestBody {
    return {
      CGIImprovement: Number(this.cgiFormGroup.get('cgiImprovementResult').value),
      CGISeverity: Number(this.cgiFormGroup.get('cgiSeverityResult').value)
    } as TubCgiCreationRequestBody;
  }

  private getRiskAssessmentMomentData(): TubPatientRiskAssessment {
    // Slice screening questions and data to save if form not active
    let gadQuestions = this.gadQuestionsKeys;
    let phqQuestions = this.phqQuestionsKeys;
    let gadResults = Object.values(this.gadFormGroup.value).map(v => Number(v));
    let phqResults = Object.values(this.phqFormGroup.value).map(v => Number(v));

    // Add GAD7_Panic results if answered
    if (this.gadPanicFormGroup.get('GAD7_Panic').value !== null) {
      gadQuestions.push(...this.gadPanicQuestionKey);
      gadResults.push(...Object.values(this.gadPanicFormGroup.value).map(v => Number(v)));
    }
    if (!this.gad7Active.value) {
      gadQuestions = gadQuestions.slice(0, 2);
      gadResults = gadResults.slice(0, 2);
    }
    if (!this.phq9Active.value) {
      phqQuestions = phqQuestions.slice(0, 2);
      phqResults = phqResults.slice(0, 2);
    }
    // Reverse results sent also reverse order. TUB handles things backwards to support unity
    return {
      QuestionsAsked: [...gadQuestions, ...phqQuestions] ,
      QuestionResults: [...phqResults.reverse(), ...gadResults.reverse()]
    } as TubPatientRiskAssessment;
  }

  private getAppointmentMomentData(): TubAppointmentMoment {
    const dateTime = this.appointmentFormGroup.get('appointmentDate').value;
    const timeValue = this.appointmentFormGroup.get('appointmentTime').value;
    dateTime.setHours(timeValue.getHours(), timeValue.getMinutes());

    const appointmentData = {
      appointmentDateTime: Date.parse(dateTime.toString()),
      appointmentOutcome: this.appointmentFormGroup.get('appointmentAttendanceStatus').value,
      therapySessionNumber: Number(this.appointmentFormGroup.get('appointmentSessionNumber').value),
      howPatientIsFunctioning: this.appointmentFormGroup.get('functionResult').value,
      attachmentsRef: this.attachmentsFormGroup.get('moments').value as string[]
    } as TubAppointmentMoment;

    if (this.appointmentFormGroup.get('appointmentAttendanceStatusOther').value) {
      appointmentData.appointmentOutcomeOther = this.appointmentFormGroup.get('appointmentAttendanceStatusOther').value;
    }

    return appointmentData;
  }

  private isInvalidTimeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.appointmentFormGroup) {
        return null;
      }

      if (this.appointmentFormGroup?.get('appointmentDate')?.value?.getDate() === new Date().getDate()) {
        const currentValue = new Date(this.appointmentFormGroup?.get('appointmentDate')?.value);

        if (control.value) {
          const hh = new Date(control.value).getHours();
          const mm = new Date(control.value).getMinutes();

          currentValue.setHours(hh);
          currentValue.setMinutes(mm);

          // Make time flexible - give or take a minute
          const toleranceMillis = 60000;

            // number of milliseconds tolerance (i.e. 60000 == one minute)
            if (currentValue.getTime() > Date.now() + toleranceMillis) {
              return { invalidTimeFuture: true };
            }
        }
      }

      return null;
    };
  }

  private addValidators(controlName: string, formGroup: FormGroup, validators: ValidatorFn): void {
    formGroup.get(controlName).addValidators(validators);
  }

  // Generic method for updating PHQ / GAD
  private updateFormValidators(add: boolean, formGroup: FormGroup, excludeControlNameOne: string, excludeControlNameTwo: string): void {
    if (add) {
      // add validators
      for (const control of Object.keys(formGroup.controls)) {
        if (control !== excludeControlNameOne && control !== excludeControlNameTwo) {
          this.addValidators(control, formGroup, Validators.required);

          // Doesn't recognise 0 as value
          if (!formGroup.get(control).value && formGroup.get(control).value !== 0) {
            formGroup.get(control).setErrors({'required': true});
          }
        }
      }
    } else {
      // remove validators
      for (const control of Object.keys(formGroup.controls)) {
        if (control !== excludeControlNameOne && control !== excludeControlNameTwo) {
          formGroup.get(control).reset();
          formGroup.get(control).setErrors(null);
        }
      }

      if (excludeControlNameOne && excludeControlNameTwo) {
        this.addValidators(excludeControlNameOne, formGroup, Validators.required);
        this.addValidators(excludeControlNameTwo, formGroup, Validators.required);
      }
    }

  }

  private addFormValidation(): void {
    // FORM VALIDATION SUBS
    this.appointmentFormGroup.get('appointmentAttendanceStatus').valueChanges.pipe(takeUntil(this.ngOnDestroy$)).subscribe(status => {
      if (status === AttendanceStates.therapistCancelled ||
          status === AttendanceStates.patientCancelled ||
          status === AttendanceStates.rescheduled
        ) {

        // Clear all validation as session never took place
        this.sessionCancelled = true;

        if (this.noteFormGroup.controls['note'].value === '') {
          this.noteFormGroup.controls['note'].clearValidators();
          this.noteFormGroup.controls['note'].setErrors(null);
          this.noteFormGroup.controls['note'].updateValueAndValidity();
        }
        this.appointmentFormGroup.controls['functionResult'].clearValidators();
        this.appointmentFormGroup.controls['functionResult'].setErrors(null);

        this.phqFormGroup.clearValidators();
        this.gadFormGroup.clearValidators();
        this.gadPanicFormGroup.clearValidators();
        this.cgiFormGroup.clearValidators();
      }

      if (this.sessionCancelled && status !== AttendanceStates.therapistCancelled &&
          this.sessionCancelled && status !== AttendanceStates.patientCancelled &&
          this.sessionCancelled && status !== AttendanceStates.rescheduled
        ) {

          // Re-add validators
          this.sessionCancelled = false;

          if (!this.noteFormGroup.controls['note'].hasValidator(Validators.required)) {
            this.noteFormGroup.get('note').addValidators([Validators.required, StringEntryControlValidator.isInvalidStringEntryValidator()]);
            this.noteFormGroup.get('note').setErrors({'required': true});
            this.noteFormGroup.get('note').updateValueAndValidity();
          }
          this.appointmentFormGroup.get('functionResult').addValidators(Validators.required);
          this.appointmentFormGroup.get('functionResult').setErrors({'required': true});
          this.appointmentFormGroup.get('functionResult').updateValueAndValidity();

          this.cgiFormGroup.addValidators(Validators.required);
          this.cgiFormGroup.setErrors({'required': true});
          this.cgiFormGroup.updateValueAndValidity();
        }

      if (status === AttendanceStates.other) {
        this.appointmentFormGroup.get('appointmentAttendanceStatusOther').patchValue('');
        this.appointmentFormGroup.get('appointmentAttendanceStatusOther').addValidators([Validators.required, StringEntryControlValidator.isInvalidStringEntryValidator()]);
        this.appointmentFormGroup.get('appointmentAttendanceStatusOther').setErrors({'required': true});
        this.appointmentFormGroup.get('appointmentAttendanceStatusOther').updateValueAndValidity();
      } else {
        this.appointmentFormGroup.get('appointmentAttendanceStatusOther').reset('');
        this.appointmentFormGroup.get('appointmentAttendanceStatusOther').setErrors(null);
        this.appointmentFormGroup.get('appointmentAttendanceStatusOther').clearValidators();
        this.appointmentFormGroup.get('appointmentAttendanceStatusOther').updateValueAndValidity();
      }
    });

    this.appointmentFormGroup.get('appointmentDate').valueChanges.pipe(takeUntil(this.ngOnDestroy$)).subscribe(date => {
      const appointmentTimeControl = this.appointmentFormGroup?.get('appointmentTime');

      const now = new Date();
      now.setHours(0,0,0,0);
      const currentDate = new Date(date);

      if (currentDate.toDateString() === now.toDateString()) {
        const currentDateTime = currentDate;
        const currentTime = appointmentTimeControl.value.getTime();
        currentDateTime.setTime(currentTime);

        if (new Date() < currentDateTime) {
          appointmentTimeControl.setErrors({ invalidTimeFuture: true });
        }
      } else if (appointmentTimeControl.hasError('invalidTimeFuture')) {
        // clear errors if date has been changed to a previous date
        appointmentTimeControl.setErrors(null);
      }
    });

    this.noteFormGroup.get('note').valueChanges.pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.ngOnDestroy$)).subscribe(note => {
      if (note !== '') {
        this.noteFormGroup.get('note').addValidators([Validators.required, StringEntryControlValidator.isInvalidStringEntryValidator()]);
        if (note.length < 3) {
          this.noteFormGroup.get('note').setErrors({'lengthError': true });
        }
        this.noteFormGroup.get('note').updateValueAndValidity();
      } else if (note === '' && this.sessionCancelled) {
        this.noteFormGroup.get('note').clearValidators();
        this.noteFormGroup.get('note').setErrors(null);
        this.noteFormGroup.get('note').updateValueAndValidity();
      }
    });

    this.phqFormGroup.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.ngOnDestroy$)).subscribe(answers => {
      this.phq9Active.next(this.appointmentMomentService.calcGadPhqScreeningScore(Object.values(answers)) >= 3);
      this.phqScore = this.appointmentMomentService.calcGadPhqScore(Object.values(answers));

      if (this.phqFormGroup.touched && this.gadFormGroup.pristine) {
        this.gadFormGroup.markAsDirty();
      }

      if (!this.gadFormGroup.controls['GAD7_1'].hasValidator(Validators.required)) {
        for (const control of Object.keys(this.gadFormGroup.controls)) {
          this.addValidators(control, this.gadFormGroup, Validators.required);
        }
      }
    });

    this.phq9Active.pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.ngOnDestroy$)).subscribe(isActive => {
      if (this.phq9ActiveAndValid) {
        this.updateFormValidators(true, this.phqFormGroup, 'PHQ9_1', 'PHQ9_2');
      } else if (!isActive && this.phqFormGroup.touched) {
        this.phqFormGroup.clearValidators();
        this.updateFormValidators(false, this.phqFormGroup, 'PHQ9_1', 'PHQ9_2');

        if (this.appointmentMomentService.isEditMode && this.phqFormGroup.get('PHQ9_1').value !== null) {
          this.phqFormGroup.markAsDirty();
        }
      }
    });

    this.gadFormGroup.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.ngOnDestroy$)).subscribe(answers => {
      this.gad7Active.next(this.appointmentMomentService.calcGadPhqScreeningScore(Object.values(answers)) >= 3);
      this.gadScore = this.appointmentMomentService.calcGadPhqScore(Object.values(answers));
      this.shouldDisplayGadPanicQuestion.next(this.gadScore >= this.GAD_SCORE_THRESHOLD);

      if (!this.shouldDisplayGadPanicQuestion.value) {
        this.gadPanicFormGroup.reset();
      }
      if (this.gadFormGroup.touched && this.phqFormGroup.pristine) {
        this.phqFormGroup.markAsDirty();
      }

      if (!this.phqFormGroup.controls['PHQ9_1'].hasValidator(Validators.required)) {
        for (const control of Object.keys(this.phqFormGroup.controls)) {
          this.addValidators(control, this.phqFormGroup, Validators.required);
        }
      }
    });

    this.gad7Active.pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.ngOnDestroy$)).subscribe(isActive => {
      if (this.gad7ActiveAndValid) {
        this.updateFormValidators(true, this.gadFormGroup, 'GAD7_1', 'GAD7_2');

        // Update GAD7_Panic Validator
        this.updateFormValidators(true, this.gadPanicFormGroup, '', '');
      } else if (!isActive && this.gadFormGroup.touched) {
        this.gadFormGroup.clearValidators();
        this.updateFormValidators(false, this.gadFormGroup, 'GAD7_1', 'GAD7_2');

        // Update GAD7_Panic Validator
        this.updateFormValidators(false, this.gadPanicFormGroup, '', '');

        if (this.appointmentMomentService.isEditMode && this.gadFormGroup.get('GAD7_1').value !== null) {
          this.gadFormGroup.markAsDirty();
        }
      }
    });
  }
}
