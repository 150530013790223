import { Injectable } from '@angular/core';

import { BehaviorSubject, firstValueFrom, groupBy, Subject } from 'rxjs';
import { filter, mergeMap, take, throttleTime } from 'rxjs/operators';

/* Protobuf imports */
/* Helpers */
import {
  ChatMessageReply,
  CommandChatBegin,
  CommandChatDeleteMessage,
  CommandChatDischarge,
  CommandChatEditMessage,
  CommandChatLeave,
  CommandChatMessages,
  CommandChatSendMessage,
  CommandChatSetLastRead,
  CommandChatTherapistInvite,
  CommandChatTypingOn,
  CommandGetChannel,
  GoCoreMediator,
  Message,
  PropertyCache,
  PubNubChannels,
  PubNubChatStatus,
  PubNubLastRead,
  PubNubMessages,
  PubNubTyping,
  Response,
  ResponseError,
} from '@thrivesoft/gocore-web';

/* Services */
import { Completer } from '../utils/completer';
import { MessageService } from '../../message.service';
import { TherapistChatService } from '@backend-client/services/therapist-chat.service';
import { GoCoreObserverRepository } from '@shared/services/gocore/gocore-observer-repository';
import { ObserverRepository } from '@shared/services/gocore/observer-repository';
import { TubErrorReportingService } from '@shared/services/tub-error-reporting.service';

/* Models */
import {
  ChatChannelModel,
  ChatLastReadModel,
  ChatMessageModel,
  ChatReplyModel,
  ChatUserModel,
} from './model';
import { CommandName, GoErrorData } from '../model';
import { TubChatUserPresenceInfo } from '@backend-client/models/tub-chat-user-presence-info';

/* Decorators */
import { ExecuteOnUpdate } from '@shared/services/gocore/chat/decorators/execute-on-update.decorator';
import { ExecuteIfInitialised } from '@shared/services/gocore/chat/decorators/execute-if-initialised.decorator';
import { GoChatCacheService } from '@app/modules/therapist/go-chat/go-chat-cache.service';

/* Values that indicate ready and online */
const READY = BigInt(2);
const CHATSLOADED = BigInt(16384);
const PENDINGCACHE = BigInt(32768);

@Injectable({
  providedIn: 'root',
})
export class GoCoreChatService {
  private goCoreMediator: GoCoreMediator;
  private observerRepository: ObserverRepository;
  // Observables
  // allSentMessages$ only contains messages which exist in the database
  public readonly allSentMessages$: BehaviorSubject<ChatMessageModel[]> = new BehaviorSubject<ChatMessageModel[]>([]);
  // messages$ is the combined messages of sent and pending
  public readonly messages$: BehaviorSubject<ChatMessageModel[]> = new BehaviorSubject<ChatMessageModel[]>([]);
  // pendingMessages$ messages the therapist has sent
  public readonly pendingMessages$: BehaviorSubject<ChatMessageModel[]> = new BehaviorSubject<ChatMessageModel[]>([]);
  public readonly chatChannels$: Subject<ChatChannelModel[]> = new Subject<ChatChannelModel[]>();
  public readonly typingIndicator$ = new BehaviorSubject<ChatUserModel[]>([]);
  public readonly selectedChatChannel$ = new BehaviorSubject<string>('');
  public readonly therapistsInSelectedChat$ = new BehaviorSubject<TubChatUserPresenceInfo[]>([]);
  public readonly arePendingMessages$ = new BehaviorSubject<boolean>(false);
  /**
   * @DEPRECATED - Accepting chat requests now handled directly through TUB
   */
  public readonly lastAcceptedRequest$ = new BehaviorSubject<string>('');
  public readonly goCoreErrorDisconnected$ = new BehaviorSubject<boolean>(false);
  public readonly errorSettingActiveChannel$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly errorFailedToLoadChatHistory$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading$ = new BehaviorSubject<boolean>(true);
  private readonly error$: Subject<GoErrorData> = new Subject<GoErrorData>();

  // Loading Status
  public readonly chatLoadState$ = new BehaviorSubject<CommandName>(null);
  public initialised$ = new BehaviorSubject<boolean>(false);

  // Status
  public readonly therapistLeaveFailed$: Subject<string> = new Subject<string>();
  public readonly therapistLeftSuccessfully$: Subject<void> = new Subject<void>();
  public readonly dischargeSuccessful$: Subject<void> = new Subject<void>();
  public readonly dischargeFailed$: Subject<string> = new Subject<string>();

  public loadingMessages$ = new BehaviorSubject<boolean>(false);

  /* Chat Client State */
  public typingNow: ChatUserModel[] = [];
  public opponentsActivity: Map<string, ChatLastReadModel[]> = new Map<string, ChatLastReadModel[]>();
  public channelUsers: TubChatUserPresenceInfo[] = [];
  public channelToActivate: string;

  constructor(
    private therapistChatService: TherapistChatService,
    private messageService: MessageService,
    private tubErrorReportingService: TubErrorReportingService,
    private goChatCacheService: GoChatCacheService,
  ) {
    this.setupErrorReporting();
  }

  /**
   * Initialises all the observers to handle all the relevant chat commands that come out of GoCore
   */
  private initObservers(): void {
    this.observerRepository.observeProperty(GoCoreObserverRepository.opCache, this.writeToChatCache.bind(this));
    this.observerRepository.observeProperty(
      GoCoreObserverRepository.opChatChannelsProperty,
      this.updateGoCoreChannels.bind(this),
    );
    this.observerRepository.observeProperty(
      GoCoreObserverRepository.opChatTypingProperty,
      this.setTypingIndicatorForOthers.bind(this),
    );
    this.observerRepository.observeProperty(
      GoCoreObserverRepository.opChatMessagesProperty,
      this.setRetrievedMessages.bind(this),
    );
    this.observerRepository.observeProperty(
      GoCoreObserverRepository.opChatStatusProperty,
      this.setGoChatInitialisationStatus.bind(this),
    );
    this.observerRepository.observeProperty(
      GoCoreObserverRepository.opChatLastReadProperty,
      this.setLastReadResponse.bind(this),
    );
    this.observerRepository.observeProperty(
      GoCoreObserverRepository.opChatErrorProperty,
      this.onChatErrorObserverResponse.bind(this),
    );
  }

  /**
   * Handles the response from the chat channels observer.
   * @param response The response from GoCore`
   *
   *         name: string,
   *         time: bigint,
   *         messageCount: number,  // unreadMessageCount
   *         participants: Map<string, PubNubChannelUser>,
   *         activeParticipants: PubNubChannelUser[]
   *
   */
  // TODO: Skip the first few emissions as they are not "complete"
  @ExecuteOnUpdate()
  private updateGoCoreChannels(response: Response): void {
    const channels = PubNubChannels.fromBinary(response.body).channels;
    const convertedChannels = ChatChannelModel.convertToChannelList(channels);
    this.chatChannels$.next(convertedChannels);
  }

  /**
   * Retrieve messages from the channel
   * @param response
   * @private
   */

  @ExecuteOnUpdate()
  @ExecuteIfInitialised()
  private setRetrievedMessages(response: Response): void {
    const decoded: PubNubMessages = PubNubMessages.fromBinary(response.body);
    const messages = ChatMessageModel.messages(decoded.messages);

    const patientUserId = this.getPatientUserId();

    const patientMessages = messages.filter(m => m.uuid === patientUserId);
    const lastPatientMsg = patientMessages[patientMessages.length - 1];

    const localMessages = [];
    let isPendingMessages = false;
    let pendingActionForSentMessage = false;

    messages.forEach(message => {
      // If we receive a message with no meta object, try and recover by creating one.
      if (!message?.meta) {
        message['meta'] = {};
      }

      message = this.processMeta(message, lastPatientMsg, messages, patientUserId);

      // Handle deleted or edited messages then return
      if (message.deleted || message.edited) {
        const match = this.messages$.value.filter((msg: ChatMessageModel) => msg.timeId === message.timeId)[0];

        if (match) {
          const indexofMatch = this.messages$.value.indexOf(match);
          this.messages$.value[indexofMatch] = message;
          pendingActionForSentMessage = true;
          return;
        }
      }

      // Handle pending messages
      if (message.isPending) {
        isPendingMessages = true;
        const match = this.messages$.value.filter((msg: ChatMessageModel) => msg.timeId === message.timeId)[0];

        if (match) {
          const indexofMatch = this.messages$.value.indexOf(match);
          this.messages$[indexofMatch] = message;
          pendingActionForSentMessage = true;
        }
      }

      // Remove sent messages from pending messages array
      if (this.pendingMessages$.value.length > 0) {
        const match = this.pendingMessages$.value.filter((pending: ChatMessageModel) => pending.timeId === message.timeId)[0];

        if (match) {
          const indexofMatch = this.pendingMessages$.value.indexOf(match);
          this.pendingMessages$.next(this.pendingMessages$.value.slice(0, indexofMatch));
        }
      }

      // Handle new messages
      localMessages.push(message);
    });

    if (!pendingActionForSentMessage) {
      // Shows all sent and pending messages
      this.messages$.next([...this.allSentMessages$.value, ...localMessages]);
      this.messages$.value.sort((a, b) => (a.timeId < b.timeId ? -1 : a.timeId > b.timeId ? 1 : 0));

      // Store states for next trigger event
      if (isPendingMessages) {
        this.pendingMessages$.next([...this.pendingMessages$.value, ...localMessages]);
      } else {
        this.allSentMessages$.next([...this.allSentMessages$.value, ...localMessages]);
        this.allSentMessages$.value.sort((a, b) => (a.timeId < b.timeId ? -1 : a.timeId > b.timeId ? 1 : 0));
      }
    }

    this.loading$.next(false);
    // Clears the loading more bar
    this.loadingMessages$.next(false);
  }

  /**
   * CACHE
   */

  /**
   * When sending a chat message to GoCore, GoCore will emit a base64 encoded string via an observer that triggers this function.
   * We write that string to local cache which enables us to recover and send any pending messages if the network dies.
   * Once a message has been successfully sent, the observer triggering this function will once again emit, but with the sent data omitted.
   * @param response
   * @private
   */
  @ExecuteOnUpdate()
  private writeToChatCache(response: Response): void {
    const chatCache: PropertyCache = PropertyCache.fromBinary(response.body);
    this.goChatCacheService.writeToChatCache(chatCache);
  }

  /**
   * TYPING
   **/

  /**
   * Sets the "currently typing" state for the other users in the current chat
   * @param response The response from GoCore
   */
  @ExecuteOnUpdate()
  @ExecuteIfInitialised()
  private setTypingIndicatorForOthers(response: Response): void {
    const typingData: PubNubTyping = PubNubTyping.fromBinary(response.body);
    this.typingNow = typingData.typingNow.map((uuid: string) => {
      return {
        uuid,
        role: this.getMessageOwner(uuid),
      };
    });

    const otherUsersCurrentlyTyping = this.typingNow.filter((user: ChatUserModel) => {
      return user.uuid !== this.getTherapistId();
    });

    this.typingIndicator$.next(otherUsersCurrentlyTyping);
  }

  /**
   * Clears typing indicator for users
   * @param therapistOnly Parameter to specify only the therapist's typing indicator should be cleared
   * @param channelId The channel to clear the typing indicators from
   */
  private clearTypingIndicators(channelId: string, therapistOnly = false) {
    if (therapistOnly) {
      const therapistTyping = this.typingNow.filter(u => u.uuid === this.getTherapistId());

      if (therapistTyping.length > 0) {
        this.typingNow = this.typingNow.filter(u => u.uuid !== this.getTherapistId());
      }
    } else {
      this.typingIndicator$.next([]);
    }
  }

  /**
   * Informs GoCore when the therapist is currently typing
   *  @deprecated Typing indicator set directly by TUB now
   */
  public setTypingIndicatorForTherapist(channelId: string) {
    if (!this.typingNow.find(u => u.uuid === this.getTherapistId())) {
      this.typingNow.push({ uuid: this.getTherapistId(), role: 'You' });
    }
    this.command(new CommandChatTypingOn({ channel: channelId }) as Message);
  }

  private areChatsLoaded$ = new BehaviorSubject<boolean>(false);

  /**
   * Set the initialisation status to true once the chat service becomes ready and online.
   * @param response The response from GoCore
   */
  @ExecuteOnUpdate()
  private setGoChatInitialisationStatus(response: Response): void {
    // Chat cache
    const chatStatus: PubNubChatStatus = PubNubChatStatus.fromBinary(response.body);
    const arePendingMessages: boolean = this.checkBinary(chatStatus.state, PENDINGCACHE);
    this.arePendingMessages$.next(arePendingMessages);
    if (this.checkBinary(chatStatus.state, READY)) {
      this.initialised$.next(true);
    }

    if (this.checkBinary(chatStatus.state, CHATSLOADED)) {
      // TODO: Populate the spinner at the top
      this.areChatsLoaded$.next(true);
    }
  }

  @ExecuteOnUpdate()
  @ExecuteIfInitialised()
  private setLastReadResponse(response: Response): void {
    const lastReadResponse: PubNubLastRead = PubNubLastRead.fromBinary(response.body);

    if (lastReadResponse.userID !== this.getTherapistId()) {
      let channelResponses = this.opponentsActivity?.get(lastReadResponse.channel) || [];

      if (channelResponses?.length > 0) {
        channelResponses = channelResponses.filter(a => a.userID !== lastReadResponse.userID);
      }

      channelResponses.push(lastReadResponse);
      this.opponentsActivity.set(lastReadResponse.channel, channelResponses);
    }
  }

  @ExecuteOnUpdate()
  @ExecuteIfInitialised()
  private onChatErrorObserverResponse(response: Response): void {
    // If there is no properly constructed message from GoCore, then ignore it and return - there's nothing we can do.
    if (!response?.error?.Message) {
      return;
    }

    if (response.error.Message.includes('NOT_IN_CHAT_WITH_PATIENT')) {
      this.messageService.showMessage(
        'Either you have left the chat, or access to this chat has been revoked by an administrator.',
      );
      return;
    }

    if (response.error.Message.includes('START_CHAT_MOMENT_EXISTS_BUT_NOT_RTD_CHAT_ROOM')) {
      this.messageService.showMessage(
        'Failed to load chat history, found chat start moment but no data exists in the realtime database.',
      );
      return;
    }

    if (response.error.Message === CommandName.CHAT_SUMMARY_NOT_FOUND) {
      console.error(`Chat summary details failed to return ${this.selectedChatChannel$.value}`);
      return;
    }

    this.messageService.showMessage(`Error: An unknown error occurred`);
  }

  /**
   * HANDLE FOCUS
   */

  public restoreChatFocus(): void {
    // TODO Currently busted
    // this.command(new CommandChatRestoreFocus() as Message);
  }

  public lostChatFocus(): void {
    // TODO: Currently busted
    // this.command(new CommandChatLostFocus() as Message);
  }

  /**
   * Verifies if a state is active in GoCore
   * @param state Current GoCore state
   * @param checkValue The value to verify is present
   */
  private checkBinary(state: bigint, checkValue: bigint): boolean {
    return (state & checkValue) !== BigInt(0);
  }

  public async initialise(goCore: GoCoreMediator, observerRepository: ObserverRepository): Promise<void> {
    this.goCoreMediator = goCore;
    this.observerRepository = observerRepository;
    this.initObservers();
    await this.initChat();
  }

  public therapistIdFromPubNub: string = null;

  public async initChat(): Promise<void> {
    try {
      const response: Message = await this.command(new CommandChatBegin() as Message);
      // @ts-expect-error .body is not a defined property, but _is_ accessible
      const decodedResponse = CommandChatBegin.fromBinary(response.body);
      this.therapistIdFromPubNub = decodedResponse.chatResponse.chatUser.id;
    } catch (e) {
      console.log('[ERR] ::', e);
    }
  }

  public async sendChat(
    channelId: string,
    text: string,
    replyId?: bigint,
    replyText?: string,
    replyAuthor?: string,
  ): Promise<void> {
    this.clearTypingIndicators(channelId, true);
    const msg: CommandChatSendMessage = new CommandChatSendMessage({
      message: text,
      channel: channelId,
    });

    if (replyId) {
      msg.reply = new ChatMessageReply({
        messageId: replyId.toString(),
        messageText: replyText,
        messageAuthorId: replyAuthor,
      });
    }
    const response: Response = (await this.command(msg as Message)) as Response;
    if (response?.error?.Message === 'context deadline exceeded') {
      this.messageService.showMessageAndReload(
        'Error: One or more messages could not be sent. Please reload the page and try again.',
        true,
      );
    }
  }

  public async editChat(channelId: string, id: bigint, text: string): Promise<void> {
    await this.command(
      new CommandChatEditMessage({
        messageId: id.toString(),
        messageText: text,
        channel: channelId,
      }) as Message,
    );
  }

  public async deleteChat(channelId: string, id: bigint): Promise<void> {
    await this.command(
      new CommandChatDeleteMessage({
        messageId: id.toString(),
        channel: channelId,
      }) as Message,
    );
  }

  /**
   * Group errors by CommandName, and report each stream of errors with an individual rate limit.
   * This will prevent the reporting endpoint from being spammed, while still capturing different errors that may occur.
   */
  private setupErrorReporting(): void {
    // One minute
    const RATE_LIMIT = 60 * 1000;

    this.error$
      .pipe(
        groupBy(err => err.commandName),
        mergeMap(group => group.pipe(throttleTime(RATE_LIMIT))),
      )
      .subscribe(err => this.reportError(err));
  }

  private reportError(err: GoErrorData): void {
    err.data.reporter = 'DASHBOARD_REPORTER';
    this.tubErrorReportingService.send(err.commandName, null, err.data);
  }

  public retryLoadSession(channelId: string) {
    setTimeout(() => {
      switch (this.chatLoadState$.value) {
        case null:
          this.selectChannel(this.selectedChatChannel$.value);
          break;
        case CommandName.SET_ACTIVE_CHANNEL:
          this.messageService.showMessage('Retrying to fetch messages... please wait.');
          this.loadMoreMessages(channelId);
          break;
      }
    }, 2000);
  }

  // REVIEW: This code may be obsolete
  private subscribeOnlineStatus(): void {
    if (!this.messageService.isOnline$.value) {
      const sub = this.messageService.isOnline$.subscribe(isOnline => {
        if (isOnline) {
          if (!this.initialised$.value) {
            if (sub) {
              sub?.unsubscribe();
            }
          }

          switch (this.chatLoadState$.value) {
            case null:
              // this.selectChannel(this.selectedChatChannel$.value);

              if (sub) {
                sub?.unsubscribe();
              }
              break;
            case CommandName.SET_ACTIVE_CHANNEL:
              this.messageService.showMessage('Retrying to fetch messages... please wait.');
              //  TODO: below probably needs the channelID

              if (sub) {
                sub?.unsubscribe();
              }
              break;
          }
        }
      });
    }
  }

  private command(message: Message): Promise<Message> {
    const task: Completer<Message> = new Completer<Message>();

    try {
      this.goCoreMediator.sendCommand(message, (response: Response) => {
        task.complete(response as Message);
      });
    } catch (err) {
      console.error(err);
      this.goCoreErrorDisconnected$.next(true);
    }

    return task.promise;
  }

  public static isLoadingActiveChannel = false;

  public async selectChannel(channelId: string): Promise<void> {

    this.loading$.next(true);

    // Clear all message subs
    this.allSentMessages$.next([]);
    this.messages$.next([]);
    this.pendingMessages$.next([]);

    await this.loadChatParticipants(channelId);
    GoCoreChatService.isLoadingActiveChannel = true;

    // Set Gocore channel
    await this.command(new CommandGetChannel({ ChatId: channelId }));
    // Trigger gocore to fetch the messages
    await this.command(new CommandChatMessages({ channel: channelId, new: true }));

    const channelString = 'PatientTherapistChat.';

    const concatName = `${channelString}${channelId}`;
    this.channelToActivate = concatName;
    this.subscribeOnlineStatus();

    GoCoreChatService.isLoadingActiveChannel = false;
  }

  private getTherapistId(): string {
    return this.channelUsers.filter(user => user.therapistId)[0]?.userId ?? null;
  }

  private getPatientId(): string {
    return this.channelUsers.filter(u => u.role === 'patient')[0]?.patientId;
  }

  private getPatientUserId(): string {
    return this.channelUsers.filter(u => u.role === 'patient')[0]?.userId;
  }

  public async loadChatParticipants(selectedChannelId: string) {
    if (selectedChannelId == null) {
      console.error('Failed to parse active channel ID to fetch chat participants from TUB.');
      return;
    }

    try {
      this.channelUsers = await firstValueFrom(
        this.therapistChatService.TherapistChatsControllerV2GetChatParticipants(selectedChannelId),
      );
      this.therapistsInSelectedChat$.next(this.channelUsers.filter(user => user.role === 'therapist'));
    } catch (error) {
      console.error('Error loading chat participants:', error);
      this.messageService.showMessage('Error: Failed to load chat participants');
    }
  }

  public async onInviteTherapist(selectedTherapistId: string, text: string): Promise<boolean> {
    const task = new Completer<boolean>();
    if (!this.therapistIdFromPubNub) {
      task.complete(true);
      return;
    }

    await this.chatTherapistInvite(this.getPatientId(), selectedTherapistId, text).then(() => {
      task.complete(true);
    });
    return task.promise;
  }

  private async chatTherapistInvite(patientId: string, therapistId: string, message: string): Promise<void> {
    try {
      await this.command(
        new CommandChatTherapistInvite({
          PatientId: patientId,
          TherapistId: therapistId,
          Message: message,
        }) as Message,
      );

      this.messageService.showMessage('Invitation has been successfully sent.');
    } catch (e) {
      const error: ResponseError = (e as Response).error;
      if (error.Message.includes('ActionPendingError')) {
        this.messageService.showMessageAndClose(
          'Invite to chat failed. Therapist(s) already have a pending invitation.',
        );
        console.error(error.Message);
      } else if (error.Message.includes('ConflictError')) {
        this.messageService.showMessageAndClose(
          'Invite to chat failed. Invited therapist already has access to the chat.',
        );
        console.error(error.Message);
      } else {
        this.messageService.showMessageAndClose('Invite to chat failed. Please retry later.');
      }
    }
    return;
  }

  public async chatTherapistLeave(channelId: string, patientId: string) {
    try {
      // Caution: This command will not return an error if the channelID is invalid.
      const response = (await this.command(
        new CommandChatLeave({
          ChatId: channelId,
          PatientId: patientId,
        }) as Message,
      )) as Response;

      if (response) {
        this.therapistLeftSuccessfully$.next();
      }
    } catch (e) {
      const error: ResponseError = (e as Response).error;
      if (error.Message.includes('CHAT_CHANNEL_NOT_PROVIDED')) {
        this.therapistLeaveFailed$.next('THERAPIST_LEAVE_FAILED');
      } else if (error.Message.includes('CHAT_PATIENT_ID_MISSING')) {
        this.therapistLeaveFailed$.next('THERAPIST_LEAVE_FAILED');
      } else {
        this.therapistLeaveFailed$.next('CHAT_THERAPIST_UNABLE_TO_LEAVE');
      }
    }
  }

  public async chatTherapistDischarge(channelId: string, patientId: string) {
    try {
      // Caution: This command will not return an error if the channelID is invalid.
      const response = (await this.command(
        new CommandChatDischarge({
          ChatId: channelId,
          PatientId: patientId,
        }) as Message,
      )) as Response;

      if (response) {
        this.dischargeSuccessful$.next();
      }
    } catch (e) {
      const error: ResponseError = (e as Response).error;
      if (error.Message.includes('CHAT_CHANNEL_NOT_PROVIDED')) {
        this.therapistLeaveFailed$.next('THERAPIST_DISCHARGE_FAILED');
      } else if (error.Message.includes('CHAT_PATIENT_ID_MISSING')) {
        this.therapistLeaveFailed$.next('THERAPIST_DISCHARGE_FAILED');
      } else {
        this.dischargeFailed$.next('Failed to discharge patient');
      }
    }
  }

  public markMessagesRead(channelId: string) {
    this.command(new CommandChatSetLastRead({ channel: channelId }) as Message);
  }

  public loadMoreMessages(channelId: string) {
    this.command(new CommandChatMessages({ channel: channelId }) as Message);
  }

  private processMeta(
    message: ChatMessageModel,
    lastPatientMsg: ChatMessageModel,
    messages: ChatMessageModel[],
    patientId: string,
  ): ChatMessageModel {
    if (this.therapistIdFromPubNub === message.uuid) {
      const channelActivity = this.opponentsActivity.get(this.selectedChatChannel$.value);
      const patientActivity = channelActivity?.find(a => a.userID === patientId);
      const patientLastMsgTime = lastPatientMsg ? new Date(Number(lastPatientMsg.timeId / BigInt(10000))) : null;
      const messageDate = message.timeId ? new Date(Number(message.timeId / BigInt(10000))) : null;

      if (patientActivity) {
        const patientLastSeenTime = this.convertEpochDate(patientActivity.Time);
        if (messageDate != null && (patientLastSeenTime >= messageDate || patientLastMsgTime >= messageDate)) {
          message.meta['hasBeenRead'] = true;
        } else {
          message.meta['hasBeenRead'] = false;
        }
      } else if (patientLastMsgTime) {
        if (messageDate != null && patientLastMsgTime >= messageDate) {
          message.meta['hasBeenRead'] = true;
        } else {
          message.meta['hasBeenRead'] = false;
        }
      }
    }

    if (message.meta?.fields !== undefined && message?.meta?.fields.ReadOnly !== undefined) {
      message.meta['readOnly'] = message?.meta?.fields.ReadOnly.kind.value;
    }

    message.meta['owner'] = this.getMessageOwner(message.uuid);

    if (message.meta?.fields !== undefined && message.meta.fields?.uuid?.value === 'thriveTherapeutic') {
      message.meta['owner'] = 'System';
    }

    message.meta['reply'] = this.getMessageReply(message, messages);
    message.meta['sent'] = this.getMessageSent(message);

    return message;
  }

  private convertEpochDate(epoch: BigInt): Date {
    const parse = parseInt(epoch.toString());
    const date = new Date(0);
    date.setUTCMilliseconds(parse / 10000);
    return date;
  }

  private getMessageSent(message: ChatMessageModel): boolean {
    let sentValue = true;
    if (message.meta?.fields !== undefined && message.meta?.fields.Temp !== undefined) {
      sentValue = !message?.meta?.fields.Temp.kind.value;
    }

    return this.messageService.isOnline$.value ? sentValue : this.messageService.isOnline$.value;
  }

  private getMessageOwner(uuid: string): string {
    if (this.therapistIdFromPubNub === uuid) {
      return 'You';
    }

    const user = this.channelUsers?.filter(u => u.userId === uuid)[0];
    if (user?.role === 'therapist') {
      return 'Therapist';
    } else if (user?.role === 'patient') {
      return 'Patient';
    } else {
      return 'Therapist';
    }
  }

  private getMessageReply(message: ChatMessageModel, allMessages: ChatMessageModel[]): ChatReplyModel {
    if (message.meta?.fields !== undefined && message.meta?.fields?.REPLY_ID !== undefined) {
      const replyId = message.meta?.fields?.REPLY_ID.kind.value;
      const replyText = message.meta?.fields?.REPLY_TEXT.kind.value;
      const replyUuid = message.meta?.fields?.REPLY_AUTHOR.kind.value;
      const replyAuthor = this.getMessageOwner(replyUuid);

      if (replyId && replyText && replyUuid && replyAuthor) {
        const original = allMessages.find(m => m.timeId === replyId);

        return {
          id: replyId,
          text: replyText,
          author: replyAuthor,
          uuid: replyUuid,
          deleted: original?.deleted || false,
        } as ChatReplyModel;
      }
    }
  }

  /**
   * Returns once GoCore has confirmed that there are no pending messages
   */
  public async waitForPendingMessagesToSend(): Promise<void> {
    await firstValueFrom(
      this.arePendingMessages$.pipe(
        filter(isPending => !isPending), // Wait for no pending messages
        take(1), // Complete after the first matching value
      ),
    );
    return;
  }
}
