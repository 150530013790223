// go-chat-duplication
import { Injectable } from '@angular/core';

import { BehaviorSubject, firstValueFrom, Subject } from 'rxjs';

import { TherapistChatService } from '@backend-client/services/therapist-chat.service';
import { GoDashboardTubChatSession } from './go-classes/go-dashboard-tub-chat-session';
import { HelperService } from '@app/modules/shared/services/helper.service';
import { PinnedGoChatSession } from '@app/modules/shared/models/go-pinned-chat-session';
import { MessageService } from '@app/modules/shared/services/message.service';

@Injectable({
  providedIn: 'root'
})
export class GoActiveChatSessionsService {

  // Set to a multiple of 30
  // There is little difference between 1 and 30, or 31 and 60 etc.
  private readonly LIMIT = 120;

  constructor(
    private therapistChatService: TherapistChatService,
    private helperService: HelperService,
    private messageService: MessageService
  ) { }

  // store the latest cached copy of the chatSessions
  private chatSessions: GoDashboardTubChatSession[];

  private inProgress = false;
  private pinnedInProgress = false;

  public reloadChats = new Subject<string>();
  public unPinChatSession = new Subject<PinnedGoChatSession>();
  public pinnedChatSessions$ = new BehaviorSubject<PinnedGoChatSession[]>([]);

  async getActiveChatSessions(): Promise<GoDashboardTubChatSession[]> {
    if (this.chatSessions) {
      // in the event that chatSessions is already set, return it immediately
      return this.chatSessions;
    } else if (this.inProgress) {
      // otherwise, chatSessions is not set, but the fetch is already in progress, so wait and then return chatSessions
      while (this.inProgress) {
        await this.helperService.delay(10);
      }
      return this.chatSessions;
    } else {
      // chatSessions is not set, and the fetch is not already in progress, so start the fetch
      try {
        this.inProgress = true;
        // load in the chats page by page into the sessions array before continuing
        const sessions = [];
        let chatPage = null;
        let skipCount = 0;
        do {
          chatPage = await firstValueFrom(this.therapistChatService.TherapistChatsControllerV2ListChats({ pubNub: true, skip: skipCount, limit: this.LIMIT }));
          // When paging is requested for chat lists, TUB will disable any filtering of null entries in order to
          // maintain the count (the length of the chat list array will still be equal to the 'limit')
          // So we need to ensure we strip this out before passing chat sessions out to the consumer
          const filteredResults = chatPage.filter(el => el !== null);
          sessions.push(...filteredResults);
          skipCount += this.LIMIT;
        } while (chatPage.length === this.LIMIT);

        this.chatSessions = sessions.map((session: GoDashboardTubChatSession) => {
          // Note: ChatConnection service not used here as it introduces a circular dependency.
          session.isLeadTherapist = false;
          return session;
        });
      } catch (exc) {
        // in the event of an error, set chatSessions to null and log it
        this.chatSessions = null;
        console.error('could not fetch chat sessions', exc);
      } finally {
        // however the fetch operation went, set inProgress to false to free up any other waiters
        this.inProgress = false;
      }
      // regardless of the fetch operations success, return the chat sessions - to maintain compatibility with the previous implementation
      return this.chatSessions;
    }
  }

  async getPinnedChatSessions(): Promise<GoDashboardTubChatSession[]> {
    if (this.pinnedChatSessions$.value && this.pinnedInProgress) {
      // in the event that pinnedChatSessions is already set, return
      return;
    }
    // pinnedChatSessions is not set, and the fetch is not already in progress, so start the fetch
    try {
      this.pinnedInProgress = true;
      // load in the chats page by page into the sessions array before continuing
      const sessions = [];
      let chatPage = null;
      let skipCount = 0;
      do {
        chatPage = await firstValueFrom(this.therapistChatService.TherapistChatsListPinnedChats({ skip: skipCount, limit: this.LIMIT }));
        // When paging is requested for chat lists, TUB will disable any filtering of null entries in order to
        // maintain the count (the length of the chat list array will still be equal to the 'limit')
        // So we need to ensure we strip this out before passing chat sessions out to the consumer
        const filteredResults = chatPage.filter(el => el !== null);
        sessions.push(...filteredResults);
        skipCount += this.LIMIT;
      } while (chatPage.length === this.LIMIT);

      this.pinnedChatSessions$.next(sessions.map((session: GoDashboardTubChatSession) => {
        // Note: ChatConnection service not used here as it introduces a circular dependency.
        session.isLeadTherapist = false;
        return session;
      }));
    } catch (exc) {
      // in the event of an error, set pinnedChatSessions to null and log it
      this.pinnedChatSessions$.next(null);
      console.error('could not fetch chat sessions', exc);
    } finally {
      // however the fetch operation went, set pinnedInProgress to false to free up any other waiters
      this.pinnedInProgress = false;
    }
    // regardless of the fetch operations success, return
    return;
  }

  public clearCache(): void {
    // only allow the cache to be "cleared" and hence request a new chat session list if there isn't one cached, and if there
    // isn't one in progress already
    if (this.chatSessions && !this.inProgress) {
      this.chatSessions = null;
    }
  }

  public hasSessionIdInCurrentCache(chatSessionId: string): boolean {
    return this.chatSessions?.some(session => session.id === chatSessionId);
  }

  public async pinChatSession(patientChatSession: PinnedGoChatSession, shouldPin: boolean): Promise<void> {
    const pinnedChats = this.pinnedChatSessions$.value;
    if (shouldPin) {
      if (pinnedChats.filter(p => p.id === patientChatSession.id).length === 0) {
        await this.handlePinChatSession(patientChatSession, pinnedChats);
      } else {
        await this.handleUnpinChatSession(patientChatSession, pinnedChats);
      }
    } else {
      await this.handleUnpinChatSession(patientChatSession, pinnedChats);
    }
  }

  private async handlePinChatSession(patientChatSession: PinnedGoChatSession, pinnedChats: PinnedGoChatSession[]): Promise<void> {
    patientChatSession.pinned = true;
    pinnedChats.unshift(patientChatSession);
    await firstValueFrom(this.therapistChatService.TherapistChatsPinChatSession(patientChatSession.patient.id))
      .then(() => this.messageService.showMessage(`Chat session ${patientChatSession.patient.email} pinned`));
  }

  private async handleUnpinChatSession(patientChatSession: PinnedGoChatSession, pinnedChats: PinnedGoChatSession[]): Promise<void> {
    patientChatSession.pinned = false;
    pinnedChats = pinnedChats.filter(p => p.id !== patientChatSession.id);
    this.pinnedChatSessions$.next(pinnedChats);
    this.unPinChatSession.next(patientChatSession);
    await firstValueFrom(this.therapistChatService.TherapistChatsUnpinChatSession(patientChatSession.patient.id))
      .then(() => this.messageService.showMessage(`Chat session ${patientChatSession.patient.email} unpinned`));
  }
}
