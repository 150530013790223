import { Component } from '@angular/core';

import { FeatureFlagService } from '@shared/services/feature-flag.service';
import { GoogleDriveDownloadService } from '@shared/services/google-drive/google-drive-download.service';
import { MomentType } from '../moment-type';
import { GoogleDriveFileMoment } from './google-drive-file-moment';
import { MomentService } from '../moment.service';
import { AppointmentStep } from '../../add-edit-appointment-moment/appointment-step.enum';

@Component({
    selector: 'app-google-drive-file-moment',
    templateUrl: './google-drive-file-moment.component.html',
    styleUrls: ['./google-drive-file-moment.component.scss'],
    standalone: false
})
export class GoogleDriveFileMomentComponent {
  public momentData: GoogleDriveFileMoment;
  public momentType = MomentType;
  public appointmentStep = AppointmentStep;
  public showDownloadButton = this.googleDriveDownloadService.showDownloadButton;

  public featureFlags$ = this.featureFlagService.featureFlags$;

  constructor(
    public momentService: MomentService,
    private featureFlagService: FeatureFlagService,
    private googleDriveDownloadService: GoogleDriveDownloadService,
  ) { }

  public async addEditFileDescription(momentData: GoogleDriveFileMoment ) {
    await this.momentService.addEditDescriptionForGoogleFileMoment(momentData, momentData?.description);
  }
}
