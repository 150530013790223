import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';

const ANIMATION_DURATION_IN_FAST = '180ms ease-in';
const ANIMATION_DURATION_IN_SLOW = '250ms ease-in';
const ANIMATION_DURATION_OUT_SLOW = '250ms ease-out';

export const onMainNavChange = trigger('onMainNavChange', [
  state(
    'close',
    style({
      'max-width': '94px'
    })
  ),
  state(
    'open',
    style({
      'max-width': '260px'
    })
  ),
  transition('close <=> open', animate(ANIMATION_DURATION_IN_SLOW))
]);

export const onMainContentChange = trigger('onMainContentChange', [
  state(
    'close',
    style({
      'margin-left': '94px'
    })
  ),
  state(
    'open',
    style({
      'margin-left': '250px'
    })
  ),
  transition('close <=> open', animate(ANIMATION_DURATION_IN_FAST))
]);

export const animateText = trigger('animateText', [
  state(
    'hide',
    style({
      display: 'none',
      opacity: 0
    })
  ),
  state(
    'show',
    style({
      display: 'block',
      opacity: 1
    })
  ),
  transition('close => open', animate(ANIMATION_DURATION_IN_SLOW)),
  transition('open => close', animate(ANIMATION_DURATION_OUT_SLOW))
]);
