import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { GAD7, GAD7_Panic, GAD7_Panic_Answers, PHQ_GAD_Answers, PHQ_GAD_Initial_Question } from '../phq-step/phq-gad-questionnaire';

@Component({
    selector: 'app-gad-step',
    templateUrl: './gad-step.component.html',
    styleUrls: ['./gad-step.component.scss'],
    standalone: false
})
export class GadStepComponent {
  @Input() gadFormGroup: FormGroup;
  @Input() gadPanicFormGroup: FormGroup;
  @Input() gadScore: number;
  @Input() gad7Active: boolean;
  @Input() shouldDisplayGadPanicQuestion: boolean;

  public phqGadInitialQuestion = PHQ_GAD_Initial_Question;
  public gadQuestions = Object.values(GAD7);
  public gadQuestionsKeys = Object.keys(GAD7);
  public gad7PanicQuestion = Object.values(GAD7_Panic);
  public gad7PanicQuestionKey = Object.keys(GAD7_Panic);
  public gad7PanicAnswers = Object.values(GAD7_Panic_Answers);
  public phqGadAnswers = Object.values(PHQ_GAD_Answers);

}
