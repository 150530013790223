import { Injectable } from '@angular/core';
import { initializeApp } from '@angular/fire/app';
import { fetchAndActivate, getRemoteConfig, getValue, getString, getBoolean, getNumber, getAll, Value } from 'firebase/remote-config';
import { environment } from '@environments/environment';
import * as remoteConfigDefaults from './remote_config_defaults.json';

@Injectable({
  providedIn: 'root'
})
export class RemoteConfigService {
  private remoteConfig = getRemoteConfig();

  constructor() {
    const app = initializeApp(environment.firebase);
    this.remoteConfig = getRemoteConfig(app);
    this.remoteConfig.defaultConfig = remoteConfigDefaults;
  }

  async initializeRemoteConfig(): Promise<boolean> {
    return await this.fetchAndActivate();
  }

  async fetchAndActivate(): Promise<boolean> {
    try {
      const activated = await fetchAndActivate(this.remoteConfig);
      console.log('Remote Config fetched and activated:', activated);
      return activated;
    } catch (error) {
      console.error('Error fetching and activating Remote Config:', error);
      return false;
    }
  }

  getAll(): Record<string, Value> {
    return getAll(this.remoteConfig);
  }

  getString(key: string, defaultValue = ''): string {
    return getString(this.remoteConfig, key) ?? defaultValue;
  }

  getBoolean(key: string, defaultValue = false): boolean {
    return getBoolean(this.remoteConfig, key) ?? defaultValue;
  }

  getNumber(key: string, defaultValue = 0): number {
    return getNumber(this.remoteConfig, key) ?? defaultValue;
  }
}
