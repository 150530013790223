<a *ngIf="!linkData.children; else expandedNavLink" class="link"
   [ngClass]="(mainNavService.mainNavOpen$ | async) || (mainNavService.mainNavState$ | async) ? 'menu-open' : 'menu-closed'"
   mat-list-item routerLink="{{linkData.link}}" routerLinkActive="selected-route">
  <div fxLayout="row" class="link-text">
    <mat-icon *ngIf="!linkData.svgIcon" class="icon">{{ linkData.matIcon }}</mat-icon>
    <mat-icon *ngIf="linkData.svgIcon" [svgIcon]="linkData.svgIcon" class="icon svg"></mat-icon>
    <span [@animateText]="(mainNavService.mainNavOpen$ | async) || (mainNavService.mainNavState$ | async) ? 'show' : 'hide'">
      {{ linkData.displayName }}
    </span>
  </div>
</a>

<ng-template #expandedNavLink>
  <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z0" [expanded]="linkData.children | containsActiveLink">
      <mat-expansion-panel-header [expandedHeight]="'48px'">
        <mat-panel-title [ngClass]="(mainNavService.mainNavOpen$ | async) || (mainNavService.mainNavState$ | async) ? 'menu-open' : 'menu-closed'">
          <mat-icon *ngIf="!linkData.svgIcon" class="icon">{{ linkData.matIcon }}</mat-icon>
          <mat-icon *ngIf="linkData.svgIcon" [svgIcon]="svgIcon" class="icon svg"></mat-icon>
          <div fxFlex="column" fxFlexAlign="center" [@animateText]="(mainNavService.mainNavOpen$ | async) || (mainNavService.mainNavState$ | async) ? 'show' : 'hide'">
            {{ linkData.displayName }}
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-nav-list>
        <a *ngFor="let childNavLink of linkData.children; trackByProperty: 'childLinks'" class="link"
           [ngClass]="(mainNavService.mainNavOpen$ | async) || (mainNavService.mainNavState$ | async) ? 'menu-open' : 'menu-closed'"
           mat-list-item routerLink="{{ childNavLink.link }}" routerLinkActive="selected-route">
          <div fxLayout="row" class="link-text">
            <mat-icon *ngIf="!childNavLink.svgIcon" class="icon">{{ childNavLink.matIcon }}</mat-icon>
            <mat-icon *ngIf="childNavLink.svgIcon" [svgIcon]="svgIcon" class="icon svg"></mat-icon>
            <div class="link-text" [@animateText]="(mainNavService.mainNavOpen$ | async) || (mainNavService.mainNavState$ | async) ? 'show' : 'hide'">
              {{ childNavLink.displayName }}
            </div>
          </div>
        </a>
      </mat-nav-list>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>
