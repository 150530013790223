import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { RouterResolveStatusService } from '@shared/services/router-resolve-status.service';
import { ServerStatusService } from '@shared/services/server-status.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { TubErrorReportingService } from '@shared/services/tub-error-reporting.service';
import { AuthenticationService } from './modules/authentication/authentication.service';
import { LazyInjectorService } from './modules/shared/services/lazy-injector.service';
import { TitleBadgeService } from '@app/modules/shared/services/title-badge.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ]
})
export class AppComponent implements OnInit {

  @ViewChild('themeCSS') themeElement: ElementRef;

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private angularFireAuth: AngularFireAuth,
    private authenticationService: AuthenticationService,
    private routerResolveStatusService: RouterResolveStatusService,
    private serverStatusService: ServerStatusService,
    private environmentService: EnvironmentService,
    private tubErrorReportingService: TubErrorReportingService,
    private lazyInjectorService: LazyInjectorService,
  ) {
    this.iconRegistry.addSvgIcon('google-drive', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/google-drive.svg'));
  }

  private baseTitle = 'Thrive Dashboard';

  ngOnInit(): void {

    this.baseTitle = this.environmentService.getEnvironment().dashboardTitle ?? 'Thrive Dashboard';

    this.updateTitleOnRouteChange();
    this.router.events.subscribe(event => {
      this.navigationInterceptor(event);
    });
    this.serverStatusService.pollForServerStatus();
    this.environmentService.getVersion().subscribe((data) => {
      console.log(JSON.stringify(data));
    });
  }

  private navigationInterceptor(event): void {
    if (event instanceof  NavigationStart) {
      // @ts-ignore
      this.routerResolveStatusService.isLoading = true;
    } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
      // @ts-ignore
      this.routerResolveStatusService.isLoading = false;
    } else if (event instanceof NavigationError) {
      // TODO: Handle this Navigation error when any of the API calls in the resolver fail
      console.error('Navigation failed!', event);
      this.tubErrorReportingService.send('Navigation failed', event.error, event);
    }
  }

  private updateTitleOnRouteChange(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else {
            return child.snapshot.data.title ? child.snapshot.data.title : null;
          }
        }
        return null;
      })
    ).subscribe(async (pageTitle: string) => {
      let listener: any;
      let titleBadgeService: any;
      const fullTitle = this.baseTitle + (pageTitle ? ` - ${pageTitle}` : '');
      this.titleService.setTitle(fullTitle);

      const userSub = combineLatest([
        this.angularFireAuth.user,
        this.authenticationService.isScopePresent$('chat:view-active-chats')
      ]).subscribe(async ([user, hasScope]) => {
          if (hasScope) {
            titleBadgeService = await this.lazyInjectorService.get<TitleBadgeService>(() =>
              import('./modules/shared/services/title-badge.service').then((m) => m.TitleBadgeService)
            );
          }

          if (user && hasScope && document.hasFocus()) {
            titleBadgeService.setBadge(fullTitle);
            userSub.unsubscribe();
            removeEventListener('focus', listener);
          } else if (user && hasScope && !document.hasFocus()) {
            listener = addEventListener('focus', (e) => {
              if (e && user) {
                titleBadgeService.setBadge(fullTitle);
                userSub.unsubscribe();
              }
            });
          }
        });
    });
  }
}
