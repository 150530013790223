<div fxLayout="column" fxLayoutGap="32px" class="stepper-content questions" [formGroup]="gadFormGroup">
  <p>{{ phqGadInitialQuestion }}</p>
  <div *ngFor="let gadQuestion of gadQuestions; let i = index; trackByProperty: 'gadQuestionsAnswers'" class="gad-question">
    <label>{{i + 1}}. {{ gadQuestion }}</label>
    <mat-radio-group formControlName="{{ gadQuestionsKeys[i] }}">
      <mat-radio-button *ngFor="let answer of phqGadAnswers; let answerIndex = index; trackByProperty: 'phqGadAnswers'" [disabled]="i > 1 && !gad7Active" [value]="answerIndex">{{ answer }}</mat-radio-button>
    </mat-radio-group>
  </div>

  <div [formGroup]="gadPanicFormGroup" class="gad-question">
    <label>{{ gad7PanicQuestion[0] }}</label>
    <mat-radio-group formControlName="{{ gad7PanicQuestionKey[0] }}">
      <mat-radio-button *ngFor="let answer of gad7PanicAnswers; let answerIndex = index; trackByProperty: 'gad7PanicAnswers'" [disabled]="!shouldDisplayGadPanicQuestion" [value]="answerIndex">{{ answer }}</mat-radio-button>
    </mat-radio-group>
  </div>

  <div>
    Total Score: {{ gadScore }}
  </div>
</div>
<p class="small-print"><small>UHS Rev 4/2020 - Developed by Drs. Robert L. Spitzer, Janet B.W. Williams, Kurt Kroenke and colleagues, with an educational grant from Pfizer Inc.</small></p>
