import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { PHQ9, PHQ_GAD_Answers, PHQ_GAD_Initial_Question } from './phq-gad-questionnaire';

@Component({
    selector: 'app-phq-step',
    templateUrl: './phq-step.component.html',
    styleUrls: ['./phq-step.component.scss'],
    standalone: false
})
export class PhqStepComponent  {
  @Input() phqFormGroup: FormGroup;
  @Input() phqScore: number;
  @Input() phq9Active: boolean;

  public phqGadInitialQuestion = PHQ_GAD_Initial_Question;
  public phqQuestions = Object.values(PHQ9);
  public phqQuestionsKeys = Object.keys(PHQ9);
  public phqGadAnswers = Object.values(PHQ_GAD_Answers);
}
