import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { firstValueFrom, take } from 'rxjs';

import { PatientTimelineService } from '@backend-client/services';
import { DialogService } from '@shared/components/dialog/dialog.service';
import { GoogleDriveService } from '@shared/services/google-drive/google-drive.service';
import { GoogleDrivePreviewService } from '@shared/services/google-drive/google-drive-preview.service';
import { GoogleDriveDownloadService } from '@shared/services/google-drive/google-drive-download.service';
import { TimelineService } from '../timeline.service';
import { Moment, PatientMoments } from './moment';
import { SoftDeleteMomentDialogComponent } from './soft-delete-moment-dialog/soft-delete-moment-dialog.component';
import { DeleteNoteMomentDialogComponent } from './note-moment/delete-note-moment-dialog/delete-note-moment-dialog.component';
import { AddEditGoogleFileMomentDescriptionComponent } from './google-drive-file-moment/add-edit-google-file-moment-description/add-edit-google-file-moment-description.component';
import { GoogleFileMomentDescriptionHistoryComponent } from './google-drive-file-moment/google-file-moment-description-history/google-file-moment-description-history.component';
import { GoogleDriveFileMoment } from './google-drive-file-moment/google-drive-file-moment';
import { ChatMoment } from './chat-moment/chat-moment';
import { ChatLogDialogComponent } from '../chat-log-dialog/chat-log-dialog.component';
import { ExpandNoteMomentDialogComponent } from './note-moment/expand-note-moment-dialog/expand-note-moment-dialog.component';
import { NoteMoment } from './note-moment/note-moment';
import { AppointmentStep } from '../add-edit-appointment-moment/appointment-step.enum';
import { MatDialogRef } from '@angular/material/dialog';

@Injectable()
export class MomentService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private timelineService: TimelineService,
    private patientTimelineService: PatientTimelineService,
    private googleDrivePreviewService: GoogleDrivePreviewService,
    private googleDriveDownloadService: GoogleDriveDownloadService,
    private googleDriveService: GoogleDriveService
  ) {}

  /**
   *
   * Edit moments
   *
   **/
  public editAppointmentMoment(momentData: PatientMoments, preloadStep?: AppointmentStep) {
    const appointmentId = momentData.appointmentRef ? momentData.appointmentRef : momentData.id;
    this.router.navigate([ `/therapist/assigned-patients/patient-profile/${this.timelineService.patientId$.value}/appointment/${appointmentId}`], { queryParams: { step: preloadStep } });
  }

  /**
   *
   * Note methods
   *
   **/
  public async deleteNoteMoment(momentId: string): Promise<void> {
    await firstValueFrom(this.patientTimelineService.PatientsMomentsDeleteNoteMoment({ momentId, patientId: this.timelineService.patientId$.value }));
  }

  public expandButtonClicked(momentData: NoteMoment): void {
    this.dialogService.openDialog(ExpandNoteMomentDialogComponent, {
      title: `${momentData.author}`,
      subtitle: `${momentData.timestamp.toDateString()}, ${momentData.timestamp.toLocaleTimeString()}`,
      note: momentData.note,
      timelineService: this.timelineService,
    }, { hasBackdrop: false });
  }

  /**
   *
   * Chat methods
   *
   **/
  public async goToActiveChatClicked(chatRoomId: string): Promise<void> {
    try {
      await this.router.navigate([ `/therapist/chat/${chatRoomId}` ]);
    } catch (err) {
      console.error(err);
    }
  }

  //go-chat-duplication start
  public async goToActiveGoChatClicked(chatRoomId: string): Promise<void> {
    try {
      await this.router.navigate([ `/therapist/go-chat/${chatRoomId}` ]);
    } catch (err) {
      console.error(err);
    }
  }
  // go-chat-duplication end

  public viewChatLogClicked(chatMoment: ChatMoment) {
    const data = {
      chatMoment,
      patientId: this.timelineService.patientId$.value
    };
    this.dialogService.openDialog(ChatLogDialogComponent, data);
  }

  /**
   *
   * Google Cloud File & Call (Legacy) methods
   *
   **/
  public async downloadMoment(momentId: string): Promise<void> {
    // first, acquire the signedUrl needed to actually download the file
    const url = await firstValueFrom(this.patientTimelineService.PatientsMomentsGetPatientFileContentDownloadUrl({ momentId, patientId: this.timelineService.patientId$.value }));

    // second, actually trigger the download
    window.open(url.signedUrl);
  };

  /**
   *
   * Google Drive methods
   *
   **/
  public addEditDescriptionForGoogleFileMoment(momentData: Moment, description?: string): MatDialogRef<AddEditGoogleFileMomentDescriptionComponent> {
    const data = { timelineService: this.timelineService, patientId: this.activatedRoute.snapshot.params.patientId, momentId: momentData.id, description};
    return this.dialogService.openDialog(AddEditGoogleFileMomentDescriptionComponent, data);
  }

  public fetchAuditHistoryForGoogleFileMoment(momentData: Moment): void {
    const data = { timelineService: this.timelineService, patientId: this.activatedRoute.snapshot.params.patientId, momentId: momentData.id};
    this.dialogService.openDialog(GoogleFileMomentDescriptionHistoryComponent, data);
  }

  public previewGoogleDriveMoment(momentData: GoogleDriveFileMoment): void {
    this.googleDrivePreviewService.googleFileMoment = momentData;
    const hasAccess = this.googleDriveService.checkUserHasAccessToFolder();
    if (hasAccess) {
      this.googleDrivePreviewService.previewFile();
    }
  }

  public downloadGoogleDriveMoment(momentData: GoogleDriveFileMoment): void {
    this.googleDriveDownloadService.driveFileMoment = momentData;
    this.googleDriveService.initTokenDownload();
  }

  /**
   *
   * Deletion methods
   *
   **/
  public softDeleteButtonClicked(momentData: Moment): void {
    const dialog = this.dialogService.openDialog(SoftDeleteMomentDialogComponent, {
      id: momentData.id,
      patientId: this.timelineService.patientId$.value,
      moment: momentData,
      timelineService: this.timelineService
    });

    dialog.afterClosed().pipe(take(1)).subscribe((reload) => {
      if (reload) {
        this.timelineService.ensureTypeSelectedOnFilterOptions(momentData.typeName);
        this.timelineService.updateTimelineWithFilterOptions();
      }
    });
  }

  public hardDeleteNoteButtonClicked(momentData: Moment, momentService: MomentService): void {
    const dialog = this.dialogService.openDialog(DeleteNoteMomentDialogComponent, {
      momentId: momentData.id,
      momentService
    });

    dialog.afterClosed().pipe(take(1)).subscribe((reload) => {
      if (reload) {
        this.timelineService.ensureTypeSelectedOnFilterOptions(momentData.typeName);
        this.timelineService.updateTimelineWithFilterOptions();
      }
    });
  }
}
