import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { TubPatient } from '@backend-client/models';
import { AppointmentMomentService } from '../../appointment-moment.service';
import { GAD7, GAD7_Panic, PHQ9 } from '../phq-step/phq-gad-questionnaire';

@Component({
    selector: 'app-validation-step',
    templateUrl: './validation-step.component.html',
    styleUrls: ['./validation-step.component.scss'],
    standalone: false
})
export class ValidationStepComponent {
  @Input() patientId: string;
  @Input() patient: TubPatient;
  @Input() editReasonFormGroup: FormGroup;
  @Input() appointmentFormGroup: FormGroup;
  @Input() noteFormGroup: FormGroup;
  @Input() phqFormGroup: FormGroup;
  @Input() gadFormGroup: FormGroup;
  @Input() gadPanicFormGroup: FormGroup;
  @Input() cgiFormGroup: FormGroup;
  @Input() attachmentsFormGroup: FormGroup;

  @Input() editMode: boolean;
  @Input() sessionCancelled: boolean;
  @Input() phqGadRequired: boolean;
  @Input() phq9Active: boolean;
  @Input() gad7Active: boolean;
  @Input() savingData: boolean;
  @Input() savingCompleted: boolean;
  @Input() shouldDisplayGadPanicQuestion: boolean;

  @Output() save = new EventEmitter();
  @Output() markGadPhqAsNotRequired = new EventEmitter();

  public gadQuestionsKeys = Object.keys(GAD7);
  public gadPanicQuestionKey = Object.keys(GAD7_Panic);
  public phqQuestionsKeys = Object.keys(PHQ9);
  public today = new Date();

  public get editReasonFormGroupValid(): boolean {
    return this.hasErrors('reason', this.editReasonFormGroup, [
      'required',
      'trimErrorStart',
      'trimErrorEnd',
      'multipleWhitespacesError',
      'lengthError'
    ]);
  }
  public get appointmentAttendanceStatusOtherValid(): boolean {
    return this.hasErrors('appointmentAttendanceStatusOther', this.appointmentFormGroup, [
      'required',
      'trimErrorStart',
      'trimErrorEnd',
      'multipleWhitespacesError',
      'lengthError'
    ]);
  }
  public get timeValid(): boolean {
    return this.hasErrors('appointmentTime', this.appointmentFormGroup, [
      'required',
      'invalidTimeFuture',
      'matTimepickerParse'
    ]);
  }
  public get appointmentSessionNumberValid(): boolean {
    return this.appointmentFormGroup.get('appointmentSessionNumber').hasError('required') ||
           this.appointmentFormGroup.get('appointmentSessionNumber').value &&
           +this.appointmentFormGroup.get('appointmentSessionNumber').value < 1;
  }
  public get notesValid(): boolean {
    return this.hasErrors('note', this.noteFormGroup, [
      'required',
      'trimErrorStart',
      'trimErrorEnd',
      'multipleWhitespacesError',
      'lengthError'
    ]);
  }
  public get phqValid(): boolean {
    return !this.sessionCancelled && this.phqFormGroup.errors !== null && !this.phqGadRequired ||
           !this.sessionCancelled && this.phqFormGroup.dirty && !this.phqGadRequired ||
           this.sessionCancelled && this.phqFormGroup.dirty;
  }
  public get gadValid(): boolean {
    return !this.sessionCancelled && this.gadFormGroup.errors !== null && !this.phqGadRequired ||
           !this.sessionCancelled && this.gadPanicFormGroup.errors !== null && !this.phqGadRequired ||
           !this.sessionCancelled && this.gadFormGroup.dirty && !this.phqGadRequired ||
           !this.shouldDisplayGadPanicQuestion && this.gadPanicFormGroup.dirty && !this.phqGadRequired ||
           this.sessionCancelled && this.gadFormGroup.dirty ||
           this.shouldDisplayGadPanicQuestion && this.gadPanicFormGroup.dirty;
    }

  constructor(private appointmentMomentService: AppointmentMomentService) {}

  public saveDisabled(): boolean {
    if (this.editMode && this.editReasonFormGroup.invalid) {
      return true;
    }

    if (this.appointmentMomentService.errorFetchingAttachments$.value) {
      return true;
    }

    if (this.sessionCancelled) {
      // PHQ & GAD if dirty are required
      if (this.gadFormGroup.dirty || this.phqFormGroup.dirty || this.gadPanicFormGroup.dirty) {
        // All PHQ & GAD must be answered
        return !this.gadFormGroup.valid || (this.shouldDisplayGadPanicQuestion && !this.gadPanicFormGroup.valid) || !this.phqFormGroup.valid;
      }

      if (this.cgiFormGroup.dirty) {
        return !this.cgiFormGroup.valid;
      }

      return !this.appointmentFormGroup.valid;
    }

    if (!this.sessionCancelled && this.appointmentFormGroup.valid && this.cgiFormGroup.valid) {
      if (this.gadFormGroup.pristine && this.gadPanicFormGroup.pristine && this.phqFormGroup.pristine) {
        return false;
      }

      // PHQ & GAD if dirty are required
      if (this.gadFormGroup.dirty || this.gadPanicFormGroup.dirty || this.phqFormGroup.dirty) {
        // All PHQ & GAD must be answered
        return !this.gadFormGroup.valid || (this.shouldDisplayGadPanicQuestion && !this.gadPanicFormGroup.valid) || !this.phqFormGroup.valid;
      }
    }

    return true;
  }

  private hasErrors(controlName: string, formGroup: FormGroup, errors: string[]): boolean {
    const control = formGroup.get(controlName);
    return errors.some(error => control.hasError(error));
  }
}
