
export class ChatLastReadModel {
  channel: string;
  Time: BigInt;
  userID: string;
}

import { TubChatUserPresenceInfo } from '@backend-client/models/tub-chat-user-presence-info';
import { ChatMessageModel, ChatChannelModel, ChatActiveChannelModel, ChatSessionModel, ChatUserModel } from '.';

export class ChatClient {

    public channelToActivate: string;
    public loading: boolean = false;
    public channels: ChatChannelModel[] = [];
    public messages: ChatMessageModel[] = [];
    public activeUsers: ChatActiveChannelModel[] = [];
    public channelUsers: TubChatUserPresenceInfo[] = [];
    public activeChannel: string;
    public sessions: ChatSessionModel[] = [];
    public opponentsActivity: Map<string, ChatLastReadModel[]> = new Map<string, ChatLastReadModel[]>();
    public typingNow: ChatUserModel[] = [];
}
