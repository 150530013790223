<div fxLayout="column" class="container-padding">
  <div fxFlex fxLayout="row">
    <button mat-button (click)="cancel()"><mat-icon>chevron_left</mat-icon> Return</button>
  </div>
  <mat-stepper #stepper [selectedIndex]="preloadStep">
    <mat-step [stepControl]="appointmentFormGroup" [editable]="!savingCompleted">
      <form [formGroup]="appointmentFormGroup">
        <ng-template matStepLabel>Appointment Information</ng-template>
        <app-appointment-step
          [patientId]="patientId"
          [patient]="patient"
          [editMode]="appointmentMomentService.isEditMode"
          [editReasonFormGroup]="editReasonFormGroup"
          [appointmentFormGroup]="appointmentFormGroup"
          [noteFormGroup]="noteFormGroup">
        </app-appointment-step>

        <div>
          <button mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="phqFormGroup" [editable]="!savingCompleted">
      <form [formGroup]="phqFormGroup">
        <ng-template matStepLabel>PHQ 9</ng-template>
        <app-phq-step [phqFormGroup]="phqFormGroup" [phqScore]="phqScore" [phq9Active]="phq9Active | async"></app-phq-step>

        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="gadFormGroup" [editable]="!savingCompleted">
      <form [formGroup]="gadFormGroup">
        <ng-template matStepLabel>GAD 7</ng-template>
        <app-gad-step [gadFormGroup]="gadFormGroup" [gadScore]="gadScore" [gadPanicFormGroup]="gadPanicFormGroup" [gad7Active]="gad7Active | async" [shouldDisplayGadPanicQuestion]="shouldDisplayGadPanicQuestion | async"></app-gad-step>
        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="cgiFormGroup" [editable]="!savingCompleted">
      <form [formGroup]="cgiFormGroup">
        <ng-template matStepLabel>CGI-S/ CGI-I</ng-template>
        <app-cgi-step
          [patientId]="patientId"
          [patient]="patient"
          [editMode]="appointmentMomentService.isEditMode"
          [cgiFormGroup]="cgiFormGroup">
        </app-cgi-step>

        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="attachmentsFormGroup" [editable]="!savingCompleted">
      <form [formGroup]="attachmentsFormGroup">
        <ng-template matStepLabel>Attach Therapy Session Files</ng-template>
        <app-attachments-step [attachmentsFormGroup]="attachmentsFormGroup" [patient]="patient"></app-attachments-step>

        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Save</ng-template>

      <div class="stepper-content questions">
        <div *ngIf="savingData" class="form-status saving" fxLayout="row" fxLayoutAlign="center center">
          <mat-spinner></mat-spinner>
        </div>

        <div *ngIf="errorSaving || (appointmentMomentService.errorFetchingAttachments$ | async)" class="form-status error" fxLayout="column" fxLayoutAlign="center center">
          <div fxFlex fxLayout="row" fxLayoutGap="8px">
            <mat-icon class="error">error</mat-icon>
            <p class="header">
              <span *ngIf="errorSaving">Encountered error whilst trying to save data!</span>
              <span *ngIf="appointmentMomentService.errorFetchingAttachments$ | async">Unable to save encountered error whilst trying to fetch attachments</span>
            </p>
          </div>
          <p *ngIf="appointmentMomentService.errorInformation$ | async">
            {{ appointmentMomentService.errorInformation$ | async }}
          </p>
        </div>

        <app-validation-step [patientId]="patientId"
                             [shouldDisplayGadPanicQuestion]="shouldDisplayGadPanicQuestion | async"
                             [patient]="patient"
                             [editReasonFormGroup]="editReasonFormGroup"
                             [appointmentFormGroup]="appointmentFormGroup"
                             [noteFormGroup]="noteFormGroup"
                             [phqFormGroup]="phqFormGroup"
                             [gadFormGroup]="gadFormGroup"
                             [gadPanicFormGroup]="gadPanicFormGroup"
                             [cgiFormGroup]="cgiFormGroup"
                             [attachmentsFormGroup]="attachmentsFormGroup"
                             [sessionCancelled]="sessionCancelled"
                             [phqGadRequired]="phqGadRequired"
                             [phq9Active]="phq9Active | async"
                             [gad7Active]="gad7Active | async"
                             [editMode]="appointmentMomentService.isEditMode"
                             [savingData]="savingData"
                             [savingCompleted]="savingCompleted"
                             (markGadPhqAsNotRequired)="markGadPhqAsNotRequired()"
                             (save)="save()"></app-validation-step>

        <div *ngIf="savingCompleted" class="form-status saved" fxLayout="column" fxLayoutAlign="center center">
          <p class="header">Appointment moment has been created</p>
          <div fxFlex fxLayout="row" fxLayoutGap="6px">
            <button mat-flat-button color="primary" (click)="returnToTimeline()">Return to patient timeline</button>
            <button *ngIf="patientChat.hasActiveChat" mat-flat-button color="accent" (click)="returnToChatSession()">
              Return to chat session
            </button>
          </div>
        </div>
      </div>
    </mat-step>
  </mat-stepper>
</div>
