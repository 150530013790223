import { Component } from '@angular/core';

import { FeatureFlagService } from '@shared/services/feature-flag.service';
import { RiskAssessmentEventMoment, RiskAssessmentIntroText } from './risk-assessment-event-moment';
import { MomentService } from '../moment.service';
import { AppointmentStep } from '../../add-edit-appointment-moment/appointment-step.enum';
import { MomentType } from '../moment-type';

@Component({
    selector: 'app-risk-assessment-event-moment',
    templateUrl: './risk-assessment-event-moment.component.html',
    styleUrls: ['./risk-assessment-event-moment.component.scss'],
    standalone: false
})
export class RiskAssessmentEventMomentComponent {

  public riskAssessmentIntroText = RiskAssessmentIntroText;
  public momentType = MomentType;
  public momentData: RiskAssessmentEventMoment;
  public featureFlags$ = this.featureFlagService.featureFlags$;
  public appointmentStep = AppointmentStep;

  constructor(
    public momentService: MomentService,
    private featureFlagService: FeatureFlagService
  ) {
  }
}
