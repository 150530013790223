import { Component, OnInit } from '@angular/core';

import { ChatType } from './chat-type.enum';
import { ChatMoment } from './chat-moment';
import { TimelineService } from '../../timeline.service';
import { MomentService } from '../moment.service';

@Component({
    selector: 'app-chat-moment',
    templateUrl: './chat-moment.component.html',
    styleUrls: ['./chat-moment.component.scss'],
    standalone: false
})
export class ChatMomentComponent implements OnInit {

  ChatType = ChatType;
  momentData: ChatMoment;
  isActiveChat: boolean;

  constructor(
    public momentService: MomentService,
    private timelineService: TimelineService
  ) {}

  ngOnInit() {
    if (this.momentData.chatType === ChatType.StartChat) {
      this.isActiveChat = this.timelineService.isActiveChat(this.momentData);
    }
  }
}
