import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, firstValueFrom, Observable, Subject } from 'rxjs';
import { concatMap, debounceTime, tap } from 'rxjs/operators';

import {
  DashboardTubMoment,
  TubMomentDescriptionResponseDto,
  TubMomentDescriptionAuditData,
  TubCgiCreationRequestBody,
  TubPatientRiskAssessment,
  TubCreateAppointmentMomentRequestBody,
  TubEditAppointmentMomentRequestBody,
  DashboardSoftDeletedTubMoment,
} from '@backend-client/models';
import { PatientTimelineService } from '@backend-client/services';
import { HelperService } from '@shared/services/helper.service';
import { MessageService } from '@shared/services/message.service';
import { FeatureFlagService } from '@shared/services/feature-flag.service';
import { ETagged, toTagged } from '@classes/tagged-model';
import { AppointmentMomentPosition, Moment, PatientMoments } from './moment/moment';
import { CallMoment } from './moment/call-moment/call-moment';
import { ChatMoment } from './moment/chat-moment/chat-moment';
import { ChatType } from './moment/chat-moment/chat-type.enum';
import { TimelineFilterOptions } from './timeline-filters/timeline-filter-options';
import { MomentType } from './moment/moment-type';
import { AuditEventMoment } from './moment/audit-event-moment/audit-event-moment';
import { FileMoment } from './moment/file-moment/file-moment';
import { NoteMoment } from './moment/note-moment/note-moment';
import { GoogleDriveFileMoment } from './moment/google-drive-file-moment/google-drive-file-moment';
import { RiskAssessmentEventMoment } from '@shared/components/patient-timeline/moment/risk-assessment-event-moment/risk-assessment-event-moment';
import { DischargeMoment } from '@shared/components/patient-timeline/moment/discharge-moment/discharge-moment';
import { CgiScoreMoment } from '@shared/components/patient-timeline/moment/cgi-score/cgi-score-moment';
import { AppointmentMoment } from './moment/appointment-moment/appointment-moment';
import { MomentDeleted } from './moment/moment-deleted';

@Injectable()
export class TimelineService {
  public static readonly MOMENTS_PER_REQUEST = 15;
  public static readonly TIMELINE_FILTER_DEFAULTS = {
    types: {
      note: true,
      call: true,
      file: true,
      chat: true,
      googleFile: true,
      event: true,
      discharge: true,
      cgiScore: true,
      appointment: true
    },
    date: new Date(),
  };

  public displayedMoments: Moment[];

  public patientId$ = new BehaviorSubject<string>(null);
  public patientTimelinePanelOpen$ = new BehaviorSubject<boolean>(false);
  public patientTimelineVisible$ = new BehaviorSubject<boolean>(false);
  public readonly typeFilterReset$ = new Subject<void>();
  public readonly filterOptions$ = new BehaviorSubject<TimelineFilterOptions>(TimelineService.TIMELINE_FILTER_DEFAULTS);
  private currentFilterOptions: TimelineFilterOptions = this.filterOptions$.value;
  private isAssessmentMomentsEnabledFeatureFlag = false;

  get activeFilterOptions(): TimelineFilterOptions {
    return this.currentFilterOptions;
  }

  constructor(private helperService: HelperService,
              private patientTimelineService: PatientTimelineService,
              private httpClient: HttpClient,
              private featureFlagService: FeatureFlagService,
              private messageService: MessageService) {

    this.featureFlagService.featureFlags$.subscribe(config => {
      this.isAssessmentMomentsEnabledFeatureFlag = config.isAssessmentMomentsEnabled;
    });
  }

  public async getLatestNoteMoment(patientId: string): Promise<DashboardTubMoment[]> {
    return await firstValueFrom(this.patientTimelineService
      .PatientsMomentsGetPatientMoments({
        patientId,
        types: ['note'],
        startFrom: Date.now(),
        limit: 1,
      }));
  }

  public async getMomentById(patientId: string, momentId: string): Promise<Moment> {
    const tubMoment = await firstValueFrom(this.patientTimelineService
      .PatientsMomentsGetPatientMomentById({patientId, momentId}));
    return this.convertTubMomentToMoment(tubMoment);
  }

  public async getMoments(patientId: string, types: MomentType[], limit = 1): Promise<DashboardTubMoment[]> {
    return await firstValueFrom(this.patientTimelineService
      .PatientsMomentsGetPatientMoments({
        patientId,
        types: types,
        startFrom: Date.now(),
        limit: limit,
      }));
  }

  public async addNoteMoment(patientId: string, note: string): Promise<void> {
    await firstValueFrom(this.patientTimelineService.PatientsMomentsCreatePatientNote({
      patientId,
      createPatientNoteRequestBody: { text: note }
    }));
  }

  public async addCgiMoment(patientId: string, cgiData: TubCgiCreationRequestBody): Promise<void> {
    await firstValueFrom(this.patientTimelineService.PatientsMomentsCreatePatientCgiMoment(
      { patientId, cgiBody:cgiData }
    ));
  }

  public async submitRiskAssessment(patientId: string, riskAssessment: TubPatientRiskAssessment): Promise<void> {
    await firstValueFrom(this.patientTimelineService.PatientsMomentsSubmitRiskAssessment(
      { patientId, riskAssessment }
    ));
  }

  public async createAppointmentMoment(patientId: string, appointment: TubCreateAppointmentMomentRequestBody): Promise<void> {
    await firstValueFrom(this.patientTimelineService.PatientsMomentsCreatePatientAppointmentMoment(
      { patientId, momentBody: appointment }
    ));
  }

  public async updateAppointmentMoment(patientId: string, appointment: TubEditAppointmentMomentRequestBody) {
    await firstValueFrom(this.patientTimelineService.PatientsMomentsUpdatePatientAppointmentMoment(
      { patientId, momentBody: appointment }
    ));
  }

  public async addFileMoment(patientId: string, file: File, type: MomentType): Promise<void> {
    // get document policy to upload the file
    const policy = await firstValueFrom(this.patientTimelineService.PatientsMomentsGetPatientFileUploadPolicyDocument({
      patientId,
      type,
      fileName: file.name
    }));

    const formData = new FormData();

    for (const formDataParam in policy.formData) {
      if (Object.prototype.hasOwnProperty.call(policy.formData, formDataParam)) {
        formData.append(formDataParam, policy.formData[formDataParam]);
      }
    }

    formData.append('file', file);

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    // necessary evil for CSP - regex which replaces  https://storage.googleapis.com/X/ with https://X.storage.googleapis.com/ in a string
    // which brings the bucket name into the domain so it can be whitelisted on CSP
    policy.url = policy.url.replace(
      /(https:\/\/storage\.googleapis\.com\/)([a-zA-Z0-1-]+)(\/|$)/,
      'https://$2.storage.googleapis.com/',
    );

    await this.httpClient.post(policy.url, formData, { headers }).toPromise();

    // REVIEW: Wait 8 seconds to ensure the cloud function has run on the uploaded file, and created the necessary FileMoment
    //         Task made to revisit : https://app.clubhouse.io/thrivesoft/story/1019/
    await this.helperService.delay(8000);
  }

  /**
   * Determines whether a chat is "active" or not.
   * An active chat is one that has a start chat moment, but no corresponding end chat moment
   * @param startChatMoment  The start chat moment to test
   */
  public isActiveChat(startChatMoment: ChatMoment): boolean {
    const hasCorrespondingChatEndMoment = this.displayedMoments.some(moment => {
      if (moment instanceof ChatMoment) {
        return moment.chatSessionId === startChatMoment.chatSessionId && moment.chatType === ChatType.EndChat;
      }
    });
    return !hasCorrespondingChatEndMoment;
  }

  public createServerFetchObservable(
    filterOptions: TimelineFilterOptions,
    needMoreMoments$: Observable<void>,
    patientId: string,
  ): Observable<Moment[]> {
    let paginationCursor: string;
    return needMoreMoments$.pipe(
      // End of scroll can be trigger happy, so add in debounce
      debounceTime(500),
      concatMap(() => {
        const filters = this.ensureTypeIsSet(filterOptions);
        return this.fetchAdditionalMoments(filters, patientId, paginationCursor);
      }),
      tap((moments: Moment[]) => {
        if (moments.length > 0) {
          paginationCursor = moments[moments.length - 1].id;
        }
      }),
    );
  }

  public ensureTypeSelectedOnFilterOptions(type: MomentType): void {
    let filteredType: string;

    if (type === MomentType.CgiScore) {
      filteredType = 'cgiScore';
    } else if (type === MomentType.GoogleFile) {
      filteredType = 'googleFile';
    } else {
      filteredType = type;
    }

    if (!this.getTypesFromFilterOptions(this.currentFilterOptions).includes(type)) {
      this.messageService.showMessage(`Timeline filters have been updated to display ${type} moments`);
    }

    this.currentFilterOptions.types[filteredType] = true;
  }

  public ensureTypeIsSet(filterOptions: TimelineFilterOptions): TimelineFilterOptions {
    // Type is required but can be null if all options are unselected - this ensures type values are selected if null is encountered.
    const filters = filterOptions;
    if (filters && this.getTypesFromFilterOptions(filters).length === 0) {
      for (const typeName in filters.types) {
        filters.types[typeName] = true;
      }
      this.typeFilterReset$.next();
    }
    return filters;
  }

  public updateTimelineWithFilterOptions(filterOptions?: TimelineFilterOptions): void {
    if (filterOptions) {
      this.filterOptions$.next(filterOptions);
      this.currentFilterOptions = filterOptions;
    } else {
      this.filterOptions$.next(this.currentFilterOptions);
    }
  }

  public async getPatientMomentDescription(
    patientId: string,
    momentId: string,
  ): Promise<ETagged<TubMomentDescriptionResponseDto>> {
    return toTagged<TubMomentDescriptionResponseDto>(
      await firstValueFrom(this.patientTimelineService.PatientsMomentsGetDriveMomentDescriptionResponse({ patientId, momentId }))
    );
  }

  public async updatePatientMomentDescription(patientId: string, momentId: string, description: string, eTag: string): Promise<void> {
    return await firstValueFrom(this.patientTimelineService.PatientsMomentsUpdateDriveMomentDescription({
      patientId,
      momentId,
      descriptionDTO: eTag ? { ETag: eTag, description: description } : { description: description }
    }));
  }

  public async softDeleteTherapistCreatedMoment(patientId: string, momentId: string, reason: string): Promise<void> {
    return await firstValueFrom(this.patientTimelineService.PatientsMomentsSoftDeletePatientMoment({
      momentId,
      patientId,
      reasonBody: { reason }
    }));
  }

  public async requestHardDeleteOfPatientMoment(patientId: string, momentId: string, reason: string): Promise<void> {
    return await firstValueFrom(this.patientTimelineService.PatientsMomentsHardDeletePatientMomentRequest({
      momentId,
      patientId,
      reasonBody: { reason }
    }));
  }

  public async getPatientMomentDescriptionAuditHistory(patientId: string, momentId: string): Promise<TubMomentDescriptionAuditData>{
    return await firstValueFrom(this.patientTimelineService.PatientsMomentsGetDriveMomentDescriptionAudit({ patientId, momentId }));
  }

  private async fetchAdditionalMoments(
    filterOptions: TimelineFilterOptions,
    patientId: string,
    paginationCursor?: string,
  ): Promise<Moment[]> {
    console.log('Fetching additional moments from', paginationCursor, filterOptions.types.call);

    // Convert the filter option types into an array of type name strings
    const filteredTypes = this.getTypesFromFilterOptions(filterOptions);

    // retrieve the tub moments from the server
    const tubMoments: DashboardTubMoment[] = await firstValueFrom(this.patientTimelineService.PatientsMomentsGetPatientMoments({
      patientId,
      types: filteredTypes,
      startFrom: filterOptions.date.getTime(),
      paginationCursor,
      limit: TimelineService.MOMENTS_PER_REQUEST
    }));

    // add data for appointments to linked moments to display visual link
    const moments = this.linkAppointmentMoments(tubMoments);
    // create the client-side moments that represent the tub moments
    return moments.map(tubMoment => this.convertTubMomentToMoment(tubMoment));
  }

  public convertTubMomentToMoment(tubMoment: DashboardTubMoment): Moment {
    const momentType = tubMoment.type as MomentType;
    const momentData = tubMoment.data as any;
    try {
      switch (momentType) {
        case MomentType.Call:
          return new CallMoment({
            timestamp: new Date(tubMoment.ts),
            id: tubMoment.id,
            createdByUserId: 0,
            fileName: momentData.fileName,
            author: tubMoment.author,
            deleted: tubMoment?.deleted,
            edited: momentData?.edited,
            appointmentRef: momentData?.appointmentRef,
            appointmentMomentPosition: momentData?.appointmentMomentPosition
          });
        case MomentType.Chat:
          return new ChatMoment({
            timestamp: new Date(tubMoment.ts),
            id: tubMoment.id,
            chatSessionId: momentData.chatRoomId,
            chatSessionArchive: null,
            chatType: momentData.type === 'chat-start' ? ChatType.StartChat : ChatType.EndChat,
            pubNub: momentData.pubNub,
            author: tubMoment.author,
          });
        case MomentType.Event:
          if (this.isAssessmentMomentsEnabledFeatureFlag) {
            if (momentData.type === 'RiskFactorResults') {
              return new RiskAssessmentEventMoment({
                timestamp: new Date(tubMoment.ts),
                id: tubMoment.id,
                author: tubMoment.author,
                data: momentData,
                deleted: tubMoment?.deleted,
                edited: momentData?.edited,
                appointmentRef: momentData?.appointmentRef,
                appointmentMomentPosition: momentData?.appointmentMomentPosition
              });
            } else {
              return new AuditEventMoment({
                timestamp: new Date(tubMoment.ts),
                id: tubMoment.id,
                eventTitle: momentData.type,
                author: tubMoment.author,
              });
            }
          } else {
            return new AuditEventMoment({
              timestamp: new Date(tubMoment.ts),
              id: tubMoment.id,
              eventTitle: momentData.type,
              author: tubMoment.author,
            });
          }
        case MomentType.File:
          return new FileMoment({
            timestamp: new Date(tubMoment.ts),
            id: tubMoment.id,
            createdByUserId: 0,
            fileName: momentData.fileName,
            author: tubMoment.author,
            deleted: tubMoment?.deleted,
            edited: momentData?.edited,
            appointmentRef: momentData?.appointmentRef,
            appointmentMomentPosition: momentData?.appointmentMomentPosition
          });
        case MomentType.Note:
          return new NoteMoment({
            timestamp: new Date(tubMoment.ts),
            id: tubMoment.id,
            createdByUserId: 0,
            note: momentData.note,
            author: tubMoment.author,
            deleted: tubMoment?.deleted,
            edited: momentData?.edited,
            appointmentRef: momentData?.appointmentRef,
            appointmentMomentPosition: momentData?.appointmentMomentPosition
          });
        case MomentType.GoogleFile:
          return new GoogleDriveFileMoment({
            timestamp: new Date(tubMoment.ts),
            id: tubMoment.id,
            createdByUserId: 0,
            author: tubMoment.author,
            googleDriveFileId: momentData.googleDriveFileId,
            googleDriveFolderId: momentData.googleDriveFolderId,
            fileName: momentData.fileName,
            contentType: momentData.contentType,
            description: momentData.description?.text,
            deleted: tubMoment?.deleted,
            edited: momentData?.edited,
            appointmentRef: momentData?.appointmentRef,
            appointmentMomentPosition: momentData?.appointmentMomentPosition
          });
        case MomentType.CgiScore:
          return new CgiScoreMoment({
            timestamp: new Date(tubMoment.ts),
            id: tubMoment.id,
            createdByUserId: 0,
            author: tubMoment.author,
            cgiSeverity: momentData.cgiSeverity,
            cgiImprovement: momentData.cgiImprovement,
            deleted: tubMoment?.deleted,
            edited: momentData?.edited,
            appointmentRef: momentData?.appointmentRef,
            appointmentMomentPosition: momentData?.appointmentMomentPosition
          });
        case MomentType.Discharge:
          return new DischargeMoment({
            timestamp: new Date(tubMoment.ts),
            id: tubMoment.id,
            createdByUserId: 0,
            author: tubMoment.author,
            therapistName: momentData.therapistName ? momentData.therapistName : 'Unknown',
          });
        case MomentType.Appointment:
          return new AppointmentMoment({
            timestamp: new Date(tubMoment.ts),
            id: tubMoment.id,
            createdByUserId: 0,
            author: tubMoment.author,
            appointmentDate: new Date(momentData.appointmentDateTime).toDateString(),
            appointmentTime: new Date(momentData.appointmentDateTime).toString(),
            therapySessionNumber: momentData.therapySessionNumber,
            appointmentOutcome: momentData.appointmentOutcome,
            appointmentOutcomeOther: momentData.appointmentOutcomeOther,
            howPatientIsFunctioning: momentData.howPatientIsFunctioning,
            noteRef: momentData.noteRef,
            riskAssessmentRef: momentData.riskAssessmentRef,
            cgiRef: momentData.cgiRef,
            attachmentsRef: momentData.attachmentsRef,
            deleted: tubMoment?.deleted,
            edited: momentData?.edited,
            appointmentRef: momentData?.appointmentRef,
            appointmentMomentPosition: momentData?.appointmentMomentPosition
          });
      }
    } catch (err) {
      console.error('could not convert tubmoment to clientside moment', { momentData, err });
      throw err;
    }
  }

  public getTypesFromFilterOptions(filterOptions: TimelineFilterOptions): MomentType[] {
    const types = [];
    for (const typeName in filterOptions.types) {
      // Capitalise - as google-file cannot be hyphenated on the filter options
      const momentTypeName = typeName.charAt(0).toUpperCase() + typeName.slice(1);

      if (filterOptions.types[typeName]) {
        types.push(MomentType[momentTypeName]);
      }
    }
    return types;
  }

  // Locates all moments linked to appointment moments
  private linkAppointmentMoments(tubMoments: DashboardTubMoment[]): DashboardTubMoment[] {
    const appointments = tubMoments.filter(m => m.type === MomentType.Appointment);

    // Gets all previously pulled data and adds it to the appointments array.
    if (this.displayedMoments.length > 0) {
      const previousAppointmentData = this.displayedMoments.filter(m => m.typeName === MomentType.Appointment) as any;
      appointments.push(...previousAppointmentData);
    }

    let moments = tubMoments;
    const deletedMoments: MomentDeleted[] = [];

    for (const moment of tubMoments) {
      if (moment?.deleted?.newMomentRef) {
        deletedMoments.push({ momentId: moment.id, replacementMomentId: moment.deleted.newMomentRef});
      }
    }

    // Data is reversed, so we have the latest data applied and overriding any previous data
    for (const appointment of appointments.reverse()) {
      // data first pulled is DashboardTubMoment, data previously pulled is Moment, this maps the data so the format is the same for new and previous.
      const appointmentData = appointment.data ? appointment.data as any : appointment;
      const appointmentId = appointment?.deleted?.newMomentRef ? appointment?.deleted?.newMomentRef : appointment.id;

      if (appointment?.deleted?.newMomentRef) {
        moments = this.updateMomentWithAppointmentReference(appointment.deleted.newMomentRef, moments, appointment.id, deletedMoments);
      }

      if (appointmentData.noteRef) {
        moments = this.updateMomentWithAppointmentReference(appointmentId, moments, appointmentData.noteRef, deletedMoments);
      }

      if (appointmentData.riskAssessmentRef) {
        moments = this.updateMomentWithAppointmentReference(appointmentId, moments, appointmentData.riskAssessmentRef, deletedMoments);
      }

      if (appointmentData.cgiRef) {
        moments = this.updateMomentWithAppointmentReference(appointmentId, moments, appointmentData.cgiRef, deletedMoments);
      }

      if (appointmentData.attachmentsRef && !appointment.deleted?.newMomentRef) {
        for (const attachment of appointmentData.attachmentsRef) {
          moments = this.updateMomentWithAppointmentReference(appointmentId, moments, attachment, deletedMoments);
        }
      }
    }

    this.sortAppointmentMomentsOrder(appointments, moments);

    return moments;
  }

  // Locate and store position of appointment moments
  private sortAppointmentMomentsOrder(appointments: DashboardTubMoment[], tubMoments: DashboardTubMoment[]): void {
    for (const appointment of appointments.filter(a => (a.data as PatientMoments)?.appointmentRef === undefined)) {

    const latestAppointment = appointment.id;
    const replacementAppointment = (appointment.data as PatientMoments)?.deleted?.newMomentRef;

    const moments = [];
    if (replacementAppointment) {
      moments.push(...tubMoments.filter(m => (m.data as PatientMoments).appointmentRef === replacementAppointment));
      moments.push(...tubMoments.filter(m => m.id === replacementAppointment));
      moments.push(...tubMoments.filter(m => m.id === latestAppointment));
    } else {
      moments.push(...tubMoments.filter(m => (m.data as PatientMoments).appointmentRef === latestAppointment));
      moments.push(...tubMoments.filter(m => m.id === latestAppointment));
    }

    // Order moments
    moments.sort((a, b) => b.ts - a.ts);
    // Remove duplicates (new Set()) ES6
    const orderedMoments = Array.from(new Set(moments));

    for (const [index, moment] of orderedMoments.entries()) {
      if (index === 0) {
        (moment.data as PatientMoments).appointmentMomentPosition = AppointmentMomentPosition.last;
      } else if (index === (orderedMoments.length - 1)) {
        (moment.data as PatientMoments).appointmentMomentPosition = AppointmentMomentPosition.start;
      } else {
        (moment.data as PatientMoments).appointmentMomentPosition = AppointmentMomentPosition.middle;
      }
    }
  }}

  // Adds appointment ref to moments that are part of an appointment moment
  private updateMomentWithAppointmentReference(appointmentId: string, tubMoments: DashboardTubMoment[], ref: string, deletedMoments?: MomentDeleted[]): DashboardTubMoment[] {
    const moment = tubMoments.find(m => m.id === ref) as DashboardTubMoment;

    let deleted: DashboardSoftDeletedTubMoment;
    // Handles deleted moments
    const previouslyDeletedMoments = tubMoments.filter(m => m?.deleted?.newMomentRef === ref);

    if (previouslyDeletedMoments.length > 0) {
      previouslyDeletedMoments.forEach(deleted => (deleted.data as PatientMoments).appointmentRef = appointmentId);
    }

    if (moment) {
      // Adds appointment reference to existing moments, allowing a line to link them together
      (moment.data as PatientMoments).appointmentRef = appointmentId;
    }

    // Handles deleted appointments
    if (moment?.deleted) {
      deleted = moment?.deleted;
    }
    if (moment?.deleted?.newMomentRef) {
      const replacementMomentId = this.checkForReplacementId(moment.id, deletedMoments);

      if (moment.type === MomentType.Appointment) {
        (moment.data as PatientMoments).appointmentRef = replacementMomentId;
      } else {
        const replacementMoment = tubMoments.filter(m => m.id === replacementMomentId)[0];

        if (replacementMoment) {
          (replacementMoment.data as PatientMoments).appointmentRef = appointmentId;
        }
      }

      const editedMoment = tubMoments.find(m => m.id === moment?.deleted?.newMomentRef);

      if (editedMoment) {
        (editedMoment.data as PatientMoments).edited = {
          author: deleted.author,
          ts: deleted.ts,
          reason: deleted.reason,
        };
      }
    }

    return tubMoments;
  }

  private checkForReplacementId(appointmentId: string, deletedMoments: MomentDeleted[]): string {
    const replacementAppointmentId = (deletedMoments.find(moment => moment.momentId === appointmentId)).replacementMomentId;

    if (deletedMoments.find(moment => moment.momentId === replacementAppointmentId)) {
      return this.checkForReplacementId(replacementAppointmentId, deletedMoments);
    }

    return replacementAppointmentId;
  }
}
