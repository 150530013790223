<ng-container *ngIf="moment.typeName === momentType.Appointment" [ngTemplateOutlet]="appointmentMoment" />
<ng-container *ngIf="moment.typeName === momentType.Note" [ngTemplateOutlet]="noteMoment" />
<ng-container *ngIf="moment.typeName === momentType.CgiScore" [ngTemplateOutlet]="cgiMoment" />
<ng-container *ngIf="moment.typeName === momentType.Event" [ngTemplateOutlet]="riskMoment" />
<ng-container *ngIf="moment.typeName === momentType.Call || moment.typeName === momentType.File ||
                    moment.typeName === momentType.GoogleFile" [ngTemplateOutlet]="attachmentMoment" />

<ng-template #noteMoment>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title fxFlex="53" fxFlex.gt-md="56" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <mat-icon [ngStyle]="{ color: '#9ee7cc' }" class="moment-icon preview">assignment</mat-icon>
        <span>Note</span>
      </mat-panel-title>
      <mat-panel-description> {{ moment.author }} | {{ moment.timestamp | convertDateToTime }} </mat-panel-description>
    </mat-expansion-panel-header>
    <p>{{ moment.note }}</p>
  </mat-expansion-panel>
</ng-template>

<ng-template #cgiMoment>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title fxFlex="53" fxFlex.gt-md="56" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <mat-icon [ngStyle]="{ color: '#e988ec' }" class="moment-icon preview">assessment</mat-icon>
        <span>CGI Score</span>
      </mat-panel-title>
      <mat-panel-description> {{ moment.author }} | {{ moment.timestamp | convertDateToTime }} </mat-panel-description>
    </mat-expansion-panel-header>
    <p>CGI-SEVERITY: {{ moment.cgiSeverity }}</p>
    <p>CGI-IMPROVEMENT: {{ moment.cgiImprovement }}</p>
  </mat-expansion-panel>
</ng-template>

<ng-template #riskMoment>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title fxFlex="53" fxFlex.gt-md="56" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <mat-icon [ngStyle]="{ color: '#E3B23C' }" class="moment-icon preview">playlist_add_check</mat-icon>
        <span>{{ moment.titleText }}</span>
      </mat-panel-title>
      <mat-panel-description> {{ moment.author }} | {{ moment.timestamp | convertDateToTime }} </mat-panel-description>
    </mat-expansion-panel-header>

    <ng-container *ngIf="moment.GADQuestions.length > 0">
      <p>GAD - {{ moment.GADSeverity }} ({{ moment.GADScore }})</p>
      <ul>
        <li *ngFor="let question of moment.GADQuestions; trackByProperty: 'momentDataGADQuestions'">
          {{ question.question }} - <i>{{ question.answer }}</i>
        </li>
      </ul>
    </ng-container>
    <ng-container *ngIf="moment.PHQQuestions.length > 0">
      <p>PHQ - {{ moment.PHQSeverity }} ({{ moment.PHQScore }})</p>
      <ul>
        <li *ngFor="let question of moment.PHQQuestions; trackByProperty: 'momentDataPHQQuestions'">
          {{ question.question }} - <i>{{ question.answer }}</i>
        </li>
      </ul>
    </ng-container>
  </mat-expansion-panel>
</ng-template>

<ng-template #attachmentMoment>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title fxFlex="53" fxFlex.gt-md="56" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <mat-icon *ngIf="moment.typeName === momentType.Call" [ngStyle]="{ color: '#46cbf3' }" class="moment-icon preview">
          call
        </mat-icon>
        <mat-icon *ngIf="moment.typeName === momentType.File" [ngStyle]="{ color: '#fdb47f' }" class="moment-icon preview">
          save
        </mat-icon>
        <mat-icon *ngIf="moment.typeName === momentType.GoogleFile" svgIcon="google-drive" class="moment-icon preview svg">
        </mat-icon>
        <span>{{ moment.fileName }}</span>
      </mat-panel-title>
      <mat-panel-description> {{ moment.author }} | {{ moment.timestamp | convertDateToTime }} </mat-panel-description>
    </mat-expansion-panel-header>
    <p>{{ moment?.description }}</p>
  </mat-expansion-panel>
</ng-template>

<ng-template #appointmentMoment>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title fxFlex="53" fxFlex.gt-md="56" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <mat-icon [ngStyle]="{ color: '#3cc4e3' }" class="moment-icon preview">event_available</mat-icon>
        <span>{{ moment.typeName | titlecase }}</span>
      </mat-panel-title>
      <mat-panel-description> {{ moment.author }} | {{ moment.timestamp | convertDateToTime }} </mat-panel-description>
    </mat-expansion-panel-header>
    <div>Date: {{ moment.appointmentDate | date: 'fullDate' }}</div>
    <div>Time: {{ moment.appointmentTime | date: 'shortTime' }}</div>
    <p>Number of Sessions: {{ moment.therapySessionNumber }}</p>
    <p>
      Outcome: {{ moment.appointmentOutcome }}
      <span *ngIf="moment.appointmentOutcomeOther"> - {{ moment.appointmentOutcomeOther }} </span>
    </p>
    <p>
      How difficult patient is finding daily activites:
      {{ moment.howPatientIsFunctioning || 'Not answered' }}
    </p>
  </mat-expansion-panel>
</ng-template>
