<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
  <div *ngIf="mediaObserver.isActive('gt-xs')" class="icon-container" [class.deleted-inner]="momentData.deleted" fxLayout="column" fxLayoutAlign="center center">
    <mat-icon *ngIf="icon" [ngStyle]="{ color: colour || 'black' }" class="moment-icon">{{ icon }}</mat-icon>
    <mat-icon *ngIf="svgIcon" [svgIcon]="svgIcon" [ngStyle]="{ color: colour || undefined }" class="moment-icon svg"></mat-icon>
    <div *ngIf="momentData.deleted" class="deleted-icon">
      <mat-icon>delete</mat-icon>
    </div>
  </div>
  <!--It's a bit nasty calling | hasExpandableContent twice, but I can't see another way-->
  <mat-expansion-panel [hideToggle]="!(content | hasExpandableContent)" [disabled]="!(content | hasExpandableContent)">
    <mat-expansion-panel-header>
      <mat-panel-title fxFlex="53" fxFlex.gt-md="56" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <ng-container *ngIf="!mediaObserver.isActive('gt-xs')">
          <mat-icon *ngIf="icon" [ngStyle]="{ color: colour || 'black' }" class="moment-icon">{{ icon }}</mat-icon>
          <mat-icon *ngIf="svgIcon" [svgIcon]="svgIcon" [ngStyle]="{ color: colour || undefined }" class="moment-icon svg" ></mat-icon>
          <div *ngIf="momentData.deleted" class="deleted-icon">
            <mat-icon>delete</mat-icon>
          </div>
        </ng-container>
        <span [class.deleted-text]="momentData.deleted">
          <span>{{ title }}</span>
        </span>

        <button *ngIf="expandOption" mat-icon-button color="primary" matTooltip="Open note in dialog" (click)="expandFunc.emit(); $event.stopPropagation();">
          <mat-icon>expand_content</mat-icon>
        </button>
      </mat-panel-title>

      <mat-panel-description fxFlex fxLayout="row" fxLayoutAlign="end center">
        <span [matTooltip]="momentData.author">{{ momentData.author }}</span> | {{ momentData.timestamp | convertDateToTime }}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div #content [class.deleted-content]="momentData.deleted">
      <ng-content></ng-content>
    </div>
  </mat-expansion-panel>
</div>
