import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { AttendanceStates } from './attendance-states';
import { Function_Question, Function_Question_Answers } from '../phq-step/phq-gad-questionnaire';
import { DashboardTubMoment, TubPatient } from '@backend-client/models';
import { MomentType } from '../../../moment/moment-type';
import { TimelineService } from '../../../timeline.service';

@Component({
    selector: 'app-appointment-step',
    templateUrl: './appointment-step.component.html',
    styleUrls: ['./appointment-step.component.scss'],
    standalone: false
})
export class AppointmentStepComponent implements OnInit {
  @Input() patientId: string;
  @Input() patient: TubPatient;
  @Input() editMode: boolean;
  @Input() editReasonFormGroup: FormGroup;
  @Input() appointmentFormGroup: FormGroup;
  @Input() noteFormGroup: FormGroup;

  public today = new Date();
  public previousNote: DashboardTubMoment;
  public attendanceStates = Object.values(AttendanceStates);
  public functionQuestion = Function_Question;
  public functionAnswers = Object.values(Function_Question_Answers);

  constructor(private timelineService: TimelineService) {}

  async ngOnInit() {
    if (!this.editMode) {
      try {
        this.previousNote = (await this.timelineService.getMoments(this.patientId, [MomentType.Note]))[0];
      } catch (e) {
        console.error(e);
      }
    }
  }
}
