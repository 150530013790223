<div matTooltip="You do not have permission to add items to the patient timeline"
     [matTooltipDisabled]="'patient:upload-moments' | userHasScope | async">
  <button [disabled]="!('patient:upload-moments' | userHasScope | async)" id="add-moment-button" mat-fab color="primary"
          [matMenuTriggerFor]="menu">
    <mat-icon>add</mat-icon>
  </button>
  <mat-menu class="add-moment-menu" xPosition="before" yPosition="above" #menu="matMenu">
    <ng-container *ngIf="(featureFlags$ | async)?.isGoogleDriveEnabled; else cloudBucketUpload">
      <button *ngFor="let driveMoment of addGoogleDriveMomentConfig; trackByProperty: 'googleDriveMomentConfig'" mat-menu-item
              (click)="driveMoment.function()">
        <mat-icon>{{driveMoment.icon}}</mat-icon>
        <span>{{driveMoment.title}}</span>
      </button>
    </ng-container>

    <!-- Note moment / standard moments created via dialog -->
    <button *ngFor="let moment of addDialogMomentConfig; trackByProperty: 'dialogMomentConfig'" mat-menu-item
            (click)="addMomentButtonClicked(moment.dialogComponent, moment.backdrop)">
      <mat-icon>{{moment.icon}}</mat-icon>
      <span>{{moment.title}}</span>
    </button>

    <!-- Appointment Note moment / moments that require function call to create -->
    <ng-container *ngIf="(featureFlags$ | async)?.isAppointmentMomentsEnabled">
      <button *ngFor="let functionMoment of addFunctionMomentConfig; trackByProperty: 'functionMomentConfig'" mat-menu-item
              (click)="functionMoment.function()">
        <mat-icon>{{functionMoment.icon}}</mat-icon>
        <span>{{functionMoment.title}}</span>
      </button>
    </ng-container>
  </mat-menu>
</div>

<ng-template #cloudBucketUpload>
  <button *ngFor="let cloudMoment of addCloudMomentConfig; trackByProperty: 'cloudMomentConfig'" mat-menu-item
    (click)="addMomentButtonClicked(cloudMoment.dialogComponent, cloudMoment.backdrop)">
    <mat-icon>{{cloudMoment.icon}}</mat-icon>
    <span>{{cloudMoment.title}}</span>
  </button>
</ng-template>
