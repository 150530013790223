import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { take } from 'rxjs';

import { DialogService } from '@shared/components/dialog/dialog.service';
import { FeatureFlagService } from '@shared/services/feature-flag.service';
import { GoogleDriveService } from '@shared/services/google-drive/google-drive.service';
import { GoogleDriveActions } from '@shared/services/google-drive/google-drive-actions.enum';
import { TimelineService } from '../timeline.service';
import { AddCallMomentComponent } from '../add-call-moment/add-call-moment.component';
import { AddFileMomentComponent } from '../add-file-moment/add-file-moment.component';
import { AddNoteMomentComponent } from '../add-note-moment/add-note-moment.component';

@Component({
    selector: 'app-add-moment-button',
    templateUrl: './add-moment-button.component.html',
    styleUrls: ['./add-moment-button.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AddMomentButtonComponent {
  @Input() patientId: string;

  //  Dialog moments
  public addDialogMomentConfig = [
    {
      title: 'Add Note',
      icon: 'assignment',
      backdrop: false,
      dialogComponent: AddNoteMomentComponent
    }
  ];
  // Function moments - if used by other moment types, html must be updated to avoid feature flag for appointment moments
  public addFunctionMomentConfig = [
    {
      title: 'Add Appointment Note',
      icon: 'event_note',
      function: () => this.addAppointmentMomentButtonClicked(),
    }
  ];

  // Old upload to cloud storage bucket
  public addCloudMomentConfig = [
    {
      title: 'Add Call',
      icon: 'call',
      backdrop: true,
      dialogComponent: AddCallMomentComponent
    },
    {
      title: 'Add File',
      icon: 'save',
      backdrop: true,
      dialogComponent: AddFileMomentComponent
    }
  ];
  // Upload to Google Drive Shared Folder
  public addGoogleDriveMomentConfig = [
    {
      title: 'Add File',
      icon: 'upload_file',
      function: () => this.addGoogleDriveMomentButtonClicked(),
    }
  ];

  public featureFlags$ = this.featureFlagService.featureFlags$;

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private timelineService: TimelineService,
    private featureFlagService: FeatureFlagService,
    private googleDriveService: GoogleDriveService
  ) {}

  public addMomentButtonClicked<T>(dialogComponent: T, hasBackdrop = true): void {
    this.dialogService.openDialog(dialogComponent, { timelineService: this.timelineService, patientId: this.patientId }, { hasBackdrop: hasBackdrop });
  }

  public async addGoogleDriveMomentButtonClicked(): Promise<void> {
    await this.googleDriveService.initTokenUpload(GoogleDriveActions.Upload, this.patientId);
  }

  public addAppointmentMomentButtonClicked(): void {
    this.router.navigate([ `/therapist/assigned-patients/patient-profile/${this.patientId}/appointment` ]);
  }
}
