<app-dialog
  #dialog
  (cancel)="cancelButtonClicked()"
  (confirm)="deleteButtonClicked()"
  cancelButtonText="Cancel"
  confirmButtonText="Soft delete"
  confirmButtonColor="warn"
  [isConfirmButtonEnabled]="deletionFormGroup.valid"
  [title]="title">
  <div><mat-error>Warning! This action cannot be undone</mat-error></div>
  <p>{{ attachedMoments.length > 0 ? appointmentInfoText : infoText }}</p>

  <mat-accordion>
    <app-display-moment [moment]="data.moment"></app-display-moment>

    <!-- Attached Appointment Moments -->
    <ng-container *ngFor="let attachedMoment of attachedMoments">
      <app-display-moment [moment]="attachedMoment"></app-display-moment>
    </ng-container>
  </mat-accordion>

  <div fxFlex fxLayout="column" [formGroup]="deletionFormGroup" class="form" fxLayoutGap="6px">
    <div fxFlex>
      <mat-error>
        Please do not submit any patient identifiable information in the reason box, this includes names, emails and patient IDs.
      </mat-error>
    </div>
    <mat-form-field fxFlex>
      <mat-label>Reason for soft deleting moment</mat-label>
      <textarea matInput formControlName="softDeleteReason" placeholder="Please explain why you want to delete this moment"></textarea>
      <mat-error *ngIf="deletionFormGroup.controls['softDeleteReason'].hasError('required')">
        Reason for soft deleting moment is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="deletionFormGroup.controls['softDeleteReason'].hasError('trimErrorStart')">
        Reason for soft deleting moment cannot contain leading whitespaces
      </mat-error>
      <mat-error *ngIf="deletionFormGroup.controls['softDeleteReason'].hasError('trimErrorEnd')">
        Reason for soft deleting moment cannot contain trailing whitespaces
      </mat-error>
      <mat-error *ngIf="deletionFormGroup.controls['softDeleteReason'].hasError('multipleWhitespacesError')">
        Reason for soft deleting moment cannot contain multiple consecutive whitespaces
      </mat-error>
      <mat-error *ngIf="!deletionFormGroup.controls['softDeleteReason'].hasError('required') && deletionFormGroup.controls['softDeleteReason'].hasError('lengthError')">
        Reason for soft deleting moment must contain more than 3 characters
      </mat-error>
    </mat-form-field>

    <div fxFlex>
      <p>Do any of the moments contain data for another patient?</p>
      <mat-radio-group formControlName="dataContainsAnotherUsersInfo" aria-label="Select an option">
        <mat-radio-button [value]="true">Yes</mat-radio-button>
        <mat-radio-button [value]="false">No</mat-radio-button>
      </mat-radio-group>
    </div>

    <div *ngIf="deletionFormGroup.get('dataContainsAnotherUsersInfo').value === true" fxFlex fxLayout="column">
      <div fxFlex>
        <mat-error>
          If a moment contains data for another patient this requires a hard delete. Please contact Andres to request
          this data be deleted.
        </mat-error>
      </div>

      <div fxFlex>
        <p>Would you like to request a hard delete?</p>
        <mat-radio-group formControlName="requestHardDelete" aria-label="Select an option">
          <mat-radio-button [value]="true">Yes</mat-radio-button>
          <mat-radio-button [value]="false">No</mat-radio-button>
        </mat-radio-group>
      </div>

      <div fxFlex>Please remember to chase this request if you have heard nothing within 4 days time.</div>
    </div>
  </div>
</app-dialog>
