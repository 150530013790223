import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RemoteConfigService } from './remote-config/remote-config.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private featureFlags = new BehaviorSubject<Record<string, boolean>>(null);
  public featureFlags$: Observable<Record<string, boolean>> = this.featureFlags.asObservable();

  constructor(private remoteConfigService: RemoteConfigService) {}

  public async initialise(): Promise<void> {
    try {
      const config = await this.remoteConfigService.getAll();
      const featureFlags = this.convertConfigToBooleans(config);

      this.featureFlags.next(featureFlags);

      console.log('[FEATURE FLAGS] Initialised:', featureFlags);
    } catch (error) {
      console.error('[FEATURE FLAGS] Error initialising feature flags:', error);
    }
  }

  public async isGoogleDriveEnabled(): Promise<boolean> {
    return await this.remoteConfigService.getBoolean('isGoogleDriveEnabled');
  }

  private convertConfigToBooleans(config: Record<string, any>): Record<string, boolean> {
    const featureFlags: Record<string, boolean> = {};

    for (const [key, value] of Object.entries(config)) {
      featureFlags[key] = value.asBoolean();
    }

    return featureFlags;
  }
}
