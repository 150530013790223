import { Component } from '@angular/core';

import { FeatureFlagService } from '@shared/services/feature-flag.service';
import { NoteMoment } from './note-moment';
import { MomentService } from '../moment.service';
import { AppointmentStep } from '../../add-edit-appointment-moment/appointment-step.enum';
import { MomentType } from '../moment-type';

@Component({
    selector: 'app-note-moment',
    templateUrl: './note-moment.component.html',
    styleUrls: ['./note-moment.component.scss'],
    standalone: false
})
export class NoteMomentComponent {
  public momentData: NoteMoment;
  public momentType = MomentType;
  public featureFlags$ = this.featureFlagService.featureFlags$;
  public appointmentStep = AppointmentStep;

  constructor(
    public momentService: MomentService,
    private featureFlagService: FeatureFlagService
  ) { }
}
