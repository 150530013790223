import { Injectable } from '@angular/core';

import { BehaviorSubject, take } from 'rxjs';
import { isNumber } from 'validate.js';

import { MessageService } from '@shared/services/message.service';
import { DialogService } from '@shared/components/dialog/dialog.service';
import { FeatureFlagService } from '@shared/services/feature-flag.service';
import { GoogleDriveService } from '@shared/services/google-drive/google-drive.service';
import { GoogleDriveUploadService } from '@shared/services/google-drive/google-drive-upload.service';
import { GoogleDriveActions } from '@shared/services/google-drive/google-drive-actions.enum';
import { TimelineService } from '../timeline.service';
import { CheckableMoment } from './stepper-components/attachments-step/attachments.model';
import { AddCallMomentComponent } from '../add-call-moment/add-call-moment.component';
import { AddFileMomentComponent } from '../add-file-moment/add-file-moment.component';
import { MomentType } from '../moment/moment-type';
import { Moment } from '../moment/moment';

@Injectable()
export class AppointmentMomentService {

  public patientId$ = new BehaviorSubject<string>(undefined);

  public isEditMode = false;
  public attachmentsLimit = 4;
  public attachments: CheckableMoment[] = [];
  public fetchingAttachments$ = new BehaviorSubject<boolean>(true);
  public attachmentsCheckedList$ = new BehaviorSubject<CheckableMoment[]>([]);

  public errorFetchingAttachments$ = new BehaviorSubject<boolean>(false);
  public errorInformation$ = new BehaviorSubject<string>(undefined);

  constructor(
    private featureFlagService: FeatureFlagService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private timelineService: TimelineService,
    private googleDriveService: GoogleDriveService,
    private googleDriveUploadService: GoogleDriveUploadService,
  ) {}

  public async getLatestMoment(id: string): Promise<Moment> {
    let moment = await this.timelineService.getMomentById(this.patientId$.value, id);

    while (moment?.deleted?.newMomentRef) {
      moment = await this.timelineService.getMomentById(this.patientId$.value, moment.deleted.newMomentRef);
    }

    return moment;
  }

  /**
  * Load attachments, mark moments previously selected as selected again
  */
  public async fetchAttachments(): Promise<void> {
    try {
      const attachments = await this.timelineService.getMoments(this.patientId$.value, [MomentType.Call, MomentType.File, MomentType.GoogleFile], this.attachmentsLimit);

      if (!this.isEditMode) {
        this.attachments = attachments.filter(moment => !moment.deleted).map(tubMoment => this.timelineService.convertTubMomentToMoment(tubMoment)) as CheckableMoment[];
      } else {
        this.attachments = attachments.map(tubMoment => this.timelineService.convertTubMomentToMoment(tubMoment)) as CheckableMoment[];
      }

      for (const checked of this.attachmentsCheckedList$.value) {
        const matchIndex = this.attachments.findIndex(a => a.id === checked.id);
        if (this.attachments[matchIndex]) {
          this.attachments[matchIndex].checked = true;
        }
      }

      this.fetchingAttachments$.next(false);
      this.googleDriveUploadService.uploadingFileMoment$.next(false);
    } catch (e) {
      console.error(e);
      this.errorFetchingAttachments$.next(true);
      switch(e.status) {
        case 404:
          this.errorInformation$.next('Patient not found in database.');
          break;
        case 403:
          this.errorInformation$.next('You do not have authorisation to access / update this patients record. This may be because you have discharged the patient recently.');
          break;
        default:
          this.errorInformation$.next('An error has occured trying to fetch attachments, if you continue to encounter this error please contact support.');
          break;
      }
      this.messageService.showMessage('Error: ' + this.errorInformation$.value);
    }
  }

  /**
  * Allow Therapist to reload attachments - pulling latest data which might not show straight away after adding a new file
  */
  public reloadAttachments(): void {
    this.fetchingAttachments$.next(true);
    this.fetchAttachments();
  }

  /**
  * Allow Therapist to load more attachments increasing the limit by 2 more each time
  */
  public loadMoreAttachments(): void {
    this.attachmentsLimit = this.attachmentsLimit + 2;
    this.fetchingAttachments$.next(true);
    this.fetchAttachments();
  }

  /**
  * Allow Therapist to upload a Google File or File moment
  */
  public uploadAttachment(): void {
    this.featureFlagService.featureFlags$.pipe(take(1)).subscribe(async flags => {
      if (flags.isGoogleDriveEnabled) {
        await this.googleDriveService.initToken(GoogleDriveActions.Upload, this.patientId$.value);
        this.googleDriveUploadService.fileUploadComplete$.pipe(take(1)).subscribe(async () => {
          this.attachmentsLimit ++;
          await this.fetchAttachments();
        });
      } else {
        const dialogRef = this.dialogService.openDialog(AddFileMomentComponent, { timelineService: this.timelineService, patientId: this.patientId$.value });
        dialogRef.afterClosed().pipe(take(1)).subscribe(async reload => {
          if (reload) {
            this.attachmentsLimit ++;
            await this.fetchAttachments();
          }
        });
      }
    });
  }

  /**
  * Allow Therapist to upload an call moment
  */
  public uploadCallAttachment(): void {
    const dialogRef = this.dialogService.openDialog(AddCallMomentComponent, { timelineService: this.timelineService, patientId: this.patientId$.value });
    dialogRef.afterClosed().pipe(take(1)).subscribe(async reload => {
      if (reload) {
        this.attachmentsLimit ++;
        await this.fetchAttachments();
      }
    });
  }

  /**
  * Calculate PHQ / GAD score based off answers given
  * @param answers The GAD / PHQ answers
  */
  public calcGadPhqScore(answers: string[]): number {
    let score = 0;
    for (const answer of answers) {
      if (isNumber(answer)) {
        score = score + Number(answer);
      }
    }
    return score;
  }

  /**
  * Calculate PHQ / GAD screening score based off the first two answers given
  * @param answers The GAD / PHQ answers
  */
  public calcGadPhqScreeningScore(answers: string[]): number {
    let score = 0;
    for (const answer of answers.splice(0, 2)) {
      if (isNumber(answer)) {
        score = score + Number(answer);
      }
    }
    return score;
  }
}
